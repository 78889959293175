.cursor {
  cursor: pointer;
  user-select: none;
}
.bottom-menu {
  display: none;
}
ion-modal.modal-greetings {
  --width: 800px;
  --height: max-content;
  --border-radius: 10px;
  gap: 16px;
  box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
  --background: var(--surface);
}
ion-modal.modal-greetings .background-image {
  position: absolute;
  bottom: 0px;
}
ion-modal.modal-greetings .modal-greetings-cont {
  border: 1px solid #e6e5ea;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 16px;
}
ion-modal.modal-greetings .modal-greetings-cont .title-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
ion-modal.modal-greetings .modal-greetings-cont .title-cont .title {
  display: flex;
  flex-direction: row;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-greetings .modal-greetings-cont .title-cont .title .red {
  color: var(--primary-default);
}
ion-modal.modal-greetings .modal-greetings-cont .title-cont .lower-title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-greetings .modal-greetings-cont .items {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-greetings .modal-greetings-cont .items .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 234.67px;
  height: 204px;
}
ion-modal.modal-greetings .modal-greetings-cont .items .item .text-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-greetings .modal-greetings-cont .items .item .text-cont .bold {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-greetings .modal-greetings-cont .items .item .text-cont .low {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
ion-modal.modal-greetings .modal-greetings-cont .btn-greetings {
  padding: 0px 20px;
  border-radius: 12px;
}
ion-modal.modal-greetings .modal-greetings-cont .btn-greetings .text {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.header-first-page {
  display: flex;
  flex-direction: row;
  height: 76px;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - 320px);
  align-items: center;
  z-index: 999;
  padding: 16px;
  background-color: transparent;
  justify-content: space-between;
}
.header-first-page.quick {
  width: calc(100vw - 320px - 520px);
}
.header-first-page .mobile-header {
  display: none;
}
.header-first-page .left-header {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.header-first-page .left-header .btn-top-first-page {
  padding: 0px 16px;
  height: 44px;
  border-radius: 12px;
}
.header-first-page .left-header .city-select,
.header-first-page .left-header .radius-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 116px;
  height: 44px;
  border-radius: 12px;
  padding: 0px 16px;
  gap: 12px;
}
.header-first-page .left-header .city-select .text,
.header-first-page .left-header .radius-select .text {
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.header-first-page .left-header .city-select svg,
.header-first-page .left-header .radius-select svg {
  min-width: 20px;
}
.header-first-page .right-header {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.header-first-page .right-header .favourite {
  min-width: 44px;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-first-page .right-header .btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  border-radius: 12px;
  padding: 0px 16px;
  gap: 2px;
}
.header-first-page .right-header .btn-login .text {
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-login {
  --width: 520px;
  --height: max-content;
  --border-radius: 12px;
}
ion-modal.modal-login .image-back-login {
  user-select: none;
  position: absolute;
  rotate: 0deg;
  max-width: 100%;
  right: 0px;
  bottom: 0px;
}
ion-modal.modal-login .body-modal-login {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 32px;
  position: relative;
  align-items: center;
}
ion-modal.modal-login .body-modal-login .title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-login .body-modal-login .title .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-modal.modal-login .body-modal-login .title .title-lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
ion-modal.modal-login .body-modal-login .form-login {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 16px;
}
ion-modal.modal-login .body-modal-login .form-login .login,
ion-modal.modal-login .body-modal-login .form-login .password {
  width: 320px;
}
ion-modal.modal-login .body-modal-login .form-login .checkbox .cont-text {
  padding: 2px 0px;
}
ion-modal.modal-login .body-modal-login .form-login .checkbox .cont-text .text {
  gap: 4px;
  display: flex;
  flex-direction: row;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-login .body-modal-login .form-login .checkbox .cont-text .text .link {
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login .body-modal-login .form-login .checkbox .cont-text .link {
  user-select: none;
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login .body-modal-login .form-login .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
ion-modal.modal-login .body-modal-login .form-login .cont-input-code {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-login .body-modal-login .form-login .input-code {
  border: 0;
  outline: 0;
  background: var(--substrate);
  border-radius: 10px;
  min-width: 68px;
  height: 88px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-login .body-modal-login .form-login .input-code::placeholder {
  color: #b0afb8;
}
ion-modal.modal-login .body-modal-login .form-login .input-code.error {
  background-color: var(--input-background-error);
  border: 1px solid var(--input-border-error);
}
ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont {
  font-family: var(--font-nunito);
  color: var(--text-unfilled);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 8px 0px 8px;
  gap: 2px;
}
ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .first-text-send-again,
ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .timer {
  opacity: 0.4;
}
ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .second-text-send-again {
  opacity: 1;
  cursor: pointer;
}
ion-modal.modal-login .body-modal-login .form-login .btn-login {
  width: 86px;
  border-radius: 12px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
ion-modal.modal-login .body-modal-login .form-login .btn-login.register {
  width: 137px;
}
ion-modal.modal-login .body-modal-login .lower-btn {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-login .body-modal-login .lower-btn .lower-btn-text {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text-muted);
  cursor: pointer;
  user-select: none;
}
ion-modal.phone {
  --height: max-content !important;
}
ion-modal.register {
  --height: max-content;
}
ion-modal.register-partner {
  --height: max-content;
}
ion-modal.pin {
  --height: max-content !important;
}
ion-modal.alert-message {
  --height: max-content !important;
}
ion-modal.recovery {
  --height: 388px !important;
}
ion-modal.modal-login-partner {
  --width: 520px;
  --height: max-content;
  --border-radius: 12px;
}
ion-modal.modal-login-partner .image-back-login {
  user-select: none;
  position: absolute;
  rotate: 0deg;
  max-width: 100%;
  right: 0px;
  bottom: 0px;
}
ion-modal.modal-login-partner .body-modal-login {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 32px;
  position: relative;
  align-items: center;
}
ion-modal.modal-login-partner .body-modal-login .title {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
ion-modal.modal-login-partner .body-modal-login .title .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-modal.modal-login-partner .body-modal-login .title .title-lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
ion-modal.modal-login-partner .body-modal-login .alert-register-partner {
  width: 480px;
}
ion-modal.modal-login-partner .body-modal-login .form-login {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 16px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .login,
ion-modal.modal-login-partner .body-modal-login .form-login .password {
  width: 320px;
  height: 44px;
  padding: 6px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .login .input,
ion-modal.modal-login-partner .body-modal-login .form-login .password .input {
  width: 100%;
  padding: 4px 6px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .checkbox .cont-text {
  padding: 2px 0px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .checkbox .cont-text .text {
  gap: 4px;
  display: flex;
  flex-direction: row;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-login-partner .body-modal-login .form-login .checkbox .cont-text .text .link {
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login-partner .body-modal-login .form-login .checkbox .cont-text .link {
  user-select: none;
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login-partner .body-modal-login .form-login .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .cont-input-code {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .input-code {
  border: 0;
  outline: 0;
  background: var(--substrate);
  border-radius: 10px;
  min-width: 68px;
  height: 88px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.modal-login-partner .body-modal-login .form-login .input-code::placeholder {
  color: #b0afb8;
}
ion-modal.modal-login-partner .body-modal-login .form-login .input-code.error {
  background-color: var(--input-background-error);
  border: 1px solid var(--input-border-error);
}
ion-modal.modal-login-partner .body-modal-login .form-login .send-again-text-cont {
  font-family: var(--font-nunito);
  color: var(--text-unfilled);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 8px 0px 8px;
  gap: 2px;
}
ion-modal.modal-login-partner .body-modal-login .form-login .send-again-text-cont .first-text-send-again,
ion-modal.modal-login-partner .body-modal-login .form-login .send-again-text-cont .timer {
  opacity: 0.4;
}
ion-modal.modal-login-partner .body-modal-login .form-login .send-again-text-cont .second-text-send-again {
  opacity: 1;
  cursor: pointer;
}
ion-modal.modal-login-partner .body-modal-login .form-login .btn-login {
  width: 86px;
  border-radius: 12px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}
ion-modal.modal-login-partner .body-modal-login .form-login .btn-login.register {
  width: 189px;
}
ion-modal.modal-login-partner .body-modal-login .lower-btn {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-login-partner .body-modal-login .lower-btn .lower-btn-text {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text-muted);
  cursor: pointer;
  user-select: none;
}
ion-modal.modal-login-recovery {
  --width: 520px;
  --height: max-content;
  --border-radius: 12px;
}
ion-modal.modal-login-recovery .image-back-login {
  user-select: none;
  position: absolute;
  rotate: 0deg;
  max-width: 100%;
  right: 0px;
  bottom: 0px;
}
ion-modal.modal-login-recovery .body-modal-login {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 32px;
  position: relative;
  align-items: center;
}
ion-modal.modal-login-recovery .body-modal-login .close-alert-message {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 105px;
}
ion-modal.modal-login-recovery .body-modal-login .title {
  gap: 8px;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  font-size: 28px;
  text-align: center;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
ion-modal.modal-login-recovery .body-modal-login .title .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-modal.modal-login-recovery .body-modal-login .title .title-lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
ion-modal.modal-login-recovery .body-modal-login .alert-register-partner {
  width: 480px;
}
ion-modal.modal-login-recovery .body-modal-login .form-login {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 16px;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .login,
ion-modal.modal-login-recovery .body-modal-login .form-login .password {
  width: 320px;
  height: 44px;
  padding: 6px;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .login .input,
ion-modal.modal-login-recovery .body-modal-login .form-login .password .input {
  width: 100%;
  padding: 4px 6px;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .checkbox .cont-text {
  padding: 2px 0px;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .checkbox .cont-text .text {
  gap: 4px;
  display: flex;
  flex-direction: row;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .checkbox .cont-text .text .link {
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .checkbox .cont-text .link {
  user-select: none;
  color: var(--link);
  cursor: pointer;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .btn-login {
  width: 137px;
  border-radius: 12px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .btn-login.register {
  width: max-content;
}
ion-modal.modal-login-recovery .body-modal-login .form-login .btn-login.recovery-btn {
  width: 114px;
}
ion-modal.modal-login-recovery .body-modal-login .lower-btn {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-login-recovery .body-modal-login .lower-btn .lower-btn-text {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text-muted);
  cursor: pointer;
  user-select: none;
}
.left-menu-tours {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 320px;
  height: 100vh;
  background-color: var(--surface);
}
.left-menu-tours .header-left-menu {
  height: 168px;
  width: 100%;
  padding: 16px 16px;
  box-shadow: 0px 4px 8px 0px #0000001f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.left-menu-tours .header-left-menu .up-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.left-menu-tours .header-left-menu .up-header .logo {
  height: 76px;
}
.left-menu-tours .header-left-menu .up-header .btn-menu-left {
  max-height: 36px;
}
.left-menu-tours .header-left-menu .input-search-tour {
  width: 100%;
}
.left-menu-tours .header-left-menu .filter-btn {
  padding: 12px;
  cursor: pointer;
  user-select: none;
}
.left-menu-tours .header-left-menu .cont-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 208px;
  gap: 4px;
}
.left-menu-tours .header-left-menu .cont-logo .btn-menu-left {
  padding: 12px;
  cursor: pointer;
  user-select: none;
}
.left-menu-tours .content-left-menu {
  display: block;
  position: relative;
  height: calc(100vh - 168px);
  overflow: hidden;
}
.left-menu-tours .content-left-menu .content {
  width: 100%;
}
.left-menu-tours .content-left-menu .scroll {
  position: relative;
  height: calc(100vh - 168px);
  padding: 0px 0px 0px 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
  border-radius: 8px;
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-track-piece:start {
  border-radius: 8px;
  margin-top: 8px;
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar {
  width: 14px;
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #22222266;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.left-menu-tours .content-left-menu .scroll ::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
.left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.left-menu-tours .content-left-menu .scroll.search {
  padding: 16px 0px;
}
.left-menu-tours .content-left-menu .scroll .el-search {
  cursor: pointer;
  width: 100%;
  padding: 0px 8px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.left-menu-tours .content-left-menu .scroll .el-search:hover {
  background-color: #f4f3f6;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-img {
  min-width: 24px;
  min-height: 24px;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 8px 12px 0px;
  gap: 16px;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search.border {
  border-bottom: 1px solid var(--divider);
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left {
  display: flex;
  width: 181px;
  flex-direction: column;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right {
  display: flex;
  flex-direction: column;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right .rating {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}
.left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right .rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.left-menu-tours .content-left-menu::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: calc(100vh - 20px);
  top: 10px;
  right: -5px;
  width: 5px;
  background: #666;
}
ion-menu.main-left-menu {
  --width: 320px;
  --box-shadow: 0px 11px 22px -2.5px #0000001f;
  --box-shadow: 0px 22px 44px -5px #00000024;
  --box-shadow: 0px 44px 88px -10px #00000029;
}
ion-menu.main-left-menu .menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-menu.main-left-menu .menu .close-left-menu {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  user-select: none;
}
ion-menu.main-left-menu .menu .l {
  width: 100%;
  border-top: 1px solid var(--divider);
}
ion-menu.main-left-menu .menu .btn-menu {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  gap: 4px;
  margin-top: 16px;
}
ion-menu.main-left-menu .menu .btn-menu .menu-item {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}
ion-menu.main-left-menu .menu .btn-menu .menu-item .text-menu-item {
  padding: 0px 6px 0px 6px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 640;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: var(--text);
}
ion-menu.main-left-menu .menu .btn-menu.btn-menu-client {
  margin-top: 0px;
}
ion-menu.main-left-menu .menu .btn-login-left-menu {
  width: 288px;
  border-radius: 12px;
  padding: 0px 16px;
  gap: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
ion-menu.main-left-menu .menu .btn-login-left-menu .text {
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.main-left-menu .menu .other-btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.main-left-menu .menu .other-btns .other-btn {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  user-select: none;
}
ion-menu.main-left-menu .menu .contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.main-left-menu .menu .contacts .text-contacts {
  font-family: var(--font-nunito);
  color: var(--link);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-menu.main-left-menu .menu .address {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.main-left-menu .menu .address .text-address {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-menu.main-left-menu .menu .circles {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
ion-menu.main-left-menu .menu .circles img {
  cursor: pointer;
  user-select: none;
}
ion-menu.main-left-menu-filter {
  --width: 360px;
  --box-shadow: 0px 11px 22px -2.5px #0000001f;
  --box-shadow: 0px 22px 44px -5px #00000024;
  --box-shadow: 0px 44px 88px -10px #00000029;
}
ion-menu.main-left-menu-filter .header-filter {
  width: 100%;
  background-color: #FFF;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 0px 16px;
  gap: 4px;
  height: 76px;
  box-shadow: 0px 4px 8px 0px #0000001f;
}
ion-menu.main-left-menu-filter .header-filter .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
ion-menu.main-left-menu-filter .body-filter {
  height: calc(var(--vh, 1vh)*100 - 76px - 76px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 16px 16px;
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
  border-radius: 8px;
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar-track-piece:start {
  border-radius: 8px;
  margin-top: 8px;
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar {
  width: 14px;
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #22222266;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
ion-menu.main-left-menu-filter .body-filter ::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
ion-menu.main-left-menu-filter .body-filter::-webkit-scrollbar-corner {
  background-color: transparent;
}
ion-menu.main-left-menu-filter .body-filter .line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-menu.main-left-menu-filter .body-filter .line .title-line {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
ion-menu.main-left-menu-filter .body-filter .line .title-line.pad {
  padding: 0px 6px;
}
ion-menu.main-left-menu-filter .body-filter .line .checkbox .text-checkbox {
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
ion-menu.main-left-menu-filter .body-filter .line .inputs {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-menu.main-left-menu-filter .body-filter .line .cont-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}
ion-menu.main-left-menu-filter .body-filter .line .cont-slider .slider-filter {
  width: 95%;
  border-radius: 800px;
  background: #f4f3f6;
}
ion-menu.main-left-menu-filter .body-filter .line .cont-slider .slider-filter .p-slider-range {
  background: #f74439;
}
ion-menu.main-left-menu-filter .body-filter .line .cont-slider .slider-filter .p-slider-handle {
  border-radius: 800px;
  border: 3px solid #f74439;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
}
ion-menu.main-left-menu-filter .body-filter .line .cont-slider .slider-filter .p-slider-handle:focus {
  box-shadow: none;
  outline: none;
}
ion-menu.main-left-menu-filter .body-filter .mem {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem .left {
  display: flex;
  flex-direction: column;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem .left .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem .left .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem .right {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
ion-menu.main-left-menu-filter .body-filter .mem .val-mem .right .val-mem-cur {
  width: 44px;
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
ion-menu.main-left-menu-filter .body-filter .dop-mem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.main-left-menu-filter .body-filter .line-date {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-menu.main-left-menu-filter .body-filter .line-date .date {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: calc((100% / 2) - 4px);
}
ion-menu.main-left-menu-filter .body-filter .line-date .date .title-date {
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
ion-menu.main-left-menu-filter .body-filter .line-date .date .input-date {
  max-width: 100%;
  width: 100%;
}
ion-menu.main-left-menu-filter .body-filter .line-date .date .input-date .input {
  width: 100%;
}
ion-menu.main-left-menu-filter .footer-menu {
  height: 76px;
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  gap: 4px;
  box-shadow: 0px -4px 8px 0px #0000001f;
}
ion-menu.main-left-menu-filter .footer-menu .width {
  width: 162px;
}
.header-pages-guest {
  position: absolute;
  z-index: 999;
  width: 100vw;
  height: 76px;
  border: 0px, 0px, 1px, 0px;
  padding: 0px 16px;
  box-shadow: 0px 1px 2px -0.25px #00000005;
  box-shadow: 0px 2px 4px -0.5px #0000000a;
  box-shadow: 0px 4px 8px -1px #0000000f;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-pages-guest .cont-header-btn {
  display: flex;
  flex-direction: row;
  max-width: 1120px;
  height: 44px;
  align-items: center;
  width: 1120px;
  justify-content: space-between;
}
.header-pages-guest .cont-header-btn .left {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.header-pages-guest .cont-header-btn .left img {
  cursor: pointer;
  user-select: none;
  height: 60px;
}
.header-pages-guest .cont-header-btn .left .btn-menu-left {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}
.header-pages-guest .cont-header-btn .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.header-pages-guest .cont-header-btn .right .btn-login-header {
  width: 86px;
}
.header-pages-guest .cont-header-btn .right .favourite {
  width: 44px;
}
.for-partners-content {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.for-partners-content::-webkit-scrollbar {
  display: none;
}
.for-partners-content .back-image {
  min-width: 1260px;
  min-height: 560px;
  position: absolute;
  left: auto;
  right: auto;
  top: -76px;
}
.for-partners-content .top-hat {
  position: relative;
  width: 100vw;
  height: 464px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.for-partners-content .top-hat .content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;
  gap: 32px;
  position: relative;
  z-index: 10;
  min-width: 1120px;
}
.for-partners-content .top-hat .content .center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0px;
  width: 640px;
  gap: 32px;
}
.for-partners-content .top-hat .content .center-content .text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.for-partners-content .top-hat .content .center-content .text .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.for-partners-content .top-hat .content .center-content .text .large-text {
  font-family: var(--font-nunito-sans);
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--text);
}
.for-partners-content .top-hat .content .center-content .text .large-text .colortext {
  color: var(--primary-default);
}
.for-partners-content .top-hat .content .center-content .text .small-text {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--text);
  text-align: center;
}
.for-partners-content .top-hat .content .center-content .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.for-partners-content .top-hat .content .center-content .cont-btn .btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  border-radius: 12px;
  padding: 0px 20px;
  gap: 2px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .top-hat .content .center-content .cont-btn .btn-partner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 182px;
  border-radius: 12px;
  padding: 0px 20px;
  gap: 2px;
  box-shadow: 0px 12px 12px -12px #de1f4c66;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .slider-partner {
  width: calc(100vw - 64px);
}
.for-partners-content .info-cont {
  width: 100vw;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.for-partners-content .info-cont .cont-offer {
  width: 1120px;
  padding: 32px 0px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.for-partners-content .info-cont .cont-offer .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}
.for-partners-content .info-cont .cont-offer .blocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.for-partners-content .info-cont .cont-offer .blocks .block {
  display: flex;
  flex-direction: column;
  width: 271px;
  height: 132px;
  border-radius: 20px;
  padding: 20px;
  gap: 16px;
}
.for-partners-content .info-cont .cont-offer .blocks .block .icon-block path {
  stroke: #71b54e;
}
.for-partners-content .info-cont .cont-offer .blocks .block .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .info-cont .cont-offer .blocks .primary {
  background-color: var(--alert-primary);
}
.for-partners-content .info-cont .cont-offer .blocks .secondary {
  background-color: var(--alert-secondary);
}
.for-partners-content .info-cont .cont-offer .blocks .info {
  background-color: var(--alert-info);
}
.for-partners-content .info-cont .cont-offer .blocks .success {
  background-color: var(--alert-success);
}
.for-partners-content .offer-stage {
  width: 1120px;
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  gap: 32px;
}
.for-partners-content .offer-stage .stage {
  display: flex;
  flex-direction: row;
  gap: calc(32px - 14px);
  padding: 8px 0px 0px 0px;
}
.for-partners-content .offer-stage .stage .text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  gap: 16px;
}
.for-partners-content .offer-stage .stage .text .number-1 {
  position: absolute;
  top: 20px;
  right: 375px;
}
.for-partners-content .offer-stage .stage .text .number-2 {
  position: absolute;
  top: 5px;
  right: 345px;
}
.for-partners-content .offer-stage .stage .text .number-3 {
  position: absolute;
  top: 50px;
  right: 355px;
}
.for-partners-content .offer-stage .stage .text .number-4 {
  position: absolute;
  top: 20px;
  right: 350px;
}
.for-partners-content .offer-stage .stage .text .number-5 {
  position: absolute;
  top: 20px;
  right: 340px;
}
.for-partners-content .offer-stage .stage .text .number-6 {
  position: absolute;
  top: 35px;
  right: 335px;
}
.for-partners-content .offer-stage .stage .text .bold {
  position: relative;
  z-index: 99;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
}
.for-partners-content .offer-stage .stage .text .lower {
  color: var(--text);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: var(--font-nunito);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 0px 25px;
  margin: 0px;
}
.for-partners-content .offer-stage .stage2 {
  gap: calc(32px - 21px);
}
.for-partners-content .tryyourself {
  width: calc(100vw - 64px);
  height: 552px;
  padding: 32px;
  border-radius: 20px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--substrate);
}
.for-partners-content .tryyourself .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  height: 488px;
  padding: 32px;
  border-radius: 12px;
  gap: 32px;
  background-color: var(--surface);
  box-shadow: 0px 1.75px 3.5px -0.5px #0000000a;
  box-shadow: 0px 3.5px 7px -1px #0000000f;
  box-shadow: 0px 7px 14px -2px #00000014;
}
.for-partners-content .tryyourself .cont .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.for-partners-content .tryyourself .cont .title .bold {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.02em;
  text-align: center;
}
.for-partners-content .tryyourself .cont .title .lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.for-partners-content .tryyourself .cont .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.for-partners-content .tryyourself .cont .cont-btn .btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  border-radius: 12px;
  padding: 0px 20px;
  gap: 2px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .tryyourself .cont .cont-btn .btn-partner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 182px;
  border-radius: 12px;
  padding: 0px 20px;
  gap: 2px;
  box-shadow: 0px 12px 12px -12px #de1f4c66;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .tryyourself .cont .line {
  width: 736px;
  border: 1px solid #e2e6e5;
}
.for-partners-content .tryyourself .cont .steps {
  width: 640px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 8px;
}
.for-partners-content .tryyourself .cont .steps .double-arrow {
  min-width: 20px;
  height: 20px;
}
.for-partners-content .tryyourself .cont .steps .cont-text {
  min-width: 189.33px;
  width: 189.33px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.for-partners-content .tryyourself .cont .steps .cont-text .bold {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .tryyourself .cont .steps .cont-text .lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.for-partners-content .tryyourself .cont .steps .center-steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.for-partners-content .weworkwith {
  display: flex;
  flex-direction: column;
  width: 1120px;
  padding: 32px 0px;
  gap: 16px;
}
.for-partners-content .weworkwith .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}
.for-partners-content .weworkwith .blocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.for-partners-content .weworkwith .blocks .block {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 365.33px;
  height: 136px;
  padding: 20px;
  border-radius: 20px;
  gap: 16px;
  background-color: var(--substrate);
}
.for-partners-content .weworkwith .blocks .block .cont-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.for-partners-content .weworkwith .blocks .block .cont-text .bold {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.for-partners-content .weworkwith .blocks .block .cont-text .lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.for-partners-content .faq {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1120px;
  padding: 32px 0px;
  gap: 16px;
}
.for-partners-content .faq .cont-faq {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.for-partners-content .faq .cont-faq .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
}
.for-partners-content .faq .cont-faq .item-accord-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord {
  width: 560px;
  border-radius: 12px;
  border: 1px solid #e2e6e5;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord .item-native {
  color: none !important;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item {
  --min-height: 52px;
  --background: var(--surface);
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated-opacity: transparent;
  --background-focused-opacity: transparent;
  --background-hover-opacity: transparent;
  --border-width: 0 0 0 0;
  --ripple-color: transparent;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item div {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 500px;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord .ion-padding {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 16px;
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord.accordion-expanded > [slot="header"] .ion-accordion-toggle-icon {
  transform: rotate(90deg);
}
.for-partners-content .faq .cont-faq .item-accord-group .item-accord.accordion-expanding > [slot="header"] .ion-accordion-toggle-icon {
  transform: rotate(90deg);
}
.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.about-content .back-image {
  min-width: 1260px;
  min-height: 560px;
  position: absolute;
  left: auto;
  right: auto;
  top: -76px;
}
.about-content .top-hat {
  position: relative;
  width: 100vw;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-content .top-hat .content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0px;
  gap: 32px;
  position: relative;
  z-index: 10;
  min-width: 1120px;
}
.about-content .top-hat .content .center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0px;
  width: 640px;
  gap: 32px;
}
.about-content .top-hat .content .center-content .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.about-content .top-hat .content .center-content .text .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-content .top-hat .content .center-content .text .text-up {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.about-content .top-hat .content .center-content .text .large-text {
  font-family: var(--font-nunito-sans);
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--text);
}
.about-content .top-hat .content .center-content .text .large-text .colortext {
  color: var(--primary-default);
}
.about-content .top-hat .content .center-content .text .small-text {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--text);
}
.about-content .items {
  width: 1120px;
  padding: 32px 0px;
  gap: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.about-content .items .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 352px;
  height: 308px;
  gap: 16px;
}
.about-content .items .item .cont-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.about-content .items .item .cont-text .bold {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.about-content .items .item .cont-text .low {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.about-content .values {
  display: flex;
  flex-direction: column;
  width: 1120px;
  height: 412px;
  padding: 32px 0px;
  gap: 16px;
}
.about-content .values .title {
  width: 100%;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}
.about-content .values .items-values {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.about-content .values .items-values .item-values {
  display: flex;
  flex-direction: column;
  width: 362.67px;
  height: auto;
  padding: 20px;
  border-radius: 12px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.about-content .values .items-values .item-values .up {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.about-content .values .items-values .item-values .up .text-up {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.about-content .values .items-values .item-values .info {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.about-content .mission {
  display: flex;
  width: 1120px;
  height: 456px;
  padding: 32px 0px;
  gap: 16px;
}
.about-content .mission .gray {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  border-radius: 20px;
  gap: 8px;
  background-color: var(--substrate);
}
.about-content .mission .info {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  gap: 12px;
  width: 560px;
}
.about-content .mission .info .up {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.about-content .mission .info .middle {
  font-family: var(--font-nunito-sans);
  color: var(--text-);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
}
.about-content .mission .info .bottom {
  font-family: var(--font-nunito);
  color: var(--text-);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.inf-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.inf-content .cont-inf {
  width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  gap: 16px;
}
.inf-content .cont-inf .cont-1 {
  display: flex;
  flex-direction: column;
  width: 720px;
  padding: 44px 0px;
  gap: 16px;
}
.inf-content .cont-inf .cont-1 .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}
.inf-content .cont-inf .cont-1 .lower {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.inf-content .cont-inf .cont {
  width: 720px;
  padding: 16px 0px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.inf-content .cont-inf .cont .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}
.inf-content .cont-inf .cont .low {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show {
  position: absolute;
  width: 520px;
  left: 320px;
  top: 0px;
  height: 100vh;
  background-color: #ffffff;
  border: 1px solid var(--divider);
}
.cont-quick-show .header-quick {
  position: relative;
  padding: 0px 16px;
  height: 76px;
  box-shadow: 0px 1.75px 3.5px -0.5px #0000000a, 0px 3.5px 7px -1px #0000000f, 0px 7px 14px -2px #00000014;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}
.cont-quick-show .header-quick .stages {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
}
.cont-quick-show .header-quick .stages .el-tabs {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 76px;
  gap: 2px;
  padding: 0px 6px;
}
.cont-quick-show .header-quick .stages .el-tabs .text-tabs {
  padding: 0px 2px;
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .header-quick .stages .el-tabs .value-tabs {
  background-color: #e6e5ea;
  padding: 0px 6px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 800px;
  min-width: 20px;
  width: max-content;
  height: 20px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.cont-quick-show .header-quick .stages .el-tabs.active-tabs {
  border-bottom: 2px solid var(--primary-default);
  height: calc(76px - 2px);
}
.cont-quick-show .header-quick .stages .el-tabs.active-tabs .text-tabs {
  color: var(--primary-default);
}
.cont-quick-show .header-quick .stages .el-tabs.active-tabs .value-tabs {
  background-color: #4b4957;
  color: #fff;
}
.cont-quick-show .header-quick .name {
  display: none;
}
.cont-quick-show .info-cont {
  position: relative;
}
.cont-quick-show .info-cont .scroll-quick {
  padding: 32px 4px 32px 16px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  height: calc(100vh - 80px);
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
  border-radius: 8px;
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar-track-piece:start {
  border-radius: 8px;
  margin-top: 8px;
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar {
  width: 14px;
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #22222266;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.cont-quick-show .info-cont .scroll-quick ::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
.cont-quick-show .info-cont .scroll-quick::-webkit-scrollbar-corner {
  background-color: transparent;
}
.cont-quick-show .info-cont .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.cont-quick-show .info-cont .btns .btn-quick-show {
  padding: 0px 4px;
}
.cont-quick-show .info-cont .btns .btn-quick-show.favorite .text-btn {
  color: #f74439;
}
.cont-quick-show .info-cont .btns .btn-quick-show.favorite .text-btn svg path {
  stroke: transparent;
}
.cont-quick-show .info-cont .l {
  width: 100%;
  border-bottom: 1px solid var(--divider);
}
.cont-quick-show .info-cont .title-cont {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cont-quick-show .info-cont .title-cont .rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cont-quick-show .info-cont .title-cont .rate .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.cont-quick-show .info-cont .title-cont .rate .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .title-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .title-cont .genres-cont {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}
.cont-quick-show .info-cont .block-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.cont-quick-show .info-cont .block-cont .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  border-radius: 10px;
  gap: 8px;
  background-color: var(--unfilled);
  height: 120px;
  min-width: 157.33px;
  flex-basis: 0;
  flex-grow: 1;
}
.cont-quick-show .info-cont .block-cont .block .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont-quick-show .info-cont .block-cont .block .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.cont-quick-show .info-cont .block-cont .block .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.cont-quick-show .info-cont .block-cont .block .cont-text .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.cont-quick-show .info-cont .block-cont .block .cont-text .row-price .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.cont-quick-show .info-cont .block-cont .block .cont-text .row-price .text.promotion {
  color: var(--success);
}
.cont-quick-show .info-cont .block-cont .block .cont-text .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-images {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
}
.cont-quick-show .info-cont .cont-images .main-img {
  height: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.cont-quick-show .info-cont .cont-images .main-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cont-quick-show .info-cont .cont-images .other-image {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.cont-quick-show .info-cont .cont-images .other-image .image {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  min-width: 200px;
  flex-basis: 0;
  flex-grow: 1;
}
.cont-quick-show .info-cont .cont-images .other-image .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.cont-quick-show .info-cont .cont-images .other-image .image:last-child img {
  border-radius: 8px;
}
.cont-quick-show .info-cont .cont-description {
  font-family: var(--font-source);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--text);
}
.cont-quick-show .info-cont .cont-description p {
  margin: 0px;
}
.cont-quick-show .info-cont .fade {
  position: fixed;
  pointer-events: none;
  width: 500px;
  height: 60px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #ffffff 100%);
}
.cont-quick-show .info-cont .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .point-start {
  display: flex;
  flex-direction: column;
  border: 1px dashed #879fff;
  border-radius: 10px;
  padding: 20px;
  gap: 8px;
}
.cont-quick-show .info-cont .point-start .title-point-start-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cont-quick-show .info-cont .point-start .title-point-start-cont .title-point-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.cont-quick-show .info-cont .point-start .title-point-start-cont .title-point-start .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .point-start .title-point-start-cont .coor-name {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .point-start .title-point-start-cont .timezone {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .point-start .cont-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}
.cont-quick-show .info-cont .point-start .cont-images .image {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 144px;
  overflow: hidden;
  width: 144px;
}
.cont-quick-show .info-cont .point-start .cont-images .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cont-quick-show .info-cont .point-start .cont-images .image:last-child img {
  border-radius: 8px;
}
.cont-quick-show .info-cont .point-start .description {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-opposite {
  display: none;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .span {
  width: 20px;
  height: 20px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector {
  width: 1px;
  background: #e6e5ea;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content {
  padding: 0px 0px 0px 16px;
  width: 416px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .icon-info-details {
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .icon-info-details.active {
  transform: rotate(90deg) !important;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .title {
  width: 400px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .collect-time {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-reviews-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-reviews-details.hide {
  display: none;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  width: 400px;
  letter-spacing: 0.01em;
  text-align: left;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 144px;
  overflow: hidden;
  width: 144px;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image:last-child img {
  border-radius: 8px;
}
.cont-quick-show .info-cont .rating-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cont-quick-show .info-cont .rating-cont .rate {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.cont-quick-show .info-cont .rating-cont .rate .star-rev-quick-show {
  width: 36px;
  height: 36px;
}
.cont-quick-show .info-cont .rating-cont .rate .value {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .select-sort {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.cont-quick-show .info-cont .cont-reviews {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cont-quick-show .info-cont .cont-reviews .items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cont-quick-show .info-cont .cont-reviews .items .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-info .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 800px;
  overflow: hidden;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-info .circle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-info .name {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-rate .when {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-reviews .items .item .user-review {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-images .image {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-images .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 455px;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .upper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .upper .circle-response {
  width: 32px;
  height: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .upper .circle-response img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .upper .name-response {
  color: var(--text);
  font-family: var(--font-source);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .upper .date-response {
  color: var(--text-muted);
  font-family: var(--font-source);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response .text-response {
  width: 100%;
  color: var(--text);
  font-family: var(--font-source);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .title-opt,
.cont-quick-show .info-cont .title-races {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .options-cont {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cont-quick-show .info-cont .options-cont .block {
  gap: 12px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 12px;
}
.cont-quick-show .info-cont .options-cont .block .right-block {
  white-space: nowrap;
  width: max-content;
}
.cont-quick-show .info-cont .options-cont .block .right-block .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .options-cont .block .right-block .text-free {
  color: #029c3f;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  border-radius: 10px;
}
.cont-quick-show .info-cont .races-cont .races-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.cont-quick-show .info-cont .races-cont .races-rate .value {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.cont-quick-show .info-cont .races-cont .races-rate .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .cont-select {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cont-quick-show .info-cont .races-cont .cont-select .title-select {
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .val-mem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.cont-quick-show .info-cont .races-cont .val-mem .left {
  display: flex;
  flex-direction: column;
}
.cont-quick-show .info-cont .races-cont .val-mem .left .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .val-mem .left .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .val-mem .right {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.cont-quick-show .info-cont .races-cont .val-mem .right .val-mem-cur {
  width: 44px;
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.cont-quick-show .info-cont .races-cont .ld {
  border-bottom: 1px dashed var(--divider);
}
.cont-quick-show .info-cont .races-cont .info-cost {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .title-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .val-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .row-price .val-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .row-price .val-cost.promotion {
  color: var(--success);
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-quick-show .info-cont .races-cont .info-cost .cost .total {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.body-show .cont-show {
  width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  gap: 32px;
}
.body-show .cont-show .title-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-show .cont-show .title-cont .btn-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.body-show .cont-show .title-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .title-cont .genres {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.body-show .cont-show .title-cont .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.body-show .cont-show .title-cont .rating .left,
.body-show .cont-show .title-cont .rating .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.body-show .cont-show .title-cont .rating .left .val,
.body-show .cont-show .title-cont .rating .right .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.body-show .cont-show .title-cont .rating .left .reviews,
.body-show .cont-show .title-cont .rating .right .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .title-cont .rating .left .btn-show-tour,
.body-show .cont-show .title-cont .rating .right .btn-show-tour {
  padding: 0px 4px;
}
.body-show .cont-show .title-cont .rating .left .btn-show-tour.favorite .text-btn,
.body-show .cont-show .title-cont .rating .right .btn-show-tour.favorite .text-btn {
  color: #f74439;
}
.body-show .cont-show .title-cont .rating .left .btn-show-tour.favorite .text-btn svg path,
.body-show .cont-show .title-cont .rating .right .btn-show-tour.favorite .text-btn svg path {
  stroke: transparent;
}
.body-show .cont-show .images-cont {
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 12px;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  height: 440px;
}
.body-show .cont-show .images-cont .left {
  position: relative;
  max-width: 558px;
  min-width: 558px;
  overflow: hidden;
  height: 100%;
}
.body-show .cont-show .images-cont .left .btn-all-photo {
  left: 16px;
  bottom: 16px;
  position: absolute;
}
.body-show .cont-show .images-cont .left img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.body-show .cont-show .images-cont .right {
  max-width: 558px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.body-show .cont-show .images-cont .right .image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 277px;
  min-width: 277px;
  height: 218px;
  max-width: 218px;
  overflow: hidden;
}
.body-show .cont-show .images-cont .right .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.body-show .cont-show .cont-information {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.body-show .cont-show .cont-information .left {
  width: 728px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.body-show .cont-show .cont-information .left .block-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .block-cont .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  border-radius: 10px;
  gap: 8px;
  background-color: var(--unfilled);
  height: 120px;
  min-width: 237.33px;
  flex-basis: 0;
  flex-grow: 1;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .row-price .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .row-price .text.promotion {
  color: var(--success);
}
.body-show .cont-show .cont-information .left .block-cont .block .cont-text .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .description,
.body-show .cont-show .cont-information .left .description_inc_price {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}
.body-show .cont-show .cont-information .left .description p,
.body-show .cont-show .cont-information .left .description_inc_price p {
  margin: 0;
}
.body-show .cont-show .cont-information .left .suitable {
  display: flex;
  width: 728px;
  flex-direction: column;
  gap: 16px;
}
.body-show .cont-show .cont-information .left .suitable .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
  border-radius: 10px;
  gap: 16px;
  background-color: var(--unfilled);
  min-width: 237.33px;
  flex-basis: 0;
  flex-grow: 1;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable.true {
  background-color: var(--alert-success);
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .icon-active {
  opacity: 40%;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .cont-text {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .cont-text .subtext.true {
  color: var(--text);
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .cont-text .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .cont-text .text.true {
  color: var(--text);
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .stat {
  position: absolute;
  right: 8px;
  top: 8px;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .stat .green path {
  stroke: #029c3f;
}
.body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable .stat .gray path {
  stroke: #b0afb8;
}
.body-show .cont-show .cont-information .left .description-inc .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .title-opt {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .options-cont {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-show .cont-show .cont-information .left .options-cont .block {
  gap: 12px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 12px;
}
.body-show .cont-show .cont-information .left .options-cont .block .right-block {
  white-space: nowrap;
  width: max-content;
}
.body-show .cont-show .cont-information .left .options-cont .block .right-block .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .options-cont .block .right-block .text-free {
  color: #029c3f;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .map-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.body-show .cont-show .cont-information .left .map-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .map-cont .conter .cont-map-t #map-client {
  width: 728px;
  height: 400px;
  border-radius: 12px;
  position: relative;
}
.body-show .cont-show .cont-information .left .cont-details {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start {
  display: flex;
  flex-direction: column;
  border: 1px dashed #879fff;
  border-radius: 10px;
  padding: 20px;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .title-point-start-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .title-point-start-cont .title-point-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .title-point-start-cont .title-point-start .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .title-point-start-cont .coor-name {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .title-point-start-cont .timezone {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .cont-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .cont-images .image {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 144px;
  overflow: hidden;
  width: 144px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .cont-images .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .cont-images .image:last-child img {
  border-radius: 8px;
}
.body-show .cont-show .cont-information .left .cont-details .point-start .description {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-opposite {
  display: none;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-separator .span {
  width: 20px;
  height: 20px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector {
  width: 1px;
  background: #e6e5ea;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content {
  padding: 0px 0px 0px 16px;
  width: 416px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .icon-info-details {
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .icon-info-details.active {
  transform: rotate(90deg) !important;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  width: 660px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .collect-time {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-reviews-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-reviews-details.hide {
  display: none;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  width: 660px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  height: 144px;
  overflow: hidden;
  width: 144px;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image:last-child img {
  border-radius: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-stage {
  display: flex;
  flex-direction: row;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-stage .stage {
  cursor: pointer;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  border-bottom: 2px solid var(--divider);
  gap: 6px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-stage .stage.active {
  border-bottom: 2px solid var(--primary-default);
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-stage .stage .text {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-stage .stage .len {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .rating-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-show .cont-show .cont-information .left .cont-reviews .rating-cont .rate-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.body-show .cont-show .cont-information .left .cont-reviews .rating-cont .rate-left .rate-val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .rating-cont .rate-left .rev-rate {
  width: 36px;
  height: 36px;
}
.body-show .cont-show .cont-information .left .cont-reviews .select-sort {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-info .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 800px;
  overflow: hidden;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-info .circle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-info .name {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-rate .when {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .user-review {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-images .image {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-images .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 696px;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .upper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .upper .circle-response {
  width: 32px;
  height: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .upper .circle-response img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .upper .name-response {
  color: var(--text);
  font-family: var(--font-source);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .upper .date-response {
  color: var(--text-muted);
  font-family: var(--font-source);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response .text-response {
  width: 100%;
  color: var(--text);
  font-family: var(--font-source);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .l {
  border-bottom: 1px solid var(--divider);
}
.body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .l.hide {
  border: none;
}
.body-show .cont-show .cont-information .left .btn-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-show .cont-show .cont-information .left .faq-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-show .cont-show .cont-information .left .faq-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord {
  width: 728px;
  border-radius: 12px;
  border: 1px solid #e2e6e5;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord .item-native {
  color: none !important;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord ion-item {
  --min-height: 52px;
  --background: var(--surface);
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated-opacity: transparent;
  --background-focused-opacity: transparent;
  --background-hover-opacity: transparent;
  --border-width: 0 0 0 0;
  --ripple-color: transparent;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord ion-item div {
  padding: 16px 0px;
  max-width: 650px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord .ion-padding {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 16px;
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord.accordion-expanded > [slot="header"] .ion-accordion-toggle-icon {
  transform: rotate(90deg);
}
.body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord.accordion-expanding > [slot="header"] .ion-accordion-toggle-icon {
  transform: rotate(90deg);
}
.body-show .cont-show .cont-information .left .operator-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.body-show .cont-show .cont-information .left .operator-cont .operator {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 20px;
  border: 1px solid var(--divider);
  border-radius: 10px;
}
.body-show .cont-show .cont-information .left .operator-cont .operator .title {
  color: var(--text-muted);
  font-family: var(--font-source);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .left .operator-cont .operator .org {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .left .operator-cont .operator .org .gray path {
  stroke: #b0afb8;
}
.body-show .cont-show .cont-information .left .operator-cont .operator .org .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right {
  width: 360px;
}
.body-show .cont-show .cont-information .right .races-cont {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  border-radius: 10px;
}
.body-show .cont-show .cont-information .right .races-cont .races-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.body-show .cont-show .cont-information .right .races-cont .races-rate .value {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.body-show .cont-show .cont-information .right .races-cont .races-rate .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .cont-select {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .races-cont .cont-select .title-select {
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem .left-btn {
  display: flex;
  flex-direction: column;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem .left-btn .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem .left-btn .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem .right-btn {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  min-width: 124px;
}
.body-show .cont-show .cont-information .right .races-cont .val-mem .right-btn .val-mem-cur {
  width: 44px;
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.body-show .cont-show .cont-information .right .races-cont .ld {
  border-bottom: 1px dashed var(--divider);
}
.body-show .cont-show .cont-information .right .races-cont .info-cost {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .title-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .val-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .row-price .val-cost {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .row-price .val-cost.promotion {
  color: var(--success);
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .races-cont .info-cost .cost .total {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  border-radius: 12px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .loading {
  height: 604px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .loading .loading-btn .p-progress-spinner-circle {
  stroke: var(--text) !important;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .info .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .row-price .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .row-price .text.promotion {
  color: var(--success);
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .price-cont .price .text-b {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .races-comp-cont .link-event {
  cursor: pointer;
  user-select: none;
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.body-show .cont-show .cont-information .right .right-cont .contacts {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  border-radius: 12px;
}
.body-show .cont-show .cont-information .right .right-cont .contacts .info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.body-show .cont-show .cont-information .right .right-cont .contacts .info .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.body-show .cont-show .cont-information .right .right-cont .contacts .info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .contacts .info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .cont-information .right .right-cont .contacts .info .line .text-link {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.body-show .cont-show .l {
  border-bottom: 1px solid var(--divider);
  width: 100%;
}
.body-show .cont-show .search-reg {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.body-show .cont-show .search-reg .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.body-show .cont-show .search-reg .no-array {
  height: 240px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-show .cont-show .search-reg .no-array img {
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.body-show .cont-show .search-reg .no-array .content {
  position: absolute;
  top: 108px;
}
.body-show .cont-show .search-reg .no-array .content .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.body-show .cont-show .similar-reg {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.body-show .cont-show .similar-reg .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.quick-s .cont-map-q {
  position: absolute;
  right: 0px;
  width: calc(100vw - (520px + 320px));
}
.quick-s .cont-map-q #map-show {
  width: calc(100vw - (520px + 320px));
  height: 100vh;
  position: relative;
}
.page-review {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}
.page-review .body-rev-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 1120px;
  padding: 32px 0px;
}
.page-review .body-rev-page .cont-title {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.page-review .body-rev-page .cont-title .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.page-review .body-rev-page .create-review-cont .left {
  display: flex;
  width: 728px;
  flex-direction: column;
  gap: 32px;
}
.page-review .body-rev-page .create-review-cont .left .review-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .rating-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .rating-cont .p-rating {
  gap: 0px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .rating-cont .p-rating .p-rating-item {
  box-shadow: none;
}
.page-review .body-rev-page .create-review-cont .left .review-info .rating-cont .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .review-info .area-reviews {
  height: 160px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .add {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .cont-image-option {
  border-radius: 8px;
  width: 104px;
  height: 104px;
  overflow: hidden;
  position: relative;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .cont-image-option .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .cont-image-option .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .add-img {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 104px;
  height: 104px;
  background-color: var(--neutral-25);
  border: 1px solid var(--divider);
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .add-img .image {
  cursor: pointer;
  width: max-content;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .description-add-img {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .description-add-img .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .description-add-img .cont-subtext {
  display: flex;
  flex-direction: column;
}
.page-review .body-rev-page .create-review-cont .left .review-info .add-image-cont .description-add-img .cont-subtext .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev {
  width: 728px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .image {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev {
  width: 592px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .p-rating {
  gap: 0px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .p-rating .p-rating-item {
  box-shadow: none;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option {
  border-radius: 8px;
  width: 104px;
  height: 104px;
  overflow: hidden;
  position: relative;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add-img {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 104px;
  height: 104px;
  background-color: var(--neutral-25);
  border: 1px solid var(--divider);
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add-img .image {
  cursor: pointer;
  width: max-content;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .cont-subtext {
  display: flex;
  flex-direction: column;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .cont-subtext .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .l {
  border-bottom: 1px solid var(--divider);
}
.page-review .body-rev-page .create-review-cont .right .cont-send {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .image {
  max-width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .inf-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 232px;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .inf-cont .rate-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .inf-cont .rate-cont .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .inf-cont .rate-cont .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .info-tour .inf-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.page-review .body-rev-page .create-review-cont .right .cont-send .btn-send {
  width: 100%;
}
ion-modal.modal-create-question {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-create-question .cont-create-quest {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-create-question .cont-create-quest .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-create-question .cont-create-quest .info-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .inf-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .inf-cont .rate-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .inf-cont .rate-cont .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .inf-cont .rate-cont .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-create-question .cont-create-quest .info-tour .inf-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-create-question .cont-create-quest .area-quest {
  height: 160px;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .add {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .cont-image-option {
  border-radius: 8px;
  width: 104px;
  height: 104px;
  overflow: hidden;
  position: relative;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .cont-image-option .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .cont-image-option .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .add-img {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 104px;
  height: 104px;
  background-color: var(--neutral-25);
  border: 1px solid var(--divider);
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .add-img .image {
  cursor: pointer;
  width: max-content;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .description-add-img {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .description-add-img .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .description-add-img .cont-subtext {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-create-question .cont-create-quest .add-image-cont .description-add-img .cont-subtext .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-modal.modal-create-question .cont-create-quest .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-cancel-orders {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-cancel-orders .modal-cancel-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-cancel-orders .modal-cancel-body .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .scroll {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-cancel-orders .modal-cancel-body .cont-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-cancel-orders .modal-cancel-body .cont-info .num {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .cont-info .name {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .info .first,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .first,
ion-modal.modal-cancel-orders .modal-cancel-body .info .third,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .third {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .info .first .link,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .first .link,
ion-modal.modal-cancel-orders .modal-cancel-body .info .third .link,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .third .link {
  cursor: pointer;
  color: var(--link);
}
ion-modal.modal-cancel-orders .modal-cancel-body .info .second,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .second {
  list-style-type: disc;
  padding-inline-start: 24px;
}
ion-modal.modal-cancel-orders .modal-cancel-body .info .second li,
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client .second li {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .info-cancel-client {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-cancel-orders .modal-cancel-body .checkbox-cont .text-checkbox .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-cancel-orders .modal-cancel-body .checkbox-cont .text-checkbox .text .link {
  cursor: pointer;
  color: var(--link);
}
ion-modal.modal-cancel-orders .modal-cancel-body .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.favorite-cont {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.favorite-cont .favorite-body {
  width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0px;
}
.favorite-cont .favorite-body .title-fav {
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .image {
  position: relative;
  width: 240px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .image .cont-val-images {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 9px;
  height: 36px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  font-family: var(--font-nunito);
  color: var(--neutral);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 536px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 536px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf .cont-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf .cont-rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf .cont-rating .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-genres {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 4px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body {
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .summa {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .summa {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .summa.promotion {
  color: var(--success);
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .duration {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .buttonsdiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .buttonsdiv .fav-btn .text-btn {
  align-items: center;
}
.favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .buttonsdiv .fav-btn .text-btn svg path {
  stroke: transparent;
}
.favorite-cont .favorite-body .l {
  border-bottom: 1px solid var(--divider);
}
.favorite-cont .favorite-body .title-most {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.favorite-cont .favorite-body .title-most .text {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour {
  position: relative;
  display: flex;
  cursor: pointer;
  width: 360px;
  flex-direction: column;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .image {
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .image .cont-val-images {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 9px;
  height: 36px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  font-family: var(--font-nunito);
  color: var(--neutral);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0px;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .cont-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .cont-title .rating-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .cont-title .rating-cont .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .cont-title .rating-cont .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .cont-title .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont {
  display: flex;
  flex-direction: column;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .left .summa {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .left .summa.promotion {
  color: var(--success);
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .left .duration {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .btn-fav {
  position: relative;
}
.favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .btn-fav .text-btn svg path {
  stroke: transparent;
}
.body-search,
.list-tour-mobile {
  display: none;
}
@media screen and (max-width: 960px) {
  .list-tour-mobile {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    background-color: #FFF;
  }
  .list-tour-mobile .scroll {
    margin-top: 52px;
    margin-bottom: 52px;
    height: calc(var(--vh, 1vh) * 100 - 52px - 52px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 12px 16px 20px 16px;
  }
  .list-tour-mobile .scroll .tour-el {
    flex-direction: row;
  }
  .list-tour-mobile .btns {
    background-color: #FFF;
    justify-content: center;
    height: 52px;
    display: flex;
    padding: 0px 8px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  .list-tour-mobile .btns .custom-btn {
    width: calc((100vw / 2) - 4px);
  }
  .bottom-menu {
    width: 100%;
    padding: 20px 16px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .bottom-menu .list {
    width: 100%;
  }
  .body-search {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    width: 100vw;
    height: 100vh;
    gap: 16px;
    position: fixed;
    z-index: 99999;
    background-color: #FFF;
  }
  .body-search .header-body-search {
    height: 44px;
    width: calc(100vw - 32px);
    display: flex ;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
  .body-search .header-body-search .input-search-tour {
    width: calc(100vw - 32px - 76px);
  }
  .body-search .search-list-cont {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .body-search .search-list-cont .el-search {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
  }
  .body-search .search-list-cont .el-search:hover {
    background-color: #f4f3f6;
  }
  .body-search .search-list-cont .el-search .cont-img {
    min-width: 24px;
    min-height: 24px;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 8px 12px 0px;
    gap: 16px;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search.border {
    border-bottom: 1px solid var(--divider);
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .left {
    display: flex;
    width: 181px;
    flex-direction: column;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .left .text {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .left .subtext {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .right {
    display: flex;
    flex-direction: column;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .right .rating {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
  }
  .body-search .search-list-cont .el-search .cont-inf-el-search .right .rating .val-rate {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
  }
  .cont-quick-show {
    overflow: hidden;
    width: 100vw;
    left: 0px;
    height: 100vh;
    position: relative;
    z-index: 999999;
  }
  .cont-quick-show .header-quick {
    height: 44px;
  }
  .cont-quick-show .header-quick .stages .el-tabs {
    height: 44px;
  }
  .cont-quick-show .header-quick .stages .el-tabs.active-tabs {
    height: calc(44px - 2px);
  }
  .cont-quick-show .info-cont .scroll-quick {
    padding: 32px 10px 32px 16px !important;
    height: 100vh;
  }
  .cont-quick-show .info-cont .btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .cont-quick-show .info-cont .btns .btn-quick-show {
    padding: 0px 4px;
  }
  .cont-quick-show .info-cont .btns .btn-quick-show.new-window {
    display: none;
  }
  .cont-quick-show .info-cont .title-cont .title {
    font-size: 22px;
    line-height: 32px;
  }
  .cont-quick-show .info-cont .block-cont {
    flex-wrap: wrap;
  }
  .cont-quick-show .info-cont .block-cont .block {
    min-width: 155px;
  }
  .cont-quick-show .info-cont .cont-images .other-image {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
  .cont-quick-show .info-cont .cont-images .other-image .image {
    height: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    min-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-opposite {
    display: none;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .span {
    width: 20px;
    height: 20px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector {
    width: 1px;
    background: #e6e5ea;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .title {
    width: 300px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image {
    height: 120px;
    width: 120px;
  }
  .cont-quick-show .info-cont .rating-cont {
    flex-wrap: wrap;
    gap: 16px;
  }
  .cont-quick-show .info-cont .select-sort {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .cont-quick-show .info-cont .cont-reviews .items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response {
    width: 286px;
  }
  .header-first-page {
    height: initial;
    padding: 20px 16px;
    width: 100vw;
  }
  .header-first-page.quick {
    display: none;
  }
  .header-first-page .left-header,
  .header-first-page .right-header {
    display: none;
  }
  .header-first-page .mobile-header {
    padding: 8px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: #FFF;
  }
  .header-first-page .mobile-header .input-search-tour {
    width: 100%;
    background: transparent;
  }
  .left-menu-tours {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 320px;
    height: 100vh;
    background-color: var(--surface);
  }
  .left-menu-tours .header-left-menu {
    height: 168px;
    width: 100%;
    padding: 16px 16px;
    box-shadow: 0px 4px 8px 0px #0000001f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .left-menu-tours .header-left-menu .up-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .left-menu-tours .header-left-menu .up-header .logo {
    height: 76px;
  }
  .left-menu-tours .header-left-menu .up-header .btn-menu-left {
    max-height: 36px;
  }
  .left-menu-tours .header-left-menu .input-search-tour {
    width: 100%;
  }
  .left-menu-tours .header-left-menu .filter-btn {
    padding: 12px;
    cursor: pointer;
    user-select: none;
  }
  .left-menu-tours .header-left-menu .cont-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 208px;
    gap: 4px;
  }
  .left-menu-tours .header-left-menu .cont-logo .btn-menu-left {
    padding: 12px;
    cursor: pointer;
    user-select: none;
  }
  .left-menu-tours .content-left-menu {
    display: block;
    position: relative;
    height: calc(100vh - 168px);
    overflow: hidden;
  }
  .left-menu-tours .content-left-menu .content {
    width: 100%;
  }
  .left-menu-tours .content-left-menu .scroll {
    width: 314px;
    position: relative;
    height: calc(100vh - 168px);
    padding: 0px 0px 0px 11px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .left-menu-tours .content-left-menu .scroll::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  .left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-track {
    background: transparent;
  }
  .left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #22222266;
  }
  .left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-track-piece:end {
    background: #22222214;
    margin-bottom: 8px;
    border-radius: 8px;
  }
  .left-menu-tours .content-left-menu .scroll::-webkit-scrollbar-track-piece:start {
    background: #22222214;
    border-radius: 8px;
    margin-top: 8px;
  }
  .left-menu-tours .content-left-menu .scroll.search {
    padding: 16px 0px;
  }
  .left-menu-tours .content-left-menu .scroll .el-search {
    cursor: pointer;
    width: 100%;
    padding: 0px 8px 0px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  .left-menu-tours .content-left-menu .scroll .el-search:hover {
    background-color: #f4f3f6;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-img {
    min-width: 24px;
    min-height: 24px;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 8px 12px 0px;
    gap: 16px;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search.border {
    border-bottom: 1px solid var(--divider);
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left {
    display: flex;
    width: 181px;
    flex-direction: column;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left .text {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .left .subtext {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right {
    display: flex;
    flex-direction: column;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right .rating {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
  }
  .left-menu-tours .content-left-menu .scroll .el-search .cont-inf-el-search .right .rating .val-rate {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
  }
  .left-menu-tours .content-left-menu::after {
    content: "";
    position: absolute;
    z-index: -1;
    height: calc(100vh - 20px);
    top: 10px;
    right: -5px;
    width: 5px;
    background: #666;
  }
}
@media screen and (max-width: 720px) {
  .list-tour-mobile .scroll .tour-el {
    flex-direction: column;
  }
  .list-tour-mobile .btns .custom-btn {
    width: calc((100vw / 2) - 4px);
  }
  .cursor {
    cursor: pointer;
    user-select: none;
  }
  ion-modal.modal-greetings {
    --width: calc(100vw - 32px);
  }
  ion-modal.modal-greetings .modal-greetings-cont {
    padding: 32px 16px;
  }
  ion-modal.modal-greetings .modal-greetings-cont .title-cont .title {
    font-size: 26px;
    line-height: 36px;
  }
  ion-modal.modal-greetings .modal-greetings-cont .title-cont .lower-title {
    font-size: 18px;
  }
  ion-modal.modal-greetings .modal-greetings-cont .items {
    flex-direction: column;
  }
  ion-modal.modal-greetings .modal-greetings-cont .items .item {
    flex-direction: row;
    width: calc(100vw - 32px - 32px);
    height: max-content;
  }
  ion-modal.modal-greetings .modal-greetings-cont .items .item .img {
    width: 56px;
    height: 56px;
  }
  ion-modal.modal-greetings .modal-greetings-cont .items .item .text-cont .bold {
    text-align: start;
  }
  ion-modal.modal-greetings .modal-greetings-cont .items .item .text-cont .low {
    text-align: start;
  }
  ion-modal.modal-login {
    --width: calc(100vw - 32px);
    --max-height: calc(100vh - 32px);
    --border-radius: 10px;
  }
  ion-modal.modal-login .body-modal-login .form-login {
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100vw - 32px - 32px);
    max-height: 404px;
    height: auto;
  }
  ion-modal.modal-login .body-modal-login .form-login .login,
  ion-modal.modal-login .body-modal-login .form-login .password {
    width: calc(100vw - 32px - 32px);
  }
  ion-modal.modal-login .body-modal-login .form-login::-webkit-scrollbar {
    display: none;
  }
  ion-modal.modal-login .body-modal-login .form-login .cont-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont {
    font-family: var(--font-nunito);
    color: var(--text-unfilled);
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 8px 0px 8px;
    gap: 2px;
  }
  ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .first-text-send-again,
  ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .timer {
    opacity: 0.4;
  }
  ion-modal.modal-login .body-modal-login .form-login .send-again-text-cont .second-text-send-again {
    opacity: 1;
    cursor: pointer;
  }
  ion-modal.modal-login .body-modal-login .form-login .btn-login {
    border-radius: 10px;
    min-height: 44px;
  }
  ion-modal.modal-login .body-modal-login .lower-btn {
    flex-wrap: wrap;
    justify-content: center;
  }
  ion-modal.phone {
    --height: max-content !important;
  }
  ion-modal.register {
    --height: auto;
    --max-height: calc(100vh - 32px);
  }
  ion-modal.register-partner {
    --height: auto;
    --max-height: calc(100vh - 32px);
  }
  ion-modal.register-partner .body-modal-login .form-login {
    width: calc(100vw - 32px - 32px);
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 32px - 64px - 64px - 84px - 120px - 32px);
  }
  ion-modal.pin {
    --height: max-content !important;
  }
  ion-modal.recovery {
    --height: 388px !important;
  }
  ion-modal.modal-login-partner {
    --width: calc(100vw - 32px);
    --max-height: calc(100vh - 32px) --border-radius: 10px;
  }
  ion-modal.modal-login-partner .body-modal-login .title {
    font-size: 20px;
    line-height: 28px;
  }
  ion-modal.modal-login-partner .body-modal-login .alert-register-partner {
    width: auto;
  }
  ion-modal.modal-login-partner .body-modal-login .form-login {
    width: calc(100vw - 32px - 32px);
  }
  ion-modal.modal-login-partner .body-modal-login .form-login::-webkit-scrollbar {
    display: none;
  }
  ion-modal.modal-login-partner .body-modal-login .form-login .login,
  ion-modal.modal-login-partner .body-modal-login .form-login .password {
    width: calc(100vw - 32px - 32px);
  }
  ion-modal.modal-login-partner .body-modal-login .form-login .btn-login {
    min-height: 44px;
    border-radius: 10px;
  }
  ion-modal.modal-login-recovery {
    --width: calc(100vw - 32px);
    --border-radius: 10px;
  }
  ion-modal.modal-login-recovery .body-modal-login .title {
    line-height: 28px;
    font-size: 20px;
  }
  ion-modal.modal-login-recovery .body-modal-login .alert-register-partner {
    width: auto;
  }
  ion-modal.modal-login-recovery .body-modal-login .form-login .btn-login {
    border-radius: 10px;
  }
  ion-menu.main-left-menu {
    --width: 320px;
    --box-shadow: 0px 11px 22px -2.5px #0000001f;
    --box-shadow: 0px 22px 44px -5px #00000024;
    --box-shadow: 0px 44px 88px -10px #00000029;
  }
  ion-menu.main-left-menu .menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
  ion-menu.main-left-menu .menu .close-left-menu {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    user-select: none;
  }
  ion-menu.main-left-menu .menu .l {
    width: 100%;
    border-top: 1px solid var(--divider);
  }
  ion-menu.main-left-menu .menu .btn-menu {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    gap: 4px;
    margin-top: 16px;
  }
  ion-menu.main-left-menu .menu .btn-menu .menu-item {
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 4px;
    cursor: pointer;
    user-select: none;
  }
  ion-menu.main-left-menu .menu .btn-menu .menu-item .text-menu-item {
    padding: 0px 6px 0px 6px;
    font-family: var(--font-nunito);
    font-size: 14px;
    font-weight: 640;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
    color: var(--text);
  }
  ion-menu.main-left-menu .menu .btn-menu.btn-menu-client {
    margin-top: 0px;
  }
  ion-menu.main-left-menu .menu .btn-login-left-menu {
    width: 288px;
    border-radius: 12px;
    padding: 0px 16px;
    gap: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  ion-menu.main-left-menu .menu .btn-login-left-menu .text {
    font-family: var(--font-nunito);
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  ion-menu.main-left-menu .menu .other-btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  ion-menu.main-left-menu .menu .other-btns .other-btn {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
    user-select: none;
  }
  ion-menu.main-left-menu .menu .contacts {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  ion-menu.main-left-menu .menu .contacts .text-contacts {
    font-family: var(--font-nunito);
    color: var(--link);
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  ion-menu.main-left-menu .menu .address {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  ion-menu.main-left-menu .menu .address .text-address {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  ion-menu.main-left-menu .menu .circles {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  ion-menu.main-left-menu .menu .circles img {
    cursor: pointer;
    user-select: none;
  }
  .header-pages-guest {
    height: 52px;
    padding: 0px 8px;
  }
  .header-pages-guest .cont-header-btn {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 36px;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
  }
  .header-pages-guest .cont-header-btn .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .header-pages-guest .cont-header-btn .left img {
    cursor: pointer;
    user-select: none;
    height: 32px;
  }
  .header-pages-guest .cont-header-btn .left .btn-menu-left {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }
  .header-pages-guest .cont-header-btn .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .header-pages-guest .cont-header-btn .right .btn-login-header {
    width: 71px;
    height: 36px;
    border-radius: 8px;
  }
  .header-pages-guest .cont-header-btn .right .favourite {
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
  .for-partners-content .back-image {
    min-width: 360px;
  }
  .for-partners-content .top-hat {
    height: 468px;
  }
  .for-partners-content .top-hat .content {
    padding: 16px 16px;
    min-width: 328px;
  }
  .for-partners-content .top-hat .content .center-content {
    width: 100%;
  }
  .for-partners-content .top-hat .content .center-content .text .large-text {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .for-partners-content .info-cont .cont-offer {
    width: 100%;
    padding: 16px 16px;
  }
  .for-partners-content .info-cont .cont-offer .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }
  .for-partners-content .info-cont .cont-offer .blocks {
    flex-wrap: wrap;
    width: 100%;
  }
  .for-partners-content .info-cont .cont-offer .blocks .block {
    display: flex;
    flex-direction: column;
    width: 48%;
    height: 152px;
    border-radius: 16px;
  }
  .for-partners-content .offer-stage {
    width: 100%;
    padding: 16px 16px;
  }
  .for-partners-content .offer-stage .stage {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px;
  }
  .for-partners-content .offer-stage .stage .text {
    padding: 0px;
  }
  .for-partners-content .offer-stage .stage .text .number-1 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .number-2 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .number-3 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .number-4 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .number-5 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .number-6 {
    display: none;
  }
  .for-partners-content .offer-stage .stage .text .bold {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  .for-partners-content .offer-stage .stage2 {
    flex-direction: column-reverse;
  }
  .for-partners-content .tryyourself {
    width: calc(100vw - 32px);
    height: 712px;
    padding: 20px 0px;
    border-radius: 16px;
    background-color: var(--substrate);
  }
  .for-partners-content .tryyourself .cont {
    width: 100%;
    height: 672px;
    padding: 20px 16px;
    border-radius: 10px;
  }
  .for-partners-content .tryyourself .cont .title .bold {
    font-size: 26px;
    line-height: 36px;
  }
  .for-partners-content .tryyourself .cont .line {
    width: 100%;
  }
  .for-partners-content .tryyourself .cont .steps {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px;
  }
  .for-partners-content .tryyourself .cont .steps .double-arrow {
    transform: rotate(90deg);
  }
  .for-partners-content .tryyourself .cont .steps .cont-text {
    width: 100%;
  }
  .for-partners-content .tryyourself .cont .steps .cont-text .bold {
    text-align: center;
  }
  .for-partners-content .tryyourself .cont .steps .cont-text .lower {
    text-align: center;
  }
  .for-partners-content .tryyourself .cont .steps .center-steps {
    flex-direction: column;
  }
  .for-partners-content .weworkwith {
    width: 100%;
    padding: 16px 16px;
  }
  .for-partners-content .weworkwith .title {
    font-size: 22px;
    line-height: 32px;
  }
  .for-partners-content .weworkwith .blocks {
    flex-direction: column;
  }
  .for-partners-content .weworkwith .blocks .block {
    width: 100%;
    height: auto;
    border-radius: 16px;
  }
  .for-partners-content .weworkwith .blocks .block img {
    width: 64px;
    height: 64px;
  }
  .for-partners-content .faq {
    width: 100%;
    padding: 16px 16px;
  }
  .for-partners-content .faq .cont-faq .title {
    font-size: 20px;
    line-height: 28px;
  }
  .for-partners-content .faq .cont-faq .item-accord-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .for-partners-content .faq .cont-faq .item-accord-group .item-accord {
    width: 100%;
  }
  .for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item {
    --min-height: 52px;
    --background: var(--surface);
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated-opacity: transparent;
    --background-focused-opacity: transparent;
    --background-hover-opacity: transparent;
    --border-width: 0 0 0 0;
    --ripple-color: transparent;
  }
  .for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item div {
    padding: 12px 0px;
    max-width: calc(100vw - 92px);
  }
  .for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item.accordion-expanded > [slot="header"] .ion-accordion-toggle-icon {
    transform: rotate(90deg);
  }
  .for-partners-content .faq .cont-faq .item-accord-group .item-accord ion-item.accordion-expanding > [slot="header"] .ion-accordion-toggle-icon {
    transform: rotate(90deg);
  }
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .about-content .back-image {
    min-width: 1260px;
    min-height: 560px;
    position: absolute;
    left: auto;
    right: auto;
    top: -76px;
  }
  .about-content .top-hat {
    height: 388px;
    padding: 16px 0px;
  }
  .about-content .top-hat .content {
    padding: 44px 0px;
    height: 356px;
  }
  .about-content .top-hat .content .center-content {
    padding: 0px 16px;
    gap: 16px;
    width: 100vw;
    height: 268px;
  }
  .about-content .top-hat .content .center-content .text .large-text {
    font-size: 32px;
    line-height: 40px;
  }
  .about-content .items {
    width: 100vw;
    padding: 16px 16px;
    gap: 16px;
    flex-direction: column;
  }
  .about-content .items .item {
    width: 100%;
    height: auto;
  }
  .about-content .values {
    width: 100vw;
    height: max-content;
    padding: 16px 16px;
  }
  .about-content .values .title {
    width: 100%;
    font-size: 22px;
    line-height: 32px;
  }
  .about-content .values .items-values {
    flex-direction: column;
  }
  .about-content .values .items-values .item-values {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .about-content .mission {
    width: 100vw;
    height: max-content;
    padding: 16px 16px;
  }
  .about-content .mission .gray {
    padding: 20px 16px;
    border-radius: 16px;
    align-items: flex-start;
    height: max-content;
  }
  .about-content .mission .info {
    width: 100%;
  }
  .about-content .mission .info .middle {
    font-size: 20px;
    line-height: 28px;
  }
  .inf-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .inf-content .cont-inf {
    width: 100vw;
    padding: 16px 16px;
    gap: 16px;
  }
  .inf-content .cont-inf .cont-1 {
    width: 100%;
  }
  .inf-content .cont-inf .cont-1 .title {
    font-size: 22px;
    line-height: 32px;
  }
  .inf-content .cont-inf .cont {
    width: 100%;
  }
  .inf-content .cont-inf .cont .title {
    font-size: 18px;
    line-height: 28px;
  }
  .cont-quick-show {
    overflow: hidden;
    width: 100vw;
    left: 0px;
    height: 100vh;
  }
  .cont-quick-show .header-quick {
    height: 96px;
    flex-direction: column;
    align-items: flex-start;
  }
  .cont-quick-show .header-quick .stages .el-tabs {
    height: 44px;
  }
  .cont-quick-show .header-quick .stages .el-tabs.active-tabs {
    height: calc(44px - 2px);
  }
  .cont-quick-show .header-quick .name {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .cont-quick-show .header-quick .name .title-header-quick {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100vw - 16px - 36px - 12px);
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .cont-quick-show .header-quick .close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .cont-quick-show .info-cont .scroll-quick {
    padding: 32px 10px 32px 16px !important;
    height: calc(var(--vh, 1vh) * 100 - 96px);
  }
  .cont-quick-show .info-cont .btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .cont-quick-show .info-cont .btns .btn-quick-show {
    padding: 0px 4px;
  }
  .cont-quick-show .info-cont .btns .btn-quick-show.new-window {
    display: none;
  }
  .cont-quick-show .info-cont .title-cont .title {
    font-size: 22px;
    line-height: 32px;
  }
  .cont-quick-show .info-cont .block-cont {
    flex-wrap: wrap;
  }
  .cont-quick-show .info-cont .block-cont .block {
    min-width: 155px;
  }
  .cont-quick-show .info-cont .cont-images .other-image {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
  .cont-quick-show .info-cont .cont-images .other-image .image {
    height: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    min-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-opposite {
    display: none;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .span {
    width: 20px;
    height: 20px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector {
    width: 1px;
    background: #e6e5ea;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .info-cont .cont-title .title {
    width: 300px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
    width: 256px;
  }
  .cont-quick-show .info-cont .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image {
    height: 120px;
    width: 120px;
  }
  .cont-quick-show .info-cont .rating-cont {
    flex-wrap: wrap;
    gap: 16px;
  }
  .cont-quick-show .info-cont .select-sort {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .cont-quick-show .info-cont .cont-reviews .items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .cont-quick-show .info-cont .cont-reviews .items .item .cont-response .response {
    width: 286px;
  }
  .body-show .cont-show {
    width: 100vw;
    padding: 16px 16px;
  }
  .body-show .cont-show .title-cont .title {
    font-size: 22px;
    line-height: 32px;
  }
  .body-show .cont-show .title-cont .genres {
    flex-wrap: wrap;
  }
  .body-show .cont-show .title-cont .rating {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
  .body-show .cont-show .images-cont {
    flex-direction: column;
    height: 488px;
  }
  .body-show .cont-show .images-cont .left {
    position: relative;
    max-width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
    overflow: hidden;
    height: 100%;
    border-radius: 8px;
  }
  .body-show .cont-show .images-cont .left .btn-all-photo {
    display: none;
  }
  .body-show .cont-show .images-cont .left img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .body-show .cont-show .images-cont .right {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  .body-show .cont-show .images-cont .right .image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc((100vw - 32px - 4px) / 2);
    min-width: calc((100vw - 32px - 4px) / 2);
    height: 120px;
    overflow: hidden;
    border-radius: 8px;
  }
  .body-show .cont-show .images-cont .right .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .body-show .cont-show .cont-information {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .body-show .cont-show .cont-information .left {
    padding: 0px 16px;
    width: 100vw;
  }
  .body-show .cont-show .cont-information .left .block-cont {
    flex-wrap: wrap;
  }
  .body-show .cont-show .cont-information .left .block-cont .block {
    min-width: calc(100vw / 2.5);
  }
  .body-show .cont-show .cont-information .left .suitable {
    width: 100%;
  }
  .body-show .cont-show .cont-information .left .suitable .title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .suitable .blocks-suitable .line .block-suitable {
    flex-direction: column;
    align-items: start;
    min-width: calc(100vw / 2.5);
    max-width: 100%;
  }
  .body-show .cont-show .cont-information .left .description-inc .title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .title-opt {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .map-cont .title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .map-cont .conter .cont-map-t #map-client {
    width: 100%;
  }
  .body-show .cont-show .cont-information .left .map-cont .conter .cont-map-t #map-client .leaflet-top {
    bottom: 16px !important;
    top: auto;
  }
  .body-show .cont-show .cont-information .left .cont-details .point-start .cont-images .image {
    height: 120px;
    width: 120px;
  }
  .body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content {
    width: 100%;
  }
  .body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
    width: calc(100vw - 64px - 32px);
  }
  .body-show .cont-show .cont-information .left .cont-details .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
    width: calc(100vw - 64px - 32px);
    flex-direction: column;
  }
  .body-show .cont-show .cont-information .left .cont-reviews .cont-stage {
    display: flex;
    flex-direction: row;
  }
  .body-show .cont-show .cont-information .left .cont-reviews .cont-stage .stage .text {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .cont-reviews .rating-cont {
    flex-wrap: wrap;
    gap: 16px;
  }
  .body-show .cont-show .cont-information .left .cont-reviews .cont-review .items .item .cont-response .response {
    width: calc(100vw - 64px - 32px);
  }
  .body-show .cont-show .cont-information .left .faq-cont .title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord {
    width: 100%;
  }
  .body-show .cont-show .cont-information .left .faq-cont .item-accord-group .item-accord ion-item div {
    max-width: calc(100vw - 106px);
  }
  .body-show .cont-show .cont-information .right {
    width: 100%;
    padding: 0px 16px;
  }
  .body-show .cont-show .cont-information .right .right-cont .races-comp-cont .title {
    font-size: 18px;
    line-height: 28px;
  }
  .body-show .cont-show .l {
    border-bottom: 1px solid var(--divider);
    width: 100%;
  }
  .body-show .cont-show .search-reg .title {
    font-size: 20px;
    line-height: 28px;
  }
  .body-show .cont-show .search-reg .no-array {
    height: 240px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body-show .cont-show .search-reg .no-array img {
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  .body-show .cont-show .search-reg .no-array .content {
    position: absolute;
    top: 108px;
  }
  .body-show .cont-show .search-reg .no-array .content .text {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .body-show .cont-show .similar-reg {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .body-show .cont-show .similar-reg .title {
    color: var(--text);
    font-family: var(--font-nunito-sans);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }
  .quick-s .cont-map-q {
    display: none;
    position: absolute;
    right: 0px;
    width: calc(100vw - (520px + 320px));
  }
  .quick-s .cont-map-q #map-show {
    width: calc(100vw - (520px + 320px));
    height: 100vh;
    position: relative;
  }
  .quick-s .cont-map-q #map-show .leaflet-top {
    bottom: 16px !important;
    top: auto;
  }
  .page-review {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
  }
  .page-review .body-rev-page {
    width: 100vw;
    padding: 16px 16px;
  }
  .page-review .body-rev-page .cont-title .title {
    font-size: 22px;
    line-height: 32px;
  }
  .page-review .body-rev-page .create-review-cont {
    flex-direction: column;
  }
  .page-review .body-rev-page .create-review-cont .left {
    width: 100%;
  }
  .page-review .body-rev-page .create-review-cont .left .review-info .area-reviews {
    height: 160px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .title {
    color: var(--text);
    font-family: var(--font-nunito-sans);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev {
    width: 728px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .image {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev {
    width: 592px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title .subtext {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-title .text {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .p-rating {
    gap: 0px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .p-rating .p-rating-item {
    box-shadow: none;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .cont-rating .val {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option {
    border-radius: 8px;
    width: 104px;
    height: 104px;
    overflow: hidden;
    position: relative;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option .btn-del-img {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .cont-image-option .main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add-img {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 104px;
    height: 104px;
    background-color: var(--neutral-25);
    border: 1px solid var(--divider);
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .add-img .image {
    cursor: pointer;
    width: max-content;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .title {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .cont-subtext {
    display: flex;
    flex-direction: column;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .body-rev .right-rev .add-image-cont .description-add-img .cont-subtext .subtext {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .page-review .body-rev-page .create-review-cont .left .create-rev-points .point-rev .l {
    border-bottom: 1px solid var(--divider);
  }
  .page-review .body-rev-page .create-review-cont .right .cont-send {
    width: 100%;
  }
  ion-modal.modal-create-question {
    --width: calc(100vw - 32px);
  }
  ion-modal.modal-cancel-orders {
    --max-height: calc(100vh - 32px);
    --width: calc(100vw - 32px);
  }
  ion-modal.modal-cancel-orders .modal-cancel-body .scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc((100vh - 32px) - 32px - 16px - 36px - 40px);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  ion-modal.modal-cancel-orders .modal-cancel-body .scroll::-webkit-scrollbar {
    display: none;
  }
  .favorite-cont .favorite-body {
    width: 100vw;
    padding: 16px 16px;
  }
  .favorite-cont .favorite-body .title-fav {
    font-size: 22px;
    line-height: 32px;
  }
  .favorite-cont .favorite-body .favotite {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour {
    flex-direction: column;
    gap: 16px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .image {
    width: calc(100vw - 32px);
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .image .cont-val-images {
    padding: 12px;
    width: 36px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour {
    width: 100%;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf {
    width: 100%;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .info-tour .cont-inf .title {
    font-size: 20px;
    line-height: 38px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body {
    width: 100%;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .summa {
    color: var(--text);
    font-family: var(--font-nunito-sans);
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .summa {
    font-size: 18px;
    line-height: 28px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .summa.promotion {
    color: var(--success);
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .summa-cont .row-price .last-price {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .buttonsdiv {
    flex-direction: row;
  }
  .favorite-cont .favorite-body .favotite .cont-fav-tour .summa-body .buttonsdiv .fav-btn {
    width: 100%;
    padding: 0px 0px;
  }
  .favorite-cont .favorite-body .l {
    border-bottom: 1px solid var(--divider);
  }
  .favorite-cont .favorite-body .title-most {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
  .favorite-cont .favorite-body .title-most .text {
    font-size: 20px;
    line-height: 28px;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular {
    width: 100%;
    gap: 16px;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour {
    width: calc((100vw - 32px - 16px) / 2);
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour .image .cont-val-images {
    padding: 8px;
    width: 36px;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont {
    display: flex;
    flex-direction: column;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .left {
    flex-wrap: wrap;
    gap: 0px;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .btn-fav {
    position: relative;
  }
  .favorite-cont .favorite-body .cont-tours-most-popular .tour .info .summa-cont .sum .btn-fav .text-btn svg path {
    stroke: transparent;
  }
}
