.orders-finance-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.orders-finance-page .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.reports-finance-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.reports-finance-page .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.reports-finance-page .cont-items {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
}
.reports-finance-page .cont-items .item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--divider);
  border-radius: 10px;
  padding: 16px;
  gap: 16px;
  box-shadow: 0px 1px 2px -0.25px rgba(0, 0, 0, 0.02), 0px 2px 4px -0.5px rgba(0, 0, 0, 0.04), 0px 4px 8px -1px rgba(0, 0, 0, 0.06);
}
.reports-finance-page .cont-items .item .text-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.reports-finance-page .cont-items .item .text-cont .image-text {
  width: 36px;
  height: 36px;
}
.reports-finance-page .cont-items .item .text-cont .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.reports-finance-page .cont-items .item .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.reports-finance-page .cont-items .item .btn-req {
  width: 127px;
}
.operations-finance-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.operations-finance-page .cont-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.operations-finance-page .cont-title .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.operations-finance-page .cont-title .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-detail-order {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 20px;
}
.page-detail-order .upper-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.page-detail-order .upper-line .tbr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-detail-order .upper-line .tbr .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.page-detail-order .upper-line .tbr .cont-l .btn-back {
  width: 36px;
}
.page-detail-order .upper-line .tbr .cont-l .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.page-detail-order .upper-line .tbr .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.page-detail-order .upper-line .status-cont {
  padding: 4px 0px;
}
.page-detail-order .upper-line .status-cont .status-h {
  margin-left: 48px;
}
.page-detail-order .body-content {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.page-detail-order .body-content .left {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.page-detail-order .body-content .left .item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  gap: 8px;
  border: 1px solid var(--divider);
}
.page-detail-order .body-content .left .item .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.page-detail-order .body-content .left .item .tablet table {
  font-family: var(--font-nunito);
  max-width: 100%;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  text-align: left;
}
.page-detail-order .body-content .left .item .tablet .el-tableth {
  border-bottom: 1px solid #e2e6e5;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 4px 6px 4px 14px;
  height: 52px;
  color: var(--text-muted);
}
.page-detail-order .body-content .left .item .tablet .el-tableth.description {
  min-width: 565px;
}
.page-detail-order .body-content .left .item .tablet .el-tableth .cont-text-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.page-detail-order .body-content .left .item .tablet .el-tableth:first-child {
  padding: 4px 6px 4px 6px;
}
.page-detail-order .body-content .left .item .tablet .line-tablet {
  border-bottom: 1px solid #e2e6e5;
  height: 52px;
}
.page-detail-order .body-content .left .item .tablet .line-tablet:last-child {
  border-bottom: none;
}
.page-detail-order .body-content .left .item .tablet .el-tablet {
  padding: 4px 6px 4px 14px;
  white-space: nowrap;
  word-break: break-word;
}
.page-detail-order .body-content .left .item .tablet .el-tablet.description {
  min-width: 565px;
}
.page-detail-order .body-content .left .item .tablet .el-tablet .text-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--link);
}
.page-detail-order .body-content .left .item .tablet .el-tablet .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.page-detail-order .body-content .left .item .tablet .el-tablet .up-text-create {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.page-detail-order .body-content .left .item .tablet .el-tablet .down-text-create {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-detail-order .body-content .left .item .tablet .el-tablet .down-text-create.green {
  color: var(--text-secondary);
}
.page-detail-order .body-content .left .item .tablet .el-tablet .down-text-create.gray {
  color: var(--text-muted);
}
.page-detail-order .body-content .left .item .tablet .el-tablet:first-child {
  padding: 4px 6px;
}
.page-detail-order .body-content .left .item .tablet tr {
  min-height: 52px;
}
.page-detail-order .body-content .right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
}
.page-detail-order .body-content .right .options {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.page-detail-order .body-content .right .options .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.page-detail-order .body-content .right .options .cont-info {
  display: flex;
  flex-direction: column;
}
.page-detail-order .body-content .right .options .cont-info .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.page-detail-order .body-content .right .options .cont-info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-detail-order .body-content .right .options .cont-info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.page-detail-order .body-content .right .options .cont-info .line-btn {
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  justify-content: space-between;
}
.page-detail-order .body-content .right .options .cont-info .line-btn .cont-text {
  display: flex;
  flex-direction: column;
}
.page-detail-order .body-content .right .options .cont-info .line-btn .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-detail-order .body-content .right .options .cont-info .line-btn .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.page-detail-order .body-content .right .history-orders {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid var(--divider);
  border-radius: 10px;
}
.page-detail-order .body-content .right .history-orders .cont-history {
  display: flex;
  flex-direction: column;
}
.page-detail-order .body-content .right .history-orders .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.page-detail-order .body-content .right .history-orders .line {
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 6px;
}
.page-detail-order .body-content .right .history-orders .line .cont-text {
  padding: 0px 4px;
}
.page-detail-order .body-content .right .history-orders .line .cont-text .text {
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.page-detail-order .body-content .right .history-orders .line .cont-text .subtext {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.page-detail-order .body-content .right .history-orders .line .cont-text .subtext.gray {
  color: var(--text-unfilled);
}
.page-detail-order .body-content .right .history-orders .line .cont-text .subtext.green {
  color: var(--text-filled-success);
}
.page-detail-order .body-content .right .history-orders .line .cont-text .subtext.red {
  color: var(--text-filled-error);
}
ion-modal.modal-cancel-order {
  --width: 480px;
  --height: 372px;
  --border-radius: 12px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .area-cancel-order {
  height: 160px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .btns {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-detail-operations-hist {
  --width: 480px;
  --border-radius: 12px;
  --height: auto;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line {
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 6px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .icon-ed path {
  stroke: var(--text-unfilled);
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text {
  display: flex;
  flex-direction: column;
  padding: 0px 4px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text .text {
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text .subtext {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text .subtext.green {
  color: var(--text-filled-success);
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text .subtext.gray {
  color: var(--text-unfilled);
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .history-orders .line .cont-text .subtext.red {
  color: var(--text-filled-error);
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .lin {
  width: 100%;
  border-top: 1px solid var(--divider);
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .cont-det {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .cont-det .line-det {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .cont-det .line-det .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .cont-det .line-det .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .info .details .cont-det .line-det .text.name {
  color: var(--link) !important;
  cursor: pointer;
}
ion-modal.modal-detail-operations-hist .body-modal-detail-operations-hist .btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
ion-modal.modal-cancel-order,
ion-modal.modal-charge-fine {
  --width: 480px;
  --height: max-content;
  --border-radius: 12px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order,
ion-modal.modal-charge-fine .body-modal-cancel-order {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .title,
ion-modal.modal-charge-fine .body-modal-cancel-order .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .line,
ion-modal.modal-charge-fine .body-modal-cancel-order .line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .line .title-line,
ion-modal.modal-charge-fine .body-modal-cancel-order .line .title-line {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .line .areaclose,
ion-modal.modal-charge-fine .body-modal-cancel-order .line .areaclose {
  height: 136px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .body-information,
ion-modal.modal-charge-fine .body-modal-cancel-order .body-information {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .body-information .line-req,
ion-modal.modal-charge-fine .body-modal-cancel-order .body-information .line-req {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .body-information .line-req .subtext,
ion-modal.modal-charge-fine .body-modal-cancel-order .body-information .line-req .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .body-information .line-req .text,
ion-modal.modal-charge-fine .body-modal-cancel-order .body-information .line-req .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .area-cancel,
ion-modal.modal-charge-fine .body-modal-cancel-order .area-cancel {
  height: 160px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .text,
ion-modal.modal-charge-fine .body-modal-cancel-order .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .text .bold,
ion-modal.modal-charge-fine .body-modal-cancel-order .text .bold {
  margin-left: 4px;
  font-weight: 700;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .input-change .cont-image svg path,
ion-modal.modal-charge-fine .body-modal-cancel-order .input-change .cont-image svg path {
  stroke: #696775;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .area-cancel-order,
ion-modal.modal-charge-fine .body-modal-cancel-order .area-cancel-order {
  height: 160px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .btns,
ion-modal.modal-charge-fine .body-modal-cancel-order .btns {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-cancel-order .body-modal-cancel-order .btns .btn-next,
ion-modal.modal-charge-fine .body-modal-cancel-order .btns .btn-next {
  width: 120px;
}
ion-modal.modal-withdraw {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-withdraw .body-modal-withdraw {
  display: flex;
  font-family: var(--font-nunito);
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}
ion-modal.modal-withdraw .body-modal-withdraw .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .text-send-req {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .header-type {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .header-type .text-checkbox {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: auto;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .header-type .width {
  width: auto;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .l {
  border-top: 1px solid var(--divider);
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .cont-page-1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .cont-page-1 .text-checkbox {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .cont-page-1 .available {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .cont-page-1 .cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-withdraw .body-modal-withdraw .infor .cont-page-1 .cont .subtext {
  padding: 0px 6px;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-withdraw .body-modal-withdraw .info-confirm {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-withdraw .body-modal-withdraw .info-confirm .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
ion-modal.modal-withdraw .body-modal-withdraw .info-confirm .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .info-confirm .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-withdraw .body-modal-withdraw .l {
  border-top: 1px solid var(--divider);
}
ion-modal.modal-withdraw .body-modal-withdraw .available {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
