.tour-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tour-list .tabs {
  display: flex;
  flex-direction: row;
}
.tour-list .tabs .el-tabs {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  user-select: none;
  height: 36px;
  cursor: pointer;
}
.tour-list .tabs .el-tabs .text-tabs {
  font-family: var(--font-nunito);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--tabs);
}
.tour-list .tabs .el-tabs .value-tabs {
  padding: 0px 6px;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: var(---circle-tabs-val);
  background-color: var(--circle-tabs-back);
  height: 20px;
  min-width: 20px;
  border-radius: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list .tabs .el-tabs:hover .text-tabs {
  color: var(--tabs-hover);
}
.tour-list .tabs .active-tabs {
  border-bottom: 3px solid var(--border-tabs-current);
}
.tour-list .tabs .active-tabs .text-tabs {
  color: var(--tabs-current);
}
.tour-list .tabs .active-tabs .value-tabs {
  color: var(--circle-tabs-current-val);
  background-color: var(--circle-tabs-current-back);
}
.tour-list .tabs .active-tabs:hover .text-tabs {
  color: var(--tabs-current);
}
.tour-list .cont-tablet {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.tour-list .cont-tablet .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tour-list .cont-tablet .filter .filter-search-cont {
  gap: 32px;
  display: flex;
  flex-direction: row;
}
.tour-list .cont-tablet .filter .filter-search-cont .input-search {
  width: 320px;
}
.tour-list .cont-tablet .filter .filter-search-cont .search-date {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tour-list .cont-tablet .filter .filter-search-cont .search-date .input-date {
  width: 156px;
}
.tour-list .cont-tablet .filter .btn-elem-filter {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tour-list .cont-tablet .filter .btn-elem-filter .btn-elem-f {
  width: 102px;
  padding: 0px 4px;
}
.tour-list .cont-tablet .filter .btn-elem-filter .btn-elem-e {
  width: 95px;
  padding: 0px 4px;
}
.tour-list .cont-tablet .chip-cont .chip {
  margin-left: 0;
  user-select: none;
  height: 24px;
  min-height: 24px;
  border-radius: 8px;
  padding: 0px 2px 0px 2px;
  border: 1px solid #ffa7a1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(0deg, #fde4e4, #fde4e4);
}
.tour-list .cont-tablet .chip-cont .chip .text {
  height: 16px;
  padding: 0px 6px 0px 6px;
  gap: 8px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #810038;
}
.tour-list .cont-tablet .chip-cont .chip .img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list .cont-tablet .tablet {
  overflow-y: hidden;
  overflow-x: auto;
  margin-right: 48px;
}
.tour-list .cont-tablet .tablet table {
  font-family: var(--font-nunito);
  max-width: 100%;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  text-align: left;
}
.tour-list .cont-tablet .tablet .el-tableth {
  border-bottom: 1px solid #e2e6e5;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 4px 6px 4px 14px;
  height: 52px;
  color: var(--text-muted);
}
.tour-list .cont-tablet .tablet .el-tableth.description {
  min-width: 565px;
}
.tour-list .cont-tablet .tablet .el-tableth .cont-text-table {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.tour-list .cont-tablet .tablet .el-tableth .cont-text-table .up {
  transform: rotate(180deg);
}
.tour-list .cont-tablet .tablet .el-tableth .cont-text-table .up g path {
  fill: #e12014;
}
.tour-list .cont-tablet .tablet .el-tableth .cont-text-table .down g path {
  fill: #e12014;
}
.tour-list .cont-tablet .tablet .el-tableth:first-child {
  padding: 4px 6px 4px 6px;
}
.tour-list .cont-tablet .tablet .el-tablet {
  cursor: pointer;
  border-bottom: 1px solid #e2e6e5;
  padding: 4px 6px 4px 14px;
  white-space: nowrap;
  word-break: break-word;
  height: 52px;
}
.tour-list .cont-tablet .tablet .el-tablet .setting .disabled {
  opacity: 40%;
}
.tour-list .cont-tablet .tablet .el-tablet.description {
  min-width: 565px;
}
.tour-list .cont-tablet .tablet .el-tablet .badge-table {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.tour-list .cont-tablet .tablet .el-tablet .text-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--link);
}
.tour-list .cont-tablet .tablet .el-tablet .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tour-list .cont-tablet .tablet .el-tablet .text-description {
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  max-width: 474px;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  white-space: normal;
}
.tour-list .cont-tablet .tablet .el-tablet .up-text-create {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tour-list .cont-tablet .tablet .el-tablet .down-text-create {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list .cont-tablet .tablet .el-tablet .badge {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.tour-list .cont-tablet .tablet .el-tablet .status {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0px 6px;
  border-radius: 800px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tour-list .cont-tablet .tablet .el-tablet:first-child {
  padding: 4px 6px;
}
.tour-list .cont-tablet .tablet .photo,
.tour-list .cont-tablet .tablet .pictures {
  width: 52px;
}
.tour-list .cont-tablet .tablet .photo .cont-img,
.tour-list .cont-tablet .tablet .pictures .cont-img {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list .cont-tablet .tablet .photo .cont-img img,
.tour-list .cont-tablet .tablet .pictures .cont-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.tour-list .cont-tablet .tablet .settings {
  position: absolute;
  z-index: 10;
  right: 32px;
  padding: 0;
  width: 48px;
  border-top: 1px solid var(--divider);
  border-bottom: none;
  height: 52px;
  padding-left: 8px;
  overflow: hidden;
  background-color: #fff;
}
.tour-list .cont-tablet .tablet .settings .setting {
  cursor: pointer;
  user-select: none;
  display: flex;
  width: 48px;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08), 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
}
.tour-list .cont-tablet .tablet .head {
  border-top: none !important;
}
.tour-list .cont-tablet .tablet tr {
  min-height: 52px;
}
.tour-list .cont-tablet .tablet::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 6px;
}
.tour-list .cont-tablet .tablet::-webkit-scrollbar-track {
  background: transparent;
}
.tour-list .cont-tablet .tablet::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #22222266;
}
.tour-list .cont-tablet .tablet::-webkit-scrollbar-track-piece:end {
  background: #22222214;
  margin-bottom: 8px;
  border-radius: 8px;
}
.tour-list .cont-tablet .tablet::-webkit-scrollbar-track-piece:start {
  background: #22222214;
  border-radius: 8px;
  margin-top: 8px;
}
.tour-list .cont-tablet .paginator {
  display: flex;
  font-family: var(--font-nunito);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: end;
}
.tour-list .cont-tablet .paginator .cont-select-el-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tour-list .cont-tablet .paginator .cont-select-el-list .btn-paginator {
  padding: 0px 4px;
}
.tour-list .cont-tablet .paginator .cont-select-el-list .text {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list .cont-tablet .paginator .cont-select-el-list .select-paginator {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60px;
  padding: 0px 4px;
  gap: 2px;
}
.tour-list .cont-tablet .paginator .cont-select-el-list .select-paginator .value {
  padding: 0px 2px 2px 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-unfilled);
}
.tour-list .cont-tablet .paginator .col {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list .cont-tablet .paginator .btn-next {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tour-list .cont-tablet .paginator .btn-next .arrow {
  cursor: pointer;
  user-select: none;
}
.tour-list .cont-tablet .paginator .btn-next .text {
  width: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text);
}
.tour-list .cont-tablet .paginator .btn-next .btn-page {
  width: 36px;
}
.tooltip-text {
  width: max-content;
  user-select: none;
  padding-right: 8px;
}
.p-tooltip-text {
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #353343;
}
.p-tooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tooltext {
  color: #fff;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
