.tourlist-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.tourlist-page .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tourlist-page .upper-line .title {
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tourlist-page .upper-line .btn-create {
  width: 110px;
  border-radius: 8px;
  gap: 2px;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}
.tourlist-page .upper-line .btn-create .text {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page .tour-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tourlist-page .tour-list .tabs {
  display: flex;
  flex-direction: row;
}
.tourlist-page .tour-list .tabs .el-tabs {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  user-select: none;
  height: 36px;
  cursor: pointer;
}
.tourlist-page .tour-list .tabs .el-tabs .text-tabs {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--tabs);
}
.tourlist-page .tour-list .tabs .el-tabs .value-tabs {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: var(---circle-tabs-val);
  background-color: var(--circle-tabs-back);
  height: 20px;
  min-width: 20px;
  border-radius: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
}
.tourlist-page .tour-list .tabs .el-tabs:hover .text-tabs {
  color: var(--tabs-hover);
}
.tourlist-page .tour-list .tabs .active-tabs {
  border-bottom: 3px solid var(--border-tabs-current);
}
.tourlist-page .tour-list .tabs .active-tabs .text-tabs {
  color: var(--tabs-current);
}
.tourlist-page .tour-list .tabs .active-tabs .value-tabs {
  color: var(--circle-tabs-current-val);
  background-color: var(--circle-tabs-current-back);
}
.tourlist-page .tour-list .tabs .active-tabs:hover .text-tabs {
  color: var(--tabs-current);
}
.tourlist-page .tour-list .cont-tablet-tour {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .filter-tour-search-cont {
  gap: 32px;
  display: flex;
  flex-direction: row;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .filter-tour-search-cont .input-search {
  width: 320px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .filter-tour-search-cont .search-date {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .filter-tour-search-cont .search-date .input-date {
  width: 156px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .btn-elem-filter {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .btn-elem-filter .btn-elem {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 0px 4px;
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .btn-elem-filter .btn-elem .text {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-muted);
}
.tourlist-page .tour-list .cont-tablet-tour .filter-tour .btn-elem-filter .btn-elem:hover .text {
  color: var(--text-muted-hover);
}
.tourlist-page .tour-list .cont-tablet-tour .chip-cont .chip {
  margin-left: 0;
  user-select: none;
  height: 24px;
  border-radius: 8px;
  padding: 0px 2px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(0deg, #fde4e4, #fde4e4);
}
.tourlist-page .tour-list .cont-tablet-tour .chip-cont .chip .text {
  height: 16px;
  padding: 0px 6px 0px 6px;
  gap: 8px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #810038;
}
.tourlist-page .tour-list .cont-tablet-tour .chip-cont .chip .img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour table {
  font-family: var(--font-nunito);
  max-width: 100%;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  text-align: left;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tableth {
  border-bottom: 1px solid #e2e6e5;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 4px 6px;
  height: 52px;
  color: var(--text-muted);
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tableth .cont-text-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet {
  border-bottom: 1px solid #e2e6e5;
  padding: 4px 6px;
  white-space: nowrap;
  word-break: break-word;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .text-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--link);
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .text-format,
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .text-price,
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .text-seats,
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .up-text-create {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .down-text-create {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet .status {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0px 6px;
  border-radius: 800px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._1 {
  background: #e2e6e5;
  color: #202226;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._2 {
  background: #ffd1a7;
  color: #850000;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._3 {
  background: #fececb;
  color: #7d1707;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._4 {
  background: #bbe4b5;
  color: #004a18;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._5 {
  background: #bbe4b5;
  color: #004a18;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .el-tablet ._6 {
  background: #e2e6e5;
  color: #202226;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .photo {
  width: 52px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .photo .cont-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .format {
  min-width: 79px;
  padding: 4px 6px 4px 14px;
  width: 79px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .create {
  width: 123px;
  padding: 4px 6px 4px 14px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .statuses {
  width: 144px;
  padding: 4px 6px 4px 14px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .seats {
  min-width: 60px;
  padding: 4px 6px 4px 14px;
  width: 60px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .price {
  min-width: 60px;
  width: 60px;
  padding: 4px 6px 4px 14px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .name {
  padding: 4px 6px 4px 14px;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .settings {
  padding: 0;
  width: 48px;
  height: 52px;
  padding-left: 8px;
  overflow: hidden;
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour .settings .setting {
  cursor: pointer;
  user-select: none;
  display: flex;
  width: 48px;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08), 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
}
.tourlist-page .tour-list .cont-tablet-tour .tablet-tour tr {
  min-height: 52px;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator {
  display: flex;
  font-family: var(--font-nunito);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: end;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .cont-select-el-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .cont-select-el-list .text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .cont-select-el-list .select-paginator {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60px;
  padding: 0px 4px;
  gap: 2px;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .cont-select-el-list .select-paginator .value {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #606b6c;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .col {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .btn-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .btn-next .text {
  width: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text);
}
.tourlist-page .tour-list .cont-tablet-tour .paginator .btn-next img {
  padding: 8px;
  cursor: pointer;
}
.showcase-options {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.showcase-options .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.showcase-options .upper-line .title {
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.promotions-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.promotions-page .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 0px 32px;
}
.promotions-page .upper-line .title {
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.popover-paginator-el-list {
  --box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08),
    0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  --backdrop-opacity: 0;
}
.popover-paginator-el-list::part(content) {
  border-radius: 8px;
}
.popover-paginator-el-list .body-popover {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
.popover-paginator-el-list .body-popover .cont-el-pop {
  cursor: pointer;
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
}
.popover-paginator-el-list .body-popover .cont-el-pop .text {
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #606b6c;
}
.tourlist-page-create {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.tourlist-page-create .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tourlist-page-create .upper-line .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.tourlist-page-create .upper-line .cont-l .btn-back {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.tourlist-page-create .upper-line .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tourlist-page-create .upper-line-put {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .upper-line-put .tbr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tourlist-page-create .upper-line-put .tbr .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.tourlist-page-create .upper-line-put .tbr .cont-l .btn-back {
  width: 36px;
}
.tourlist-page-create .upper-line-put .tbr .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tourlist-page-create .upper-line-put .tbr .btns .btn-save {
  width: 107px;
}
.tourlist-page-create .upper-line-put .last-change {
  width: 100%;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-left: 48px;
}
.tourlist-page-create .upper-line-put .status-cont {
  padding: 4px 48px;
}
.tourlist-page-create .stage {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.tourlist-page-create .stage .active {
  background-color: var(--unfilled);
}
.tourlist-page-create .stage .active .info-item .text {
  color: var(--text-unfilled-current) !important;
}
.tourlist-page-create .stage .item {
  cursor: pointer;
  width: 140px;
  user-select: none;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 6px;
  gap: 2px;
}
.tourlist-page-create .stage .item .icon-stage {
  width: 24px;
  height: 24px;
}
.tourlist-page-create .stage .item .info-item {
  display: flex;
  flex-direction: column;
  padding: 0px 4px;
}
.tourlist-page-create .stage .item .info-item .text {
  font-family: var(--font-nunito);
  color: var(--text-unfilled);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.tourlist-page-create .stage .item .info-item .muted {
  font-family: var(--font-nunito);
  color: var(--text-unfilled);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .stage .item .info-item .green {
  color: var(--text-filled-success) !important;
}
.tourlist-page-create .stage .item:hover {
  background-color: #f9f9fb !important;
}
.tourlist-page-create .cont-stage-one-create-tour {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont {
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .title-line {
  width: 184px;
  padding: 4px 6px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .title-line.up {
  margin-bottom: 20px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .cont-input-subtext {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .cont-input-subtext .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 0px 6px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .input-line {
  width: 484px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .line .select-line {
  width: 484px;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .l {
  border-top: 1px solid var(--text);
  opacity: 10%;
  width: 100%;
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .title-create-tour {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.tourlist-page-create .cont-stage-one-create-tour .input-cont .alert-create-tour {
  padding: 4px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable {
  display: flex;
  flex-direction: column;
  width: 504px;
  min-width: 344px;
  border-radius: 10px;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-btn .btn {
  width: 36px;
  height: 36px;
  padding: 0px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-btn .val {
  width: 36px;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-text {
  display: flex;
  flex-direction: column;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-text .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .line .cont-text .subtext {
  font-family: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .l {
  border-top: 1px solid var(--text);
  opacity: 10%;
  width: 100%;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .toggle-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .suitable .toggle-line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .responsible {
  display: flex;
  flex-direction: column;
  width: 504px;
  min-width: 344px;
  border-radius: 10px;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .responsible .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .responsible .cont-sel {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-one-create-tour .dop-colmn .responsible .cont-sel .muted {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .description {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .description .title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .description .title .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .description .title .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .description .p-editor-container .p-editor-content .ql-editor {
  background: inherit;
  color: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .title-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .title-cont .text-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .title-cont .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .title-cont .text-cont .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .cont-faqs {
  max-height: 360px;
  height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid var(--divider);
  border-radius: 10px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .cont-faqs .faq-partner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 680px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .cont-faqs .faq-partner .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .cont-faqs .faq-partner .cont-toggle .cont-text {
  max-width: 596px;
}
.tourlist-page-create .cont-stage-two-create-tour .contl .faq-cont .cont-faqs .l {
  border-bottom: 1px solid var(--divider);
}
.tourlist-page-create .cont-stage-two-create-tour .contr {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .title .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .title .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .cont-image-main {
  width: 504px;
  height: 304px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .cont-image-main .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .cont-image-main .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .main .main-image {
  background-color: var(--neutral-25);
  cursor: pointer;
  user-select: none;
  width: 504px;
  height: 304px;
  border-radius: 8px;
  border: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 504px;
  overflow: hidden;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .title .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .title .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images .image-f {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 104px;
  height: 104px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images .image-f .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images .image-f .other-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.tourlist-page-create .cont-stage-two-create-tour .contr .more .images .image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  border: 1px solid var(--divider);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  background-color: var(--neutral-25);
}
.tourlist-page-create .cont-stage-three-create-tour {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl {
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--divider);
  padding: 20px;
  gap: 16px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .upper .btn-set-point {
  width: 36px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .upper .text-cont {
  display: flex;
  flex-direction: column;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .upper .text-cont .surtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .upper .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .descr {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .images .cont-img {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 104px;
  height: 104px;
  border-radius: 8px;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .point-cont .images .cont-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tourlist-page-create .cont-stage-three-create-tour .contl .add {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  justify-content: center;
  gap: 2px;
  width: 100%;
  height: 76px;
  border: 1px solid var(--divider);
}
.tourlist-page-create .cont-stage-three-create-tour .contl .add .text {
  color: var(--text-unfilled);
  font-family: var(--divider);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: lowercase;
  padding: 0px 4px 2px 4px;
}
.tourlist-page-create .cont-stage-three-create-tour .contr .cont-map-c #map-create {
  width: 504px;
  height: 440px;
  border-radius: 12px;
  position: relative;
}
.tour-info-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
}
.tour-info-page .upper-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tour-info-page .upper-line .tbr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tour-info-page .upper-line .tbr .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.tour-info-page .upper-line .tbr .cont-l .btn-back {
  width: 36px;
}
.tour-info-page .upper-line .tbr .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tour-info-page .upper-line .tbr .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tour-info-page .upper-line .status-cont {
  padding: 4px 0px;
}
.tour-info-page .upper-line .status-cont .status-h {
  margin-left: 48px;
}
.tour-info-page .upper-line .last-change {
  width: 100%;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-left: 48px;
}
.tour-info-page .cont-information {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.tour-info-page .cont-information .block-1,
.tour-info-page .cont-information .block-2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tour-info-page .cont-information .block-1 .item-image,
.tour-info-page .cont-information .block-2 .item-image {
  position: relative;
  border-radius: 10px;
  width: 360px;
  min-height: 276px;
  overflow: hidden;
}
.tour-info-page .cont-information .block-1 .item-image img,
.tour-info-page .cont-information .block-2 .item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour-info-page .cont-information .block-1 .item-image.no-image,
.tour-info-page .cont-information .block-2 .item-image.no-image {
  background-color: var(--neutral-25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-info-page .cont-information .block-1 .item-image.no-image .img-no,
.tour-info-page .cont-information .block-2 .item-image.no-image .img-no {
  width: 48px;
  height: 48px;
}
.tour-info-page .cont-information .block-1 .item-image.no-image .img-no path,
.tour-info-page .cont-information .block-2 .item-image.no-image .img-no path {
  stroke: #b0afb8;
}
.tour-info-page .cont-information .block-1 .item-image .val-photo,
.tour-info-page .cont-information .block-2 .item-image .val-photo {
  user-select: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  font-family: var(--font-source);
  color: var(--neutral);
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tour-info-page .cont-information .block-1 .item-rating,
.tour-info-page .cont-information .block-2 .item-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  gap: 8px;
  border: 1px solid var(--divider);
  border-radius: 10px;
}
.tour-info-page .cont-information .block-1 .item-rating .rat,
.tour-info-page .cont-information .block-2 .item-rating .rat {
  width: 36px;
  height: 36px;
}
.tour-info-page .cont-information .block-1 .item-rating .text-rat,
.tour-info-page .cont-information .block-2 .item-rating .text-rat {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper,
.tour-info-page .cont-information .block-2 .upper {
  display: flex;
  flex-direction: row;
  flex: auto;
  width: 100%;
  gap: 16px;
}
.tour-info-page .cont-information .block-1 .upper .item,
.tour-info-page .cont-information .block-2 .upper .item {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 346.67px;
  min-height: 240px;
  border-radius: 10px;
  padding: 16px;
  gap: 8px;
  justify-content: space-between;
  border: 1px solid var(--divider);
  align-items: flex-start;
}
.tour-info-page .cont-information .block-1 .upper .item .info-icon,
.tour-info-page .cont-information .block-2 .upper .item .info-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  user-select: none;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont,
.tour-info-page .cont-information .block-2 .upper .item .title-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .title,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance {
  height: 20px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance .p-inputtext,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance .p-inputtext {
  color: var(--text);
  padding: 0px 4px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance .p-dropdown-trigger,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance .p-dropdown-trigger {
  width: 16px;
  height: 16px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance .p-dropdown-trigger svg path,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance .p-dropdown-trigger svg path {
  stroke: var(--text);
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance-year,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance-year {
  height: 20px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance-year .p-inputtext,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance-year .p-inputtext {
  color: var(--text);
  padding: 0px 4px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance-year .p-dropdown-trigger,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance-year .p-dropdown-trigger {
  width: 16px;
  height: 16px;
}
.tour-info-page .cont-information .block-1 .upper .item .title-cont .select-finance-year .p-dropdown-trigger svg path,
.tour-info-page .cont-information .block-2 .upper .item .title-cont .select-finance-year .p-dropdown-trigger svg path {
  stroke: var(--text);
}
.tour-info-page .cont-information .block-1 .upper .item .char .char-text .val,
.tour-info-page .cont-information .block-2 .upper .item .char .char-text .val {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .char .char-text .prc,
.tour-info-page .cont-information .block-2 .upper .item .char .char-text .prc {
  color: #029c3f;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .cont-load,
.tour-info-page .cont-information .block-2 .upper .item .cont-load {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 136px;
}
.tour-info-page .cont-information .block-1 .upper .item .cont-load .seat .val-text,
.tour-info-page .cont-information .block-2 .upper .item .cont-load .seat .val-text {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .cont-load .seat .val-subtext,
.tour-info-page .cont-information .block-2 .upper .item .cont-load .seat .val-subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .cont-load .line-graph,
.tour-info-page .cont-information .block-2 .upper .item .cont-load .line-graph {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: var(--inactive-progress);
}
.tour-info-page .cont-information .block-1 .upper .item .cont-load .line-graph .val-graph,
.tour-info-page .cont-information .block-2 .upper .item .cont-load .line-graph .val-graph {
  height: 8px;
  background-color: #fe8034;
  border-radius: 8px;
  max-width: 100%;
}
.tour-info-page .cont-information .block-1 .upper .item .graph,
.tour-info-page .cont-information .block-2 .upper .item .graph {
  height: 80px;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item,
.tour-info-page .cont-information .block-2 .upper .item .body-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .genre,
.tour-info-page .cont-information .block-2 .upper .item .body-item .genre {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .genre .genre-block,
.tour-info-page .cont-information .block-2 .upper .item .body-item .genre .genre-block {
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .genre .genre-block._red,
.tour-info-page .cont-information .block-2 .upper .item .body-item .genre .genre-block._red {
  background-color: var(--badge-red);
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .genre .genre-block._blue,
.tour-info-page .cont-information .block-2 .upper .item .body-item .genre .genre-block._blue {
  background-color: var(--badge-blue);
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .create,
.tour-info-page .cont-information .block-2 .upper .item .body-item .create,
.tour-info-page .cont-information .block-1 .upper .item .body-item .responsible,
.tour-info-page .cont-information .block-2 .upper .item .body-item .responsible {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .create .subtext,
.tour-info-page .cont-information .block-2 .upper .item .body-item .create .subtext,
.tour-info-page .cont-information .block-1 .upper .item .body-item .responsible .subtext,
.tour-info-page .cont-information .block-2 .upper .item .body-item .responsible .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .upper .item .body-item .create .text,
.tour-info-page .cont-information .block-2 .upper .item .body-item .create .text,
.tour-info-page .cont-information .block-1 .upper .item .body-item .responsible .text,
.tour-info-page .cont-information .block-2 .upper .item .body-item .responsible .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .lower,
.tour-info-page .cont-information .block-2 .lower {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.tour-info-page .cont-information .block-1 .lower .small-item,
.tour-info-page .cont-information .block-2 .lower .small-item {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 201.6px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 16px;
  border: 1px solid var(--divider);
  border-radius: 10px;
}
.tour-info-page .cont-information .block-1 .lower .small-item .info-icon,
.tour-info-page .cont-information .block-2 .lower .small-item .info-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  user-select: none;
}
.tour-info-page .cont-information .block-1 .lower .small-item .title,
.tour-info-page .cont-information .block-2 .lower .small-item .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .lower .small-item .value,
.tour-info-page .cont-information .block-2 .lower .small-item .value {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.tour-info-page .cont-information .block-1 .lower .small-item .cont-val,
.tour-info-page .cont-information .block-2 .lower .small-item .cont-val {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.tour-info-page .cont-information .block-2 {
  width: 100%;
}
.sales-schedule {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sales-schedule .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.sales-schedule .filter-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sales-schedule .filter-line .l {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.sales-schedule .filter-line .l .input-search-filter-sales {
  width: 320px;
}
.sales-schedule .filter-line .l .cont-cal-search {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.sales-schedule .filter-line .l .cont-cal-search .input-cal-filter-sales {
  width: 156px;
}
.sales-schedule .filter-line .r {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.sales-schedule .table-sales {
  width: 100%;
  position: relative;
  height: 224px;
  overflow: hidden;
  border-radius: 10px;
}
.sales-schedule .table-sales .back-img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sales-schedule .table-sales .content {
  padding: 32px 16px;
  gap: 16px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sales-schedule .table-sales .content .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.sales-schedule .table-sales .content .cont-text .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.sales-schedule .table-sales .content .cont-text .subtext {
  display: flex;
  flex-direction: column;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
ion-modal.modal-sumbit-for-moderation {
  --width: 480px;
  --height: 316px;
  --border-radius: 12px;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .cont-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .cont-text .up-t {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .cont-text .up-t .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .cont-text .up-t .boldtext {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-sumbit-for-moderation .body-modal-sumbit-for-moderation .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-oper-no-moderation {
  --width: 480px;
  --height: 220px;
  --border-radius: 12px;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation .cont-text {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation .cont-text .link {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-oper-no-moderation .body-modal-oper-no-moderation .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-delete {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
ion-modal.modal-delete.tour {
  --height: auto !important;
}
ion-modal.modal-delete .body-modal-delete {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-delete .body-modal-delete .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-delete .body-modal-delete .cont-text {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-delete .body-modal-delete .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-delete .body-modal-delete .cont-text .link {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-delete .body-modal-delete .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-start-selling,
ion-modal.modal-make-draft,
ion-modal.modal-archive-tour {
  --width: 480px;
  --height: 196px;
  --border-radius: 12px;
}
ion-modal.modal-start-selling .body-modal-start-selling,
ion-modal.modal-make-draft .body-modal-start-selling,
ion-modal.modal-archive-tour .body-modal-start-selling,
ion-modal.modal-start-selling .body-modal-make-draft,
ion-modal.modal-make-draft .body-modal-make-draft,
ion-modal.modal-archive-tour .body-modal-make-draft,
ion-modal.modal-start-selling .body-modal-archive-tour,
ion-modal.modal-make-draft .body-modal-archive-tour,
ion-modal.modal-archive-tour .body-modal-archive-tour {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-start-selling .body-modal-start-selling .title,
ion-modal.modal-make-draft .body-modal-start-selling .title,
ion-modal.modal-archive-tour .body-modal-start-selling .title,
ion-modal.modal-start-selling .body-modal-make-draft .title,
ion-modal.modal-make-draft .body-modal-make-draft .title,
ion-modal.modal-archive-tour .body-modal-make-draft .title,
ion-modal.modal-start-selling .body-modal-archive-tour .title,
ion-modal.modal-make-draft .body-modal-archive-tour .title,
ion-modal.modal-archive-tour .body-modal-archive-tour .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-start-selling .body-modal-start-selling .cont-text,
ion-modal.modal-make-draft .body-modal-start-selling .cont-text,
ion-modal.modal-archive-tour .body-modal-start-selling .cont-text,
ion-modal.modal-start-selling .body-modal-make-draft .cont-text,
ion-modal.modal-make-draft .body-modal-make-draft .cont-text,
ion-modal.modal-archive-tour .body-modal-make-draft .cont-text,
ion-modal.modal-start-selling .body-modal-archive-tour .cont-text,
ion-modal.modal-make-draft .body-modal-archive-tour .cont-text,
ion-modal.modal-archive-tour .body-modal-archive-tour .cont-text {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-start-selling .body-modal-start-selling .cont-text .text,
ion-modal.modal-make-draft .body-modal-start-selling .cont-text .text,
ion-modal.modal-archive-tour .body-modal-start-selling .cont-text .text,
ion-modal.modal-start-selling .body-modal-make-draft .cont-text .text,
ion-modal.modal-make-draft .body-modal-make-draft .cont-text .text,
ion-modal.modal-archive-tour .body-modal-make-draft .cont-text .text,
ion-modal.modal-start-selling .body-modal-archive-tour .cont-text .text,
ion-modal.modal-make-draft .body-modal-archive-tour .cont-text .text,
ion-modal.modal-archive-tour .body-modal-archive-tour .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-start-selling .body-modal-start-selling .cont-text .link,
ion-modal.modal-make-draft .body-modal-start-selling .cont-text .link,
ion-modal.modal-archive-tour .body-modal-start-selling .cont-text .link,
ion-modal.modal-start-selling .body-modal-make-draft .cont-text .link,
ion-modal.modal-make-draft .body-modal-make-draft .cont-text .link,
ion-modal.modal-archive-tour .body-modal-make-draft .cont-text .link,
ion-modal.modal-start-selling .body-modal-archive-tour .cont-text .link,
ion-modal.modal-make-draft .body-modal-archive-tour .cont-text .link,
ion-modal.modal-archive-tour .body-modal-archive-tour .cont-text .link {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-start-selling .body-modal-start-selling .btns,
ion-modal.modal-make-draft .body-modal-start-selling .btns,
ion-modal.modal-archive-tour .body-modal-start-selling .btns,
ion-modal.modal-start-selling .body-modal-make-draft .btns,
ion-modal.modal-make-draft .body-modal-make-draft .btns,
ion-modal.modal-archive-tour .body-modal-make-draft .btns,
ion-modal.modal-start-selling .body-modal-archive-tour .btns,
ion-modal.modal-make-draft .body-modal-archive-tour .btns,
ion-modal.modal-archive-tour .body-modal-archive-tour .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-menu.menu-right-add-coor {
  --width: 440px;
}
ion-menu.menu-right-add-coor .menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}
ion-menu.menu-right-add-coor .menu .top .coordinates {
  height: 64px;
  padding: 20px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.menu-right-add-coor .menu .top .cont-map-add-coor #map-add-coor {
  width: 100%;
  height: 320px;
  position: relative;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .inp-add-coor {
  padding: 6px;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .select-line {
  padding: 10px 12px;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .time-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .time-cont .time {
  width: max-content;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .time-cont .cont-select {
  width: 100%;
}
ion-menu.menu-right-add-coor .menu .top .inf-cont .textarea-add-coor {
  height: 200px;
  padding: 6px 6px;
}
ion-menu.menu-right-add-coor .menu .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 20px;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08), 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
}
ion-menu.menu-right-add-coor .menu-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
ion-menu.menu-right-add-coor .menu-images .top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}
ion-menu.menu-right-add-coor .menu-images .top .text-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.menu-right-add-coor .menu-images .top .text-cont .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.menu-right-add-coor .menu-images .top .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont {
  border: 1px solid var(--divider);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 16px;
  border-radius: 10px;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .text-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .text-cont .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .images {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .images .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .images .add-img {
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 104px;
  height: 104px;
  border: 1px solid var(--divider);
  border-radius: 8px;
  background-color: var(--neutral-25);
  user-select: none;
  cursor: pointer;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .images .add-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-menu.menu-right-add-coor .menu-images .top .add-image-cont .images .add-img .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
ion-menu.menu-right-add-coor .menu-images .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 20px;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08), 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
}
ion-modal.modal-create-options {
  --width: 480px;
  --height: 640px;
  --border-radius: 12px;
}
ion-modal.modal-create-options .body-modal-create-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-create-options .body-modal-create-options .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-create-options .body-modal-create-options .description {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-create-options .body-modal-create-options .line {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-create-options .body-modal-create-options .line .input-create-options {
  width: 220px;
}
ion-modal.modal-create-options .body-modal-create-options .area-create-options {
  height: 160px;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .cont-image-option {
  border-radius: 8px;
  width: 104px;
  height: 104px;
  overflow: hidden;
  position: relative;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .cont-image-option .btn-del-img {
  position: absolute;
  top: 4px;
  right: 4px;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .cont-image-option .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .add-img {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 104px;
  height: 104px;
  background-color: var(--neutral-25);
  border: 1px solid var(--divider);
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .add-img .image {
  cursor: pointer;
  width: max-content;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .description-add-img {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .description-add-img .cont-subtext {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-create-options .body-modal-create-options .add-image-cont .description-add-img .cont-subtext .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-modal.modal-create-options .body-modal-create-options .btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
ion-modal.modal-create-options .body-modal-create-options .btns .btn-save {
  width: 107px;
}
.body-create-promotions {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.body-create-promotions .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.body-create-promotions .upper-line .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.body-create-promotions .upper-line .cont-l .btn-back {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.body-create-promotions .upper-line .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.body-create-promotions .upper-line .btn-save-draft {
  width: 107px;
}
.body-create-promotions .upper-line-put {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-create-promotions .upper-line-put .tbr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-create-promotions .upper-line-put .tbr .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.body-create-promotions .upper-line-put .tbr .cont-l .btn-back {
  width: 36px;
}
.body-create-promotions .upper-line-put .tbr .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.body-create-promotions .upper-line-put .tbr .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.body-create-promotions .upper-line-put .tbr .btns .btn-save-draft {
  width: 107px;
}
.body-create-promotions .upper-line-put .last-change {
  width: 100%;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-left: 48px;
}
.body-create-promotions .upper-line-put .status-cont {
  padding: 4px 48px;
}
.body-create-promotions .body {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.body-create-promotions .body .input-cont {
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-create-promotions .body .input-cont .line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.body-create-promotions .body .input-cont .line.line-a {
  align-items: baseline;
}
.body-create-promotions .body .input-cont .line .cont-input-line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.body-create-promotions .body .input-cont .line .cont-input-line .left-input-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-create-promotions .body .input-cont .line .cont-input-line .left-input-line .input-line-s {
  width: 236px;
}
.body-create-promotions .body .input-cont .line .cont-input-line .left-input-line .subtext {
  padding: 0px 6px;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-create-promotions .body .input-cont .line .cont-input-line .select-line-s {
  width: 236px;
}
.body-create-promotions .body .input-cont .line .cont-colors-cube {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.body-create-promotions .body .input-cont .line .cont-colors-cube .color-cube {
  position: relative;
  cursor: pointer;
  height: 44px;
  width: 44.4px;
  border-radius: 8px;
}
.body-create-promotions .body .input-cont .line .cont-colors-cube .color-cube .icon-c {
  position: absolute;
  top: 2px;
  right: 2px;
}
.body-create-promotions .body .input-cont .line .cont-colors-cube .color-cube.focus {
  border: 1px solid #353343;
}
.body-create-promotions .body .input-cont .line .title-line {
  width: 184px;
  padding: 4px 6px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-create-promotions .body .input-cont .line .title-line.up {
  margin-bottom: 20px;
}
.body-create-promotions .body .input-cont .line .title-line-a {
  display: flex;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .sel-opt {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .sel-opt .select-line-a {
  width: 334px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .sel-opt .btn-create {
  width: 138px;
  border-radius: 12px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--divider);
  padding: 12px;
  border-radius: 10px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .cont-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 4px 0px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .cont-info .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .cont-info .cont-subtext {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .cont-info .cont-subtext .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-create-promotions .body .input-cont .line .cont-sel-opt .cont-options .justify .cont-info .cont-subtext .link {
  cursor: pointer;
  user-select: none;
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-create-promotions .body .input-cont .line .cont-input-subtext {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.body-create-promotions .body .input-cont .line .cont-input-subtext .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 0px 6px;
}
.body-create-promotions .body .input-cont .line .input-line {
  width: 484px;
}
.body-create-promotions .body .input-cont .line .textarea-line {
  width: 484px;
  height: 160px;
}
.body-create-promotions .body .input-cont .line .select-line {
  width: 484px;
}
.body-create-promotions .body .tour-sel-cont {
  width: 504px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--divider);
}
.body-create-promotions .body .tour-sel-cont .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left .image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left .text-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0px;
  gap: 4px;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left .text-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .cont-info .left .text-cont .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .l {
  border-top: 1px solid #222030;
  opacity: 10%;
}
.body-create-promotions .body .tour-sel-cont .cont-tours-prev .l:last-child {
  border-top: none;
}
ion-modal.modal-show-promotion {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-show-promotion .body-modal-show-promotion {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage {
  height: 40px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  gap: 2px;
  cursor: pointer;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage.active {
  border-bottom: 2px solid #f74439;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage.active .text-stage {
  color: #f74439 !important;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage.active .val-stage {
  background-color: #4b4957;
  color: #fff;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage .text-stage {
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 2px;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage .val-stage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  border-radius: 800px;
  background-color: var(--status-gray);
  color: var(--text-unfilled-current);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .stages .stage:hover .text-stage {
  color: var(--text-unfilled-current);
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line-t {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line-t .name {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .cont-info .line-t .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .description {
  width: 100%;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-show-promotion .body-modal-show-promotion .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-menu.menu-right-add-races,
ion-menu.menu-right-add-races-change {
  --width: 320px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races {
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-title,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-title .title,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-title .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-title .subtext,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-title .subtext {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .l,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .l {
  width: 100%;
  border-top: 1px solid var(--divider);
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .sel-options,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .sel-options {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt {
  position: relative;
  border-radius: 10px;
  padding: 12px;
  gap: 12px;
  border: 1px solid var(--divider);
  display: flex;
  flex-direction: row;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .cont-image,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .cont-image {
  min-width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .cont-image img,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .cont-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .cont-inf,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .cont-inf {
  width: 128px;
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  gap: 4px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .cont-inf .text,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .cont-inf .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .cont-inf .subtext,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .cont-inf .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .options .cont-opt .btn-dot,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .options .cont-opt .btn-dot {
  position: absolute;
  top: 8px;
  right: 8px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage {
  height: 40px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  gap: 2px;
  cursor: pointer;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage.active,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage.active {
  border-bottom: 2px solid #f74439;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage.active .text-stage,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage.active .text-stage {
  color: #f74439 !important;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage.active .val-stage,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage.active .val-stage {
  background-color: #4b4957;
  color: #fff;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage .text-stage,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage .text-stage {
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 2px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .stages .stage:hover .text-stage,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .stages .stage:hover .text-stage {
  color: var(--text-unfilled-current);
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .date-cont,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .date-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .num-dates,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .num-dates {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .subtext,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .subtext {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-inputs,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-inputs .cont,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-inputs .cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-inputs .cont .title,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-inputs .cont .title {
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-menu.menu-right-add-races .cont-menu-right-add-races .cont-inputs .cont .subtext,
ion-menu.menu-right-add-races-change .cont-menu-right-add-races .cont-inputs .cont .subtext {
  padding: 0px 6px;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-menu.menu-right-add-races .btns,
ion-menu.menu-right-add-races-change .btns {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 20px;
  box-shadow: 0px 3px 6px -1px #0000000f, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001a;
}
::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
  border-radius: 8px;
}
::-webkit-scrollbar-track-piece:start {
  border-radius: 8px;
  margin-top: 8px;
}
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #22222266;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
@media screen and (max-width: 720px) {
  ion-modal.modal-delete {
    --width: calc(100vw - 32px);
  }
}
