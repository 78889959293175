ion-content.page {
  --offset-top: -96px !important;
}
ion-content.page-guest {
  --offset-top: -76px !important;
}
.header {
  width: 100vw;
  z-index: 1;
  height: 96px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid #e2e6e5;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  padding: 16px 32px 0px 32px;
}
.header .upper-line-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header .upper-line-header .left-segment {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.header .upper-line-header .left-segment .logo {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.header .upper-line-header .left-segment ._company {
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .upper-line-header .left-segment ._company .status .icon-com {
  cursor: pointer;
}
.header .upper-line-header .left-segment ._company .status .icon-warning path {
  stroke: var(--icon-warning);
}
.header .upper-line-header .left-segment ._company .status .overlay {
  display: none;
  position: absolute;
  box-shadow: 0px 3px 6px -1px #0000000f, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001a;
  background-color: #ffffff;
  max-width: 240px;
  width: max-content;
  height: auto;
  border-radius: 10px;
  padding: 12px;
}
.header .upper-line-header .left-segment ._company .status .overlay .text {
  user-select: none;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.header .upper-line-header .left-segment ._company .status:hover .overlay {
  display: block;
}
.header .upper-line-header .left-segment ._company span {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.header .upper-line-header .left-segment ._company .ver {
  color: var(--success);
}
.header .upper-line-header .left-segment ._company .nover {
  color: var(--warning);
}
.header .upper-line-header .left-segment ._company .text {
  color: #606b6c;
}
.header .upper-line-header .right-segment {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.header .upper-line-header .right-segment .select-value {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  height: 36px;
  padding: 0px 12px 0px 12px;
  border-radius: 8px;
  border: 1px solid #e2e6e5;
  gap: 2px;
  color: var(--text);
}
.header .upper-line-header .right-segment .select-value:hover {
  background-color: var(--hover-outline);
}
.header .upper-line-header .right-segment .select-value:focus {
  box-shadow: 0px 0px 0px 1px #00000099;
}
.header .upper-line-header .right-segment .select-value:active {
  background-color: var(--pressed-outline);
}
.header .upper-line-header .right-segment .select-value .value {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 2px 2px 2px;
}
.header .upper-line-header .right-segment .select-value .overlay {
  cursor: default;
  display: none;
  z-index: 999999;
  position: absolute;
  top: 52px;
  right: 112px;
  box-shadow: 0px 3px 6px -1px #0000000f, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001a;
  background-color: #ffffff;
  max-width: 220px;
  width: 220px;
  height: auto;
  border-radius: 10px;
  padding: 12px;
}
.header .upper-line-header .right-segment .select-value .overlay .avans {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: stretch;
}
.header .upper-line-header .right-segment .select-value .overlay .avans .avans-info {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.header .upper-line-header .right-segment .select-value .overlay .avans .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.header .upper-line-header .right-segment .select-value .overlay .l {
  margin-bottom: 12px;
  border-bottom: 1px solid var(--divider);
}
.header .upper-line-header .right-segment .select-value .overlay .cont-val {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}
.header .upper-line-header .right-segment .select-value .overlay .cont-val .value {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  padding: 0px;
}
.header .upper-line-header .right-segment .select-value .overlay .cont-val .subtext {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.header .upper-line-header .right-segment .select-value .overlay .alert-req {
  border-radius: 10px;
  padding: 12px;
  background-color: var(--alert-warning);
  color: var(--alert-warning-text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.header .upper-line-header .right-segment .select-value .overlay.focus {
  display: block !important;
}
.header .upper-line-header .right-segment .cont-icons {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  user-select: none;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .action-alert {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 800px;
  background: #d43a0b;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay {
  cursor: default;
  display: none;
  z-index: 999999;
  position: absolute;
  top: 37px;
  right: 0px;
  box-shadow: 0px 3px 6px -1px #0000000f, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001a;
  background-color: #ffffff;
  max-width: 280px;
  width: 280px;
  height: auto;
  min-height: 340px;
  border-radius: 10px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .avans {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: stretch;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .avans .avans-info {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .avans .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .l {
  margin-bottom: 12px;
  border-bottom: 1px solid var(--divider);
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .cont-val {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .cont-val .value {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  padding: 0px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .cont-val .subtext {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .alert-req {
  border-radius: 10px;
  padding: 12px;
  background-color: var(--alert-warning);
  color: var(--alert-warning-text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay.focus {
  display: block !important;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 340px;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar-track-piece:end {
  margin-bottom: 8px;
  border-radius: 8px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar-track-piece:start {
  border-radius: 8px;
  margin-top: 8px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar {
  width: 14px;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #22222266;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll ::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
.header .upper-line-header .right-segment .cont-icons .cont-icon .overlay .scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.header .down-line-header {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.header .down-line-header .el-menu {
  max-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px 12px 8px;
  gap: 6px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--tabs);
  cursor: pointer;
}
.header .down-line-header .el-menu:hover {
  color: var(--tabs-hover);
}
.header .down-line-header .select-el {
  color: var(--tabs-current) !important;
  border-bottom: 2px solid var(--border-tabs-current);
  padding: 12px 8px 12px 8px;
}
@media screen and (max-width: 720px) {
  ion-content.page-guest {
    --offset-top: -56px !important;
  }
  .header {
    height: 52px;
    padding: 8px 0px;
  }
  .header .upper-line-header {
    padding: 0px 8px 0px 16px;
  }
  .header .upper-line-header .left-segment {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
  }
  .header .upper-line-header .left-segment ._company {
    display: none;
  }
  .header .upper-line-header .right-segment {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .header .upper-line-header .right-segment .select-value {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    height: 36px;
    padding: 0px 12px 0px 12px;
    border-radius: 8px;
    border: 1px solid #e2e6e5;
    gap: 2px;
    color: var(--text);
  }
  .header .upper-line-header .right-segment .select-value:hover {
    background-color: var(--hover-outline);
  }
  .header .upper-line-header .right-segment .select-value:focus {
    box-shadow: 0px 0px 0px 1px #00000099;
  }
  .header .upper-line-header .right-segment .select-value:active {
    background-color: var(--pressed-outline);
  }
  .header .upper-line-header .right-segment .select-value .value {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 2px 2px 2px;
  }
  .header .upper-line-header .right-segment .select-value .overlay {
    cursor: default;
    display: none;
    z-index: 999999;
    position: absolute;
    top: 52px;
    right: 112px;
    box-shadow: 0px 3px 6px -1px #0000000f, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001a;
    background-color: #ffffff;
    max-width: 220px;
    width: 220px;
    height: auto;
    border-radius: 10px;
    padding: 12px;
  }
  .header .upper-line-header .right-segment .select-value .overlay .avans {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: stretch;
  }
  .header .upper-line-header .right-segment .select-value .overlay .avans .avans-info {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .header .upper-line-header .right-segment .select-value .overlay .avans .text {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .header .upper-line-header .right-segment .select-value .overlay .l {
    margin-bottom: 12px;
    border-bottom: 1px solid var(--divider);
  }
  .header .upper-line-header .right-segment .select-value .overlay .cont-val {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 12px;
  }
  .header .upper-line-header .right-segment .select-value .overlay .cont-val .value {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    padding: 0px;
  }
  .header .upper-line-header .right-segment .select-value .overlay .cont-val .subtext {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .header .upper-line-header .right-segment .select-value .overlay .alert-req {
    border-radius: 10px;
    padding: 12px;
    background-color: var(--alert-warning);
    color: var(--alert-warning-text);
    font-family: var(--font-nunito);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .header .upper-line-header .right-segment .select-value .overlay.focus {
    display: block !important;
  }
  .header .upper-line-header .right-segment .cont-icons {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  .header .upper-line-header .right-segment .cont-icons #bottom-start-user {
    display: none;
  }
  .header .upper-line-header .right-segment .cont-icons #btn-menu {
    display: flex;
  }
  .header .upper-line-header .right-segment .cont-icons .cont-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    user-select: none;
  }
  .header .upper-line-header .right-segment .cont-icons .cont-icon .action-alert {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 800px;
    background: #d43a0b;
  }
  .header .upper-line-header .right-segment .cont-icons .cont-icon .overlay {
    top: 37px;
    right: -36px;
    max-width: calc(720px - 32px);
    width: calc(100vw - 32px);
  }
  .header .down-line-header {
    display: none;
  }
}
