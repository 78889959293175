.cont-slide {
  position: relative;
}
.cont-slide .shadow1 {
  position: absolute;
  right: -130px;
  z-index: 999;
  top: 120px;
  height: 60px;
  width: 304px;
  transform: rotate(-90deg);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #ffffff 100%);
}
.cont-slide .shadow2 {
  position: absolute;
  z-index: 999;
  left: -130px;
  top: 120px;
  height: 60px;
  width: 304px;
  transform: rotate(90deg);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #ffffff 100%);
}
.cont-slide .shadow1.fq {
  top: 94px;
  width: 250px;
  right: -95px;
}
.cont-slide .shadow2.fq {
  top: 94px;
  width: 250px;
  left: -95px;
}
.cont-slide .swiper-slide {
  width: 240px !important;
}
.cont-slide .slider {
  width: 250px;
}
.cont-slide .slider .images-tour {
  width: 240px;
}
.cont-slide .slider .images-tour img {
  width: 240px;
}
.tour-el {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px 5px 16px 5px;
  border-radius: 8px;
}
.tour-el:hover {
  background-color: #f4f3f6;
}
.tour-el .images-tour {
  overflow: hidden;
  height: 160px;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-el .images-tour .tag {
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  height: 20px;
  width: 72px;
  color: var(--neutral);
  position: absolute;
  padding: 0px 6px;
  border-radius: 800px;
  background-color: var(--contrast-high);
  font-family: var(--font-nunito);
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.tour-el .images-tour img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour-el .images-tour .value-images {
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  font-family: var(--font-nunito);
  color: var(--neutral);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tour-el .short-inf {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 12px 0px;
}
.tour-el .short-inf .rating {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.tour-el .short-inf .rating .value-rat {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: var(--text);
}
.tour-el .short-inf .rating .value-review {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-el .short-inf .name-tour {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tour-el .short-inf .price {
  display: flex;
  flex-direction: column;
}
.tour-el .short-inf .price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.tour-el .short-inf .price .cont-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.tour-el .short-inf .price .cont-price .price-value {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: var(--text);
}
.tour-el .short-inf .price .cont-price .promotion {
  color: var(--success) !important;
}
.tour-el .short-inf .price .cont-price .duration {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text);
}
.tour-el .short-inf .price .cont-price .width {
  width: 50%;
}
.tour-el .short-inf .btn-fav .text-btn svg path {
  stroke: transparent;
}
.tour-el .short-inf .favourite {
  right: 0px;
  bottom: 12px;
  position: absolute;
}
.tour-el .short-inf .favourite .svg {
  width: 20px;
  height: 20px;
}
.prev-feedback-cont {
  width: 280px;
  height: 248px;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--divider);
}
.prev-feedback-cont .cont-up {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.prev-feedback-cont .cont-up .cont-avatar {
  overflow: hidden;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev-feedback-cont .cont-up .cont-avatar .avatar-info {
  width: 44px;
  height: 44px;
}
.prev-feedback-cont .cont-up .cont-info-user {
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
}
.prev-feedback-cont .cont-up .cont-info-user .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.prev-feedback-cont .cont-up .cont-info-user .text-muted {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.prev-feedback-cont .rate {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.prev-feedback-cont .rate .text-muted {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.prev-feedback-cont .name-tours {
  font-family: var(--font-nunito);
  color: var(--link);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.prev-feedback-cont .textarea {
  overflow: hidden;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
