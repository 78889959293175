.employees-settings-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.employees-settings-page .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.employees-settings-page .upper-line .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.employees-create-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.employees-create-page .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.employees-create-page .upper-line .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.employees-create-page .upper-line .cont-l .btn-back {
  width: 36px;
}
.employees-create-page .upper-line .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.employees-create-page .body-create {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.employees-create-page .body-create .first-line {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.employees-create-page .body-create .first-line .left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 600px;
}
.employees-create-page .body-create .first-line .left .line-input {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}
.employees-create-page .body-create .first-line .left .line-input .input-create-oper-user {
  width: 404px;
}
.employees-create-page .body-create .first-line .left .line-input .text {
  padding: 4px 6px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-create-page .body-create .first-line .left .line-input-textarea {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
}
.employees-create-page .body-create .first-line .left .line-input-textarea .input-create-oper-user {
  width: 404px;
}
.employees-create-page .body-create .first-line .left .line-input-textarea .text {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  height: 52px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-create-page .body-create .first-line .right {
  width: 584px;
}
.employees-create-page .body-create .cont-1 {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 1216px;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.employees-create-page .body-create .cont-1 .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0px 2px;
}
.employees-create-page .body-create .cont-1 .title .cont-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.employees-create-page .body-create .cont-1 .title .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-create-page .body-create .cont-1 .title .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.employees-create-page .body-create .cont-1 .cont-items {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.employees-create-page .body-create .cont-1 .cont-items .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 280px;
}
.employees-create-page .body-create .cont-1 .cont-items .item .title-item {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}
.employees-create-page .body-create .cont-1 .cont-items .item .line-item {
  padding: 2px;
}
.company-settings-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.company-settings-page .upper-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.company-settings-page .upper-cont .tbr {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.company-settings-page .upper-cont .tbr .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.company-settings-page .upper-cont .tbr .btn-save {
  width: 107px;
}
.company-settings-page .upper-cont .status-cont {
  padding: 4px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}
.company-settings-page .upper-cont .last-change {
  width: 100%;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.company-settings-page .inf-cont {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.company-settings-page .inf-cont .contl {
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.company-settings-page .inf-cont .contl .line-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.company-settings-page .inf-cont .contl .line-input .text {
  width: 184px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  height: 52px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.company-settings-page .inf-cont .contl .line-input .input-company {
  width: 484px;
}
.company-settings-page .inf-cont .contl .line-input .input-descr-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.company-settings-page .inf-cont .contl .line-input .input-descr-cont .descr {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 0px 6px;
}
.company-settings-page .inf-cont .contl .line-input.line-input-with-descr {
  align-items: flex-start;
}
.company-settings-page .inf-cont .conteiner {
  display: flex;
  flex-direction: column;
  width: 504px;
  border-radius: 10px;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.company-settings-page .inf-cont .conteiner .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.company-settings-page .inf-cont .conteiner .line-r {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.company-settings-page .inf-cont .conteiner .line-r .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 6px;
}
.company-settings-page .inf-cont .conteiner .line-r .helper-area {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 0px;
}
.employees-info-oper-user-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.employees-info-oper-user-page .upper-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.employees-info-oper-user-page .upper-line .tbr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.employees-info-oper-user-page .upper-line .tbr .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.employees-info-oper-user-page .upper-line .tbr .cont-l .btn-back {
  width: 36px;
}
.employees-info-oper-user-page .upper-line .tbr .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.employees-info-oper-user-page .upper-line .last-change {
  width: 100%;
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-left: 48px;
}
.employees-info-oper-user-page .upper-line .status-cont {
  padding: 4px 48px;
}
.employees-info-oper-user-page .upper-line-n {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.employees-info-oper-user-page .upper-line-n .cont-l {
  gap: 12px;
  display: flex;
  flex-direction: row;
}
.employees-info-oper-user-page .upper-line-n .cont-l .btn-back {
  width: 36px;
}
.employees-info-oper-user-page .upper-line-n .cont-l .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 28px;
  font-weight: 640;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.employees-info-oper-user-page .body-create {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.employees-info-oper-user-page .body-create .first-line {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.employees-info-oper-user-page .body-create .first-line .left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 600px;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input .input-create-oper-user {
  width: 404px;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input .text {
  padding: 4px 6px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input-textarea {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input-textarea .input-create-oper-user {
  width: 404px;
}
.employees-info-oper-user-page .body-create .first-line .left .line-input-textarea .text {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  height: 52px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-info-oper-user-page .body-create .first-line .right {
  width: 584px;
}
.employees-info-oper-user-page .body-create .cont-1 {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 1216px;
  padding: 20px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.employees-info-oper-user-page .body-create .cont-1 .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0px 2px;
}
.employees-info-oper-user-page .body-create .cont-1 .title .cont-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.employees-info-oper-user-page .body-create .cont-1 .title .text {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.employees-info-oper-user-page .body-create .cont-1 .title .subtext {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.employees-info-oper-user-page .body-create .cont-1 .cont-items {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.employees-info-oper-user-page .body-create .cont-1 .cont-items .item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 280px;
}
.employees-info-oper-user-page .body-create .cont-1 .cont-items .item .title-item {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}
.employees-info-oper-user-page .body-create .cont-1 .cont-items .item .line-item {
  padding: 2px;
}
@media screen and (max-width: 720px) {
  .employees-settings-page {
    width: 100vw;
    padding: 20px 16px;
    /* Скрываем scrollbar для Chrome, Safari и Opera */
    /* Скрываем scrollbar для IE, Edge и Firefox */
  }
  .employees-settings-page .upper-line {
    flex-direction: column;
    gap: 12px;
  }
  .employees-settings-page .upper-line .title {
    font-size: 20px;
    line-height: 28px;
  }
  .employees-settings-page .upper-line .btn-create {
    width: 110px;
  }
  .employees-settings-page .tabs {
    white-space: nowrap;
    overflow: scroll;
  }
  .employees-settings-page .tabs::-webkit-scrollbar {
    display: none;
  }
  .employees-settings-page .tabs {
    -ms-overflow-style: none;
    /* IE и Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .employees-create-page {
    width: 100%;
    height: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .employees-create-page .upper-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .employees-create-page .upper-line .cont-l {
    gap: 12px;
    display: flex;
    flex-direction: row;
  }
  .employees-create-page .upper-line .cont-l .btn-back {
    width: 36px;
  }
  .employees-create-page .upper-line .cont-l .title {
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 28px;
    font-weight: 640;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  }
  .employees-create-page .body-create {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .employees-create-page .body-create .first-line {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .employees-create-page .body-create .first-line .left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 600px;
  }
  .employees-create-page .body-create .first-line .left .line-input {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .employees-create-page .body-create .first-line .left .line-input .input-create-oper-user {
    width: 404px;
  }
  .employees-create-page .body-create .first-line .left .line-input .text {
    padding: 4px 6px;
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-create-page .body-create .first-line .left .line-input-textarea {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }
  .employees-create-page .body-create .first-line .left .line-input-textarea .input-create-oper-user {
    width: 404px;
  }
  .employees-create-page .body-create .first-line .left .line-input-textarea .text {
    padding: 4px 6px;
    display: flex;
    align-items: center;
    height: 52px;
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-create-page .body-create .first-line .right {
    width: 584px;
  }
  .employees-create-page .body-create .cont-1 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 1216px;
    padding: 20px;
    gap: 16px;
    border: 1px solid var(--divider);
  }
  .employees-create-page .body-create .cont-1 .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0px 2px;
  }
  .employees-create-page .body-create .cont-1 .title .cont-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .employees-create-page .body-create .cont-1 .title .text {
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-create-page .body-create .cont-1 .title .subtext {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .employees-create-page .body-create .cont-1 .cont-items {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .employees-create-page .body-create .cont-1 .cont-items .item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 280px;
  }
  .employees-create-page .body-create .cont-1 .cont-items .item .title-item {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
  }
  .employees-create-page .body-create .cont-1 .cont-items .item .line-item {
    padding: 2px;
  }
  .company-settings-page {
    width: 100%;
    height: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .company-settings-page .upper-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .company-settings-page .upper-cont .tbr {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .company-settings-page .upper-cont .tbr .title {
    font-family: var(--font-nunito-sans);
    color: var(--text);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  }
  .company-settings-page .upper-cont .tbr .btn-save {
    width: 107px;
  }
  .company-settings-page .upper-cont .status-cont {
    padding: 4px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  .company-settings-page .upper-cont .last-change {
    width: 100%;
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .company-settings-page .inf-cont {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .company-settings-page .inf-cont .contl {
    width: 680px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .company-settings-page .inf-cont .contl .line-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .company-settings-page .inf-cont .contl .line-input .text {
    width: 184px;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    height: 52px;
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .company-settings-page .inf-cont .contl .line-input .input-company {
    width: 484px;
  }
  .company-settings-page .inf-cont .contl .line-input .input-descr-cont {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .company-settings-page .inf-cont .contl .line-input .input-descr-cont .descr {
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    padding: 0px 6px;
  }
  .company-settings-page .inf-cont .contl .line-input.line-input-with-descr {
    align-items: flex-start;
  }
  .company-settings-page .inf-cont .conteiner {
    display: flex;
    flex-direction: column;
    width: 504px;
    border-radius: 10px;
    padding: 20px;
    gap: 16px;
    border: 1px solid var(--divider);
  }
  .company-settings-page .inf-cont .conteiner .title {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .company-settings-page .inf-cont .conteiner .line-r {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .company-settings-page .inf-cont .conteiner .line-r .text {
    color: var(--text);
    font-family: var(--font-nunito);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 6px;
  }
  .company-settings-page .inf-cont .conteiner .line-r .helper-area {
    color: var(--link);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    padding: 0px;
  }
  .employees-info-oper-user-page {
    width: 100%;
    height: auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .employees-info-oper-user-page .upper-line {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .employees-info-oper-user-page .upper-line .tbr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .employees-info-oper-user-page .upper-line .tbr .cont-l {
    gap: 12px;
    display: flex;
    flex-direction: row;
  }
  .employees-info-oper-user-page .upper-line .tbr .cont-l .btn-back {
    width: 36px;
  }
  .employees-info-oper-user-page .upper-line .tbr .cont-l .title {
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 28px;
    font-weight: 640;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  }
  .employees-info-oper-user-page .upper-line .last-change {
    width: 100%;
    color: var(--text-muted);
    font-family: var(--font-nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-left: 48px;
  }
  .employees-info-oper-user-page .upper-line .status-cont {
    padding: 4px 48px;
  }
  .employees-info-oper-user-page .upper-line-n {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .employees-info-oper-user-page .upper-line-n .cont-l {
    gap: 12px;
    display: flex;
    flex-direction: row;
  }
  .employees-info-oper-user-page .upper-line-n .cont-l .btn-back {
    width: 36px;
  }
  .employees-info-oper-user-page .upper-line-n .cont-l .title {
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 28px;
    font-weight: 640;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  }
  .employees-info-oper-user-page .body-create {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .employees-info-oper-user-page .body-create .first-line {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .employees-info-oper-user-page .body-create .first-line .left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 600px;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input .input-create-oper-user {
    width: 404px;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input .text {
    padding: 4px 6px;
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input-textarea {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input-textarea .input-create-oper-user {
    width: 404px;
  }
  .employees-info-oper-user-page .body-create .first-line .left .line-input-textarea .text {
    padding: 4px 6px;
    display: flex;
    align-items: center;
    height: 52px;
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-info-oper-user-page .body-create .first-line .right {
    width: 584px;
  }
  .employees-info-oper-user-page .body-create .cont-1 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 1216px;
    padding: 20px;
    gap: 16px;
    border: 1px solid var(--divider);
  }
  .employees-info-oper-user-page .body-create .cont-1 .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0px 2px;
  }
  .employees-info-oper-user-page .body-create .cont-1 .title .cont-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .employees-info-oper-user-page .body-create .cont-1 .title .text {
    font-family: var(--font-nunito);
    color: var(--text);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .employees-info-oper-user-page .body-create .cont-1 .title .subtext {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .employees-info-oper-user-page .body-create .cont-1 .cont-items {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .employees-info-oper-user-page .body-create .cont-1 .cont-items .item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 280px;
  }
  .employees-info-oper-user-page .body-create .cont-1 .cont-items .item .title-item {
    font-family: var(--font-nunito);
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
  }
  .employees-info-oper-user-page .body-create .cont-1 .cont-items .item .line-item {
    padding: 2px;
  }
}
