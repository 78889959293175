.home {
  height: auto;
  width: 100vw;
  padding: 32px;
  gap: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home .offer {
  width: 1120px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.home .showcase {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .showcase .title {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text-muted);
  text-transform: uppercase;
}
.home .showcase .elements {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.home .showcase .elements .places-purchased {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 424px;
  height: 200px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e2e6e5;
  gap: 16px;
}
.home .showcase .elements .places-purchased .places-purchased-inf {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .showcase .elements .places-purchased .places-purchased-inf .title-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.home .showcase .elements .places-purchased .places-purchased-inf .title-cont .title-places-purch {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .inf-mouth {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .inf-mouth .mouth {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .inf-mouth .value-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .inf-mouth .value-cont .value {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .inf-mouth .value-cont .max {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .cont-circle .circle {
  width: 64px;
  height: 64px;
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .cont-circle .circle .CircularProgressbar-path {
  stroke: var(--progress-active);
}
.home .showcase .elements .places-purchased .places-purchased-inf .stat .cont-circle .circle .CircularProgressbar-trail {
  stroke: var(--progress-inactive);
}
.home .showcase .elements .places-purchased .cont-look {
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 0px 4px 0px 4px;
  user-select: none;
  cursor: pointer;
}
.home .showcase .elements .places-purchased .cont-look .look {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #606b6c;
  padding: 0px 2px 2px 2px;
}
.home .showcase .elements .other-inf {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .showcase .elements .other-inf .other-el {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 424px;
  height: 56px;
  padding: 16px 20px 16px 20px;
  border-radius: 12px;
  gap: 8px;
  border: 1px solid #e2e6e5;
  cursor: pointer;
}
.home .showcase .elements .other-inf .other-el .right-other-el {
  display: flex;
  flex-direction: row;
}
.home .showcase .elements .other-inf .other-el .right-other-el .value-num {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  padding: 0px 6px 0px 6px;
  border-radius: 800px;
  background: #444c4e;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
  color: #ffffff;
  margin-right: 8px;
}
.home .showcase .elements .other-inf .other-el span {
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}
.home .rating {
  width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.home .rating .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.home .rating .top-tablet {
  position: relative;
}
.home .rating .top-tablet.hide {
  overflow: hidden;
  height: 260px;
}
.home .rating .top-tablet .row-tablet {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e2e6e5;
  justify-content: space-between;
}
.home .rating .top-tablet .row-tablet .cont {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.home .rating .top-tablet .row-tablet .el-photo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  gap: 2px;
}
.home .rating .top-tablet .row-tablet .el-photo-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.home .rating .top-tablet .row-tablet .el-name-text {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 52px;
  padding: 4px 6px 4px 6px;
  gap: 2px;
  font-family: var(--font-nunito);
  color: var(--link);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.home .rating .top-tablet .row-tablet .el-order-text {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 52px;
  padding: 4px 6px 4px 6px;
  gap: 2px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text-muted);
}
.home .rating .top-tablet .shadow {
  top: 212px;
  width: 100%;
  height: 52px;
  position: absolute;
  z-index: 5;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #ffffff 100%);
}
.home .rating .top-tablet .row-tablet:last-child {
  border-bottom: none;
}
.home .rating .expand {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.home .rating .expand .btn-expand {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
  gap: 2px;
}
.home .rating .expand .btn-expand .text {
  font-family: var(--font-nunito);
  color: var(--ghost);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.home .rating .expand .btn-expand .reverse {
  transform: rotate(180deg);
}
.home .rating .btn-expand:hover .text {
  color: var(--text-muted-hover);
}
.home .rating .btn-expand:hover .btn-expand-icon path {
  stroke: var(--icon-transparent-bckg-hover);
}
.home .finance {
  display: flex;
  width: 1120px;
  flex-direction: column;
  gap: 16px;
}
.home .finance .cont-frames {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .finance .cont-frames .upper-frames {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.home .finance .cont-frames .upper-frames .frame-fin {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 362.67px;
  height: 200px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e2e6e5;
  gap: 8px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .info-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  user-select: none;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .title-frame-fin {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance {
  height: 20px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance .p-inputtext {
  color: var(--text);
  padding: 0px 4px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance .p-dropdown-trigger {
  width: 16px;
  height: 16px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance .p-dropdown-trigger svg path {
  stroke: var(--text);
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance-year {
  height: 20px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance-year .p-inputtext {
  color: var(--text);
  padding: 0px 4px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance-year .p-dropdown-trigger {
  width: 16px;
  height: 16px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .title-cont .select-finance-year .p-dropdown-trigger svg path {
  stroke: var(--text);
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .inf-mouth {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .inf-mouth .mouth {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .inf-mouth .value-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .inf-mouth .value-cont .value {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .inf-mouth .value-cont .max {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .cont-circle .circle {
  width: 64px;
  height: 64px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .cont-circle .circle .CircularProgressbar-path {
  stroke: #de1f4c;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .stat .cont-circle .circle .CircularProgressbar-trail {
  stroke: #e2e6e5;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .cont-balance {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .cont-balance .balance {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .cont-balance .r {
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}
.home .finance .cont-frames .upper-frames .frame-fin .frame-fin-inf .dep {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.home .finance .cont-frames .upper-frames .frame-fin .char {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.home .finance .cont-frames .upper-frames .frame-fin .char .char-text .text {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.home .finance .cont-frames .upper-frames .frame-fin .char .char-text .prc {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.home .finance .cont-frames .upper-frames .frame-fin .char .char-text .prc.green {
  color: #029c3f;
}
.home .finance .cont-frames .upper-frames .frame-fin .char .char-text .prc.orange {
  color: #fe8034;
}
.home .finance .cont-frames .upper-frames .frame-fin .cont-look {
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 0px 4px 0px 4px;
  user-select: none;
  cursor: pointer;
}
.home .finance .cont-frames .upper-frames .frame-fin .cont-look .look {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-ghost-btn);
  padding: 0px 2px 2px 2px;
}
.home .finance .cont-frames .upper-frames .frame-fin .cont-look:hover .look {
  color: var(--text-muted-hover);
}
.home .finance .cont-frames .upper-frames .frame-fin .cont-look:hover .icon-outline path {
  stroke: var(--icon-transparent-bckg-hover);
}
.home .finance .cont-frames .lower-frames {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.home .finance .cont-frames .lower-frames .frame-lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 211.2px;
  height: 88px;
  padding: 16px;
  border-radius: 12px;
  gap: 8px;
  border: 1px solid var(--divider);
  position: relative;
}
.home .finance .cont-frames .lower-frames .frame-lower .title {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: none;
}
.home .finance .cont-frames .lower-frames .frame-lower .info-icon {
  position: absolute;
  cursor: pointer;
  user-select: none;
  right: 8px;
  top: 8px;
}
.home .finance .cont-frames .lower-frames .frame-lower .value {
  display: flex;
  gap: 4px;
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.home .finance .cont-frames .lower-frames .frame-lower .value .new-val {
  position: relative;
  color: var(--success);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.home .finance .cont-frames .lower-frames .frame-lower .penalty {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}
.home .feedback {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .feedback .title {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text-muted);
  text-transform: uppercase;
}
.home .feedback .cont-frames-feed {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.home .feedback .cont-frames-feed .frame-feed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 277.33px;
  height: 200px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e2e6e5;
  gap: 16px;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .title-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .title-cont .title-frame-feed {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .inf-mouth {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .inf-mouth .mouth {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .inf-mouth .value-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .inf-mouth .value-cont .value {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .inf-mouth .value-cont .max {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .cont-circle .circle {
  width: 64px;
  height: 64px;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .cont-circle .circle .CircularProgressbar-path {
  stroke: #de1f4c;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .stat .cont-circle .circle .CircularProgressbar-trail {
  stroke: #e2e6e5;
}
.home .feedback .cont-frames-feed .frame-feed .frame-fin-feed .balance {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 36px;
  font-weight: 640;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.home .feedback .cont-frames-feed .frame-feed .cont-look {
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 0px 4px 0px 4px;
  user-select: none;
  cursor: pointer;
}
.home .feedback .cont-frames-feed .frame-feed .cont-look .look {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #606b6c;
  padding: 0px 2px 2px 2px;
}
.cont-chart {
  width: 330px;
  height: 44px;
}
.cont-chart canvas {
  width: 330px !important;
}
.get-advance-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1120px;
  border-radius: 12px;
  padding: 16px;
  gap: 16px;
  border: 1px solid var(--divider);
}
.get-advance-cont .cont-input-get-advance {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.get-advance-cont .cont-input-get-advance .input-get-advance-number {
  width: 200px;
}
.get-advance-cont .cont-input-get-advance .input-get-advance-code {
  width: 96px;
  height: 44px;
  border-radius: 10px;
  padding: 6px;
}
.get-advance-cont .cont-input-get-advance .input-get-advance-code .input {
  width: 84px;
  height: 32px;
}
.get-advance-cont .cont-input-get-advance .btn-get-advance {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  padding: 0px 16px 0px 16px;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.get-advance-cont .alert {
  color: var(--warning);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.alert-summary {
  width: 1120px;
}
.draft-tours-cont,
.question-cont,
.feedback-tours-cont {
  width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.draft-tours-cont .title-cont,
.question-cont .title-cont,
.feedback-tours-cont .title-cont {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.draft-tours-cont .no-array,
.question-cont .no-array,
.feedback-tours-cont .no-array {
  height: 240px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.draft-tours-cont .no-array img,
.question-cont .no-array img,
.feedback-tours-cont .no-array img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.draft-tours-cont .no-array .content,
.question-cont .no-array .content,
.feedback-tours-cont .no-array .content {
  position: absolute;
  top: 108px;
}
.draft-tours-cont .no-array .content .text,
.question-cont .no-array .content .text,
.feedback-tours-cont .no-array .content .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.draft-tours-cont .title,
.question-cont .title,
.feedback-tours-cont .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}
.draft-tours-cont .btn,
.question-cont .btn,
.feedback-tours-cont .btn {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
ion-modal.modal-tour-confirmed {
  --height: auto;
  --width: 480px;
  --border-radius: 12px;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .cont-info {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .cont-info .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .cont-info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .cont-info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-tour-confirmed .body-modal-tour-confrmed .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
@media screen and (max-width: 720px) {
  ion-modal.modal-tour-confirmed {
    --width: calc(100vw - 32px);
  }
}
