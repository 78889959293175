/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** custom **/

  /** font **/
  --font-robotoflex: "Roboto Flex", sans-serif;
  --font-ruda: 'Ruda', sans-serif;
  --font-ubuntu: 'Ubuntu', sans-serif;
  --font-nunito: 'Nunito', sans-serif;
  --font-source: 'Source Sans 3', sans-serif;
  --font-pt: 'PT Serif', serif;
  --font-nunito-sans: 'Nunito Sans', sans-serif;
  /** alert **/
  --alert-neutral: #F4F3F6;
  --alert-primary: #FEF0EF;
  --alert-secondary: #E6F7E2;
  --alert-info: #EEF4FF;
  --alert-success: #DEFAE9;
  --alert-warning: #FEF2E4;
  --alert-danger: #FFF0F2;

  --alert-neutral-text: #353343;
  --alert-primary-text: #C31C12;
  --alert-secondary-text: #2F710D;
  --alert-info-text: #4749F0;
  --alert-success-text: #15723A;
  --alert-warning-text: #BD2A11;
  --alert-danger-text: #C51228;
  /** other **/
  --warning: #EE510A;
  --success: #00860F;
  --primary-default: #F74439;
  --selected: rgba(222, 31, 76, 1);
  --progress-active: #DE1F4C;
  --progress-inactive: #E2E6E5;
  --divider: #E2E6E5;
  --surface: #FFFFFF;
  --substrate: #F2F3F3;
  --contrast-high: #4B4957;
  --unfilled: #F4F3F6;
  --neutral-25: #F9F9FB;

  /** toggle **/
  --toggle-handler: #FFFFFF;
  --toggle-off-back: #E6E5EA;
  --toggle-off-back-hover: #D0CED6;
  --toggle-on-back: #F74439;
  --toggle-on-back-hover: #E12014;
  --toggle-back-boxshadow: 0px 0px 0px 1px #00000099;
  --toggle-invalid-back: #FFFFFF;
  --toggle-handler-invalid-off: #A8B2B1;
  --toggle-handler-invalid-on: #DE233A;

  /** checkbox **/
  --checkbox-off: #E2E6E5;
  --checkbox-off-hover: #C9D0CE;
  --checkbox-invalid: #FFFFFF;
  --checkbox-invalid-border: #DD291D;
  --checkbox-on: #F74439;
  --checkbox-on-hover: #E12014;

  /** tabs **/
  --tabs: #606B6C;
  --tabs-hover: #202226;
  --circle-tabs-current-back: #444C4E;
  --circle-tabs-current-val: #FFFFFF;
  --circle-tabs-val: #202226;
  --circle-tabs-back: #E2E6E5;
  --tabs-current: #F74439;
  --border-tabs-current: #F74439;

  /** btn **/
  --ghost: #606B6C;
  --bright: #F74439;
  --muted: #FEE4E2;
  --elevated: #FFFFFF;
  --pressed-bright: #C31C12;
  --pressed-muted: #FFA7A1;
  --pressed-elevated: #F2F3F3;
  --pressed-outline: #E6E5EA;
  --pressed-ghost: #E6E5EA;
  --hover-bright: #E12014;
  --hover-muted: #FFCECB;
  --hover-elevated: #F9F9FB;
  --hover-outline: #F9F9FB;

  /** progress **/
  --inactive-progress: #E6E5EA;

  /** shadow **/
  --box-shadow-btn: 0px 0px 0px 1px rgba(0, 0, 0, 0.60);
  --box-shadow-btn-elevated: 0px 1.75px 3.5px -0.5px rgba(0, 0, 0, 0.04), 0px 3.5px 7px -1px rgba(0, 0, 0, 0.06), 0px 7px 14px -2px rgba(0, 0, 0, 0.08);

  /** border **/
  --border-outline-btn: #E6E5EA;
  /** input/select **/
  --input-background: #F4F3F6;
  --input-background-hover: #E6E5EA;
  --input-border-focus: 0px 0px 0px 1px #00000099;
  --input-background-error: #FFF7F7;
  --input-border-error: #DE233A;

  /** text **/
  --neutral: #FFFFFF;
  --link: rgba(71, 73, 240, 1);
  --text: #222030;
  --text-muted: #696775;
  --text-muted-hover: #202226;
  --text-bright-btn: #FFFFFF;
  --text-muted-btn: #C31149;
  --text-outline-btn: #696775;
  --text-elevated-btn: #202226;
  --text-ghost-btn: #606B6C;
  --text-ghost-btn-click: #202226;
  --text-input-placeholder: #696775;
  --text-unfilled: #696775;
  --text-unfilled-current: #222030;
  --text-filled-success: #058539;
  --text-filled-error: #DE233A;

  --green: #004A18;
  --text-secondary: #539730;

  /** background **/
  --green-back: #BBE4B5;

  /** badge **/
  --badge-red: #FFCECE;
  --badge-orange: #FFD390;
  --badge-sulu: #CFE37D;
  --badge-lgreen: #9BEE87;
  --badge-mint: #91EDB6;
  --badge-blizzard: #98E7E7;
  --badge-blue: #C3DBFF;
  --badge-purple: #DDD4FF;
  --badge-lavender: #EED0F5;
  --badge-pink: #F8CEE7;

  /** status **/
  --status-gray: #E6E5EA;
  --status-gray-text: #222030;
  --status-orange: #FFD1A7;
  --status-orange-text: #850000;
  --status-red: #FECECB;
  --status-red-text: #7D1707;
  --status-green: #A8E8C1;
  --status-green-text: #004A18;



  /** icon **/
  --icon-bright: #FFFFFF;
  --icon-muted: #F74439;
  --icon-transparent-bckg: #696775;
  --icon-transparent-bckg-hover: #696775;
  --icon-elevated: #303639;
  --icon-warning: #EE510A;
  --icon-muted-info: #A8B2B1;
  --icon-unfilled: #696775;
  --icon-password: #696775;
}



/* @media (prefers-color-scheme: dark) { */
/*
   * Dark Colors
   * -------------------------------------------
   */

/* body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  } */

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

/* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
 */

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

/* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
/* } */