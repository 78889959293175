.body-calendar {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 280px;
}
.header-calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 2px;
  user-select: none;
}
.header-calendar .current {
  cursor: pointer;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.dates-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 280px;
  gap: 4px;
}
.dates-wrapper .date {
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--divider);
  border-radius: 8px;
  padding: 6px 2px;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  animation: fadein 1s both;
}
.dates-wrapper .date .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.dates-wrapper .date .dotter {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.dates-wrapper .date .dotter .dot {
  width: 6px;
  height: 6px;
  border-radius: 16px;
}
.dates-wrapper .date .dotter .dot.green {
  background-color: #71b54e;
}
.dates-wrapper .date .dotter .dot.orange {
  background-color: #fe8034;
}
.dates-wrapper .date .dotter .dot.red {
  background-color: #ff7a8a;
}
.dates-wrapper .date.disabled {
  border: none !important;
  box-shadow: none;
  pointer-events: none;
}
.dates-wrapper .date.disabled .text {
  color: var(--text-muted);
}
.dates-wrapper .date.disabled .dotter {
  display: none;
}
.dates-wrapper .date:hover {
  box-shadow: none;
}
.dates-wrapper .date.selected {
  border: 1px solid var(--primary-default);
  box-shadow: none;
}
.dates-wrapper .date:focus {
  box-shadow: 0px 0px 0px 1px #00000099;
}
.dates-wrapper .date.unactive {
  pointer-events: none;
  border: none;
  box-shadow: none;
  background-color: #f4f3f6;
}
.dates-wrapper .date.unactive .text {
  color: var(--text-muted);
  text-decoration: line-through;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
}
