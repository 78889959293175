.tour-list-races {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tour-list-races .cont-tablet-races {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.tour-list-races .cont-tablet-races .filter-races {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tour-list-races .cont-tablet-races .filter-races .filter-search-cont-races {
  gap: 32px;
  display: flex;
  flex-direction: row;
}
.tour-list-races .cont-tablet-races .filter-races .filter-search-cont-races .input-search {
  width: 320px;
}
.tour-list-races .cont-tablet-races .filter-races .filter-search-cont-races .search-date {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tour-list-races .cont-tablet-races .filter-races .filter-search-cont-races .search-date .input-date {
  width: 156px;
}
.tour-list-races .cont-tablet-races .filter-races .btn-elem-filter-races {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.tour-list-races .cont-tablet-races .filter-races .btn-elem-filter-races .btn-elem-f {
  width: 102px;
  padding: 0px 4px;
}
.tour-list-races .cont-tablet-races .filter-races .btn-elem-filter-races .btn-elem-e {
  width: 95px;
  padding: 0px 4px;
}
.tour-list-races .cont-tablet-races .chip-cont-races .chip {
  margin-left: 0;
  user-select: none;
  height: 24px;
  border-radius: 8px;
  padding: 0px 2px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(0deg, #fde4e4, #fde4e4);
}
.tour-list-races .cont-tablet-races .chip-cont-races .chip .text {
  height: 16px;
  padding: 0px 6px 0px 6px;
  gap: 8px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #810038;
}
.tour-list-races .cont-tablet-races .chip-cont-races .chip .img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list-races .cont-tablet-races .tablet-races {
  overflow-y: hidden;
  overflow-x: auto;
  margin-right: 84px;
}
.tour-list-races .cont-tablet-races .tablet-races .hide {
  display: none;
}
.tour-list-races .cont-tablet-races .tablet-races .options-line {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.tour-list-races .cont-tablet-races .tablet-races table {
  font-family: var(--font-nunito);
  max-width: 100%;
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  text-align: left;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races {
  border-bottom: 1px solid #e2e6e5;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 4px 6px 4px 14px;
  height: 52px;
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races .setting {
  width: 36px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races.description {
  min-width: 565px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races .cont-text-table {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races .cont-text-table .up {
  transform: rotate(180deg);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races .cont-text-table .up g path {
  fill: #e12014;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth-races .cont-text-table .down g path {
  fill: #e12014;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tableth:first-child {
  padding: 4px 6px 4px 6px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races {
  border-bottom: 1px solid #e2e6e5;
  padding: 4px 6px 4px 14px;
  white-space: nowrap;
  word-break: break-word;
  height: 52px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.btn-add {
  border-bottom: none;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.summa {
  cursor: pointer;
  user-select: none;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.number_travelers {
  cursor: pointer;
  user-select: none;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .hide {
  display: none;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.description {
  min-width: 565px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .text-opt {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .subtext {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .cap {
  text-transform: capitalize;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-img-opt {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-img-opt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-name-opt {
  display: flex;
  flex-direction: column;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-name-opt .text-name-opt {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-name-opt .text-name-opt.disable {
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-name-opt .text-stat-option {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.range .cont-range .cont-name-opt .text-stat-option.red {
  color: #ee510a;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.left.red {
  background-color: #ffe3e6;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races.left.green {
  background-color: #effcf4;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet-races .status {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0px 6px;
  border-radius: 800px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  font-variation-settings: "wdth" 120, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.tour-list-races .cont-tablet-races .tablet-races .el-tablet:first-child {
  padding: 4px 6px;
}
.tour-list-races .cont-tablet-races .tablet-races .photo,
.tour-list-races .cont-tablet-races .tablet-races .pictures {
  width: 52px;
}
.tour-list-races .cont-tablet-races .tablet-races .photo .cont-img,
.tour-list-races .cont-tablet-races .tablet-races .pictures .cont-img {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour-list-races .cont-tablet-races .tablet-races .photo .cont-img img,
.tour-list-races .cont-tablet-races .tablet-races .pictures .cont-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.tour-list-races .cont-tablet-races .tablet-races .settings {
  position: absolute;
  z-index: 10;
  right: 32px;
  padding: 0;
  width: 84px;
  border-top: 1px solid var(--divider);
  border-bottom: none;
  height: 52px;
  padding-left: 8px;
  overflow: hidden;
  background-color: #fff;
}
.tour-list-races .cont-tablet-races .tablet-races .settings .cont-set-btn {
  padding: 4px 6px;
  width: 84px;
  height: 52px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08), 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
}
.tour-list-races .cont-tablet-races .tablet-races .settings .cont-set-btn.s {
  justify-content: flex-end;
}
.tour-list-races .cont-tablet-races .tablet-races .settings .cont-set-btn .setting-opt {
  cursor: pointer;
  user-select: none;
  display: flex;
  min-width: 36px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.tour-list-races .cont-tablet-races .tablet-races .settings .cont-set-btn .setting-opt.disabled {
  opacity: 40%;
}
.tour-list-races .cont-tablet-races .tablet-races .settings .cont-set-btn .open-opt {
  cursor: pointer;
  user-select: none;
  display: flex;
  min-width: 36px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.tour-list-races .cont-tablet-races .tablet-races .head {
  border-top: none !important;
}
.tour-list-races .cont-tablet-races .tablet-races tr {
  min-height: 52px;
}
.tour-list-races .cont-tablet-races .tablet-races::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 6px;
}
.tour-list-races .cont-tablet-races .tablet-races::-webkit-scrollbar-track {
  background: transparent;
}
.tour-list-races .cont-tablet-races .tablet-races::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #22222266;
}
.tour-list-races .cont-tablet-races .tablet-races::-webkit-scrollbar-track-piece:end {
  background: #22222214;
  margin-bottom: 8px;
  border-radius: 8px;
}
.tour-list-races .cont-tablet-races .tablet-races::-webkit-scrollbar-track-piece:start {
  background: #22222214;
  border-radius: 8px;
  margin-top: 8px;
}
.tour-list-races .cont-tablet-races .paginator-races {
  display: flex;
  font-family: var(--font-nunito);
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: end;
}
.tour-list-races .cont-tablet-races .paginator-races .cont-select-el-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tour-list-races .cont-tablet-races .paginator-races .cont-select-el-list .btn-paginator {
  padding: 0px 4px;
}
.tour-list-races .cont-tablet-races .paginator-races .cont-select-el-list .text {
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .paginator-races .cont-select-el-list .select-paginator {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60px;
  padding: 0px 4px;
  gap: 2px;
}
.tour-list-races .cont-tablet-races .paginator-races .cont-select-el-list .select-paginator .value {
  padding: 0px 2px 2px 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-unfilled);
}
.tour-list-races .cont-tablet-races .paginator-races .col {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.tour-list-races .cont-tablet-races .paginator-races .btn-next {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tour-list-races .cont-tablet-races .paginator-races .btn-next .arrow {
  cursor: pointer;
  user-select: none;
}
.tour-list-races .cont-tablet-races .paginator-races .btn-next .text {
  width: 56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text);
}
.tour-list-races .cont-tablet-races .paginator-races .btn-next .btn-page {
  width: 36px;
}
.popover-change-element {
  --box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08),
    0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  --backdrop-opacity: 0;
  --min-width: 162px;
  --max-width: 160px;
}
.popover-change-element .body-popover-change {
  width: 160px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 10px;
  gap: 4px;
  box-shadow: 0px 5px 10px -1.5px #00000014, 0px 10px 20px -3px #0000001a, 0px 20px 40px -6px #0000001f;
}
.popover-change-element .body-popover-change .input-change {
  width: 100px;
}
