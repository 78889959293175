.cont-map {
  width: calc(100vw - 320px);
  width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100vh;
}
.map {
  width: calc(100vw - 320px);
  position: absolute;
  right: 0px;
  top: 0px;
  height: calc(var(--vh, 1vh) * 100);
}
.map.quick {
  width: calc(100vw - 840px) !important;
}
.leaflet-left {
  right: 16px !important;
  left: auto;
}
.leaflet-top {
  bottom: 16px !important;
  top: auto;
  position: absolute;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-control-zoom-in span {
  width: 20px;
  line-height: 44px;
}
/* .map .leaflet-bar {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16px;
  bottom: 16px;
  gap: 4px;
} */
.leaflet-bar {
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.leaflet-bar a {
  width: 44px !important;
  height: 44px !important;
  padding: 12px 12px !important;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.map .leaflet-bar a {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 12px;
  background-color: var(--elevated);
  color: var(--icon-elevated);
  font-size: 20px;
}
.map .leaflet-bar a:hover {
  background-color: var(--hover-elevated);
}
.leaflet-popup {
  bottom: 16px !important;
}
.leaflet-popup .leaflet-popup-close-button {
  display: none;
}
.leaflet-popup .leaflet-popup-content-wrapper {
  box-shadow: none;
  padding: 0px;
  background: transparent;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  min-width: 320px;
  margin: 0px;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map {
  display: flex;
  flex-direction: row;
  gap: 12px;
  min-width: 320px;
  max-width: 320px;
  border-radius: 10px;
  padding: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1.75px 3.5px -0.5px #0000000a, 0px 3.5px 7px -1px #0000000f, 0px 7px 14px -2px #00000014;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .left .image-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 8px;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .left .image-cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .rating {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .rating .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .price {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .price .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  white-space: nowrap;
}
.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .body-popup-map .right .price .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
@media screen and (max-width: 960px) {
  .cont-map {
    position: relative;
    width: 100vw;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
  }
  .map {
    width: 100vw;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
  }
  .leaflet-top {
    bottom: 96px !important;
    top: auto;
    position: fixed;
  }
}
