.reviews-feedback-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.reviews-feedback-page .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.questions-feedback-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.questions-feedback-page .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.faq-feedback-page {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.faq-feedback-page .upper-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.faq-feedback-page .upper-line .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
ion-modal.modal-reviews-details {
  --width: 480px;
  --height: auto;
  --max-height: calc(100vh - 64px);
  --border-radius: 12px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .client-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .client-cont .circle {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  overflow: hidden;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .client-cont .info-cont {
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .client-cont .info-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .client-cont .info-cont .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .rate-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .rate-cont .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .text-reviews {
  color: var(--text);
  font-family: var(--font-source);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .cont-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .cont-images .item-img {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .cont-images .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .info-reviews {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .info-reviews .line {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .info-reviews .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .info-reviews .line .text-name {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .info-reviews .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-opposite {
  display: none;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-separator .span {
  width: 20px;
  height: 20px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-separator .p-timeline-event-connector {
  width: 1px;
  background: #e6e5ea;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content {
  padding: 0px 16px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .icon-info-details {
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title {
  display: flex;
  flex-direction: column;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .title {
  cursor: pointer;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .info-cont .cont-title .collect-time {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-rate {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-rate .val {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .text-reviews-details {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .customized-timeline .p-timeline-event .p-timeline-event-content .cont-info-details .cont-images .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .btn-report {
  width: max-content;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .btn-report-answer-edit {
  width: max-content;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .cont-text-information .area-reviews {
  height: 160px;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-reviews-details .body-modal-reviews-details .btns .btn-save {
  width: 178px;
}
ion-modal.modal-reviews-report {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-reviews-report .body-modal-reviews-report {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}
ion-modal.modal-reviews-report .body-modal-reviews-report .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-reviews-report .body-modal-reviews-report .cont-text-information {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
ion-modal.modal-reviews-report .body-modal-reviews-report .cont-text-information .text-reviews {
  color: var(--text);
  font-family: var(--font-source);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-reviews-report .body-modal-reviews-report .cont-text-information .area-reviews {
  height: 160px;
}
ion-modal.modal-reviews-report .body-modal-reviews-report .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-questions-details {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-questions-details .body-modal-questions-details {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}
ion-modal.modal-questions-details .body-modal-questions-details .client-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-questions-details .body-modal-questions-details .client-cont .circle {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  overflow: hidden;
}
ion-modal.modal-questions-details .body-modal-questions-details .client-cont .info-cont {
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
}
ion-modal.modal-questions-details .body-modal-questions-details .client-cont .info-cont .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
ion-modal.modal-questions-details .body-modal-questions-details .client-cont .info-cont .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .line .name {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .btn-report {
  width: max-content;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .btn-report-answer-edit {
  width: max-content;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .btn-ed {
  width: 189px;
  padding: 0px;
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .l {
  border-top: 1px solid var(--divider);
}
ion-modal.modal-questions-details .body-modal-questions-details .cont-text-information .answer-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
ion-modal.modal-questions-details .body-modal-questions-details .area-reviews {
  height: 160px;
}
ion-modal.modal-questions-details .body-modal-questions-details .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-questions-details .body-modal-questions-details .btns .btn-save {
  width: 178px;
}
ion-modal.modal-faq-details {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.modal-faq-details .body-faq-details {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
ion-modal.modal-faq-details .body-faq-details .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-faq-details .body-faq-details .stages {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage {
  height: 40px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  gap: 2px;
  cursor: pointer;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage.active {
  border-bottom: 2px solid #f74439;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage.active .text-stage {
  color: #f74439 !important;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage.active .val-stage {
  background-color: #4b4957;
  color: #fff;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage .text-stage {
  color: var(--text-unfilled);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 2px;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage .val-stage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  border-radius: 800px;
  background-color: var(--status-gray);
  color: var(--text-unfilled-current);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
ion-modal.modal-faq-details .body-faq-details .stages .stage:hover .text-stage {
  color: var(--text-unfilled-current);
}
ion-modal.modal-faq-details .body-faq-details .cont-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.modal-faq-details .body-faq-details .cont-inputs .block-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-faq-details .body-faq-details .cont-inputs .block-input .title-input {
  padding: 0px 6px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-faq-details .body-faq-details .cont-inputs .block-input .area-faq {
  height: 136px;
}
ion-modal.modal-faq-details .body-faq-details .array-products {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid var(--divider);
  height: 336px;
  width: 100%;
  border-radius: 10px;
}
ion-modal.modal-faq-details .body-faq-details .array-products .cont-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
ion-modal.modal-faq-details .body-faq-details .array-products .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.modal-faq-details .body-faq-details .array-products .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.modal-faq-details .body-faq-details .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.modal-faq-details .body-faq-details .btns .btn-save {
  width: 178px;
}
