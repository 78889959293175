.list-timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.list-timeline .cont-tablet {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.list-timeline .cont-tablet .filter {
  padding: 0px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.list-timeline .cont-tablet .filter .filter-search-cont {
  gap: 8px;
  display: flex;
  flex-direction: row;
}
.list-timeline .cont-tablet .filter .filter-search-cont .input-search {
  width: 320px;
}
.list-timeline .cont-tablet .filter .filter-search-cont .paginator-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.list-timeline .cont-tablet .filter .filter-search-cont .paginator-date .btns-paginator {
  display: flex;
  flex-direction: row;
  gap: 1px;
}
.list-timeline .cont-tablet .filter .filter-search-cont .paginator-date .date {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  width: 120px;
}
.list-timeline .cont-tablet .filter .btn-elem-filter {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.list-timeline .cont-tablet .filter .btn-elem-filter .btn-elem-d {
  padding: 0px 4px;
}
.list-timeline .cont-tablet .filter .btn-elem-filter .btn-elem-f {
  width: 102px;
  padding: 0px 4px;
}
.list-timeline .cont-tablet .filter .btn-elem-filter .btn-elem-e {
  width: 95px;
  padding: 0px 4px;
}
.list-timeline .cont-tablet .chip-cont .chip {
  margin-left: 0;
  user-select: none;
  height: 24px;
  border-radius: 8px;
  padding: 0px 2px 0px 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(0deg, #fde4e4, #fde4e4);
}
.list-timeline .cont-tablet .chip-cont .chip .text {
  height: 16px;
  padding: 0px 6px 0px 6px;
  gap: 8px;
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #810038;
}
.list-timeline .cont-tablet .chip-cont .chip .img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-timeline {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 40px;
  padding: 4px;
  border-radius: 8px !important;
  border: none !important;
  overflow: visible;
}
.item-timeline .vis-item-content {
  padding: 0px !important;
}
.item-timeline .vis-item-content div {
  display: flex;
  flex-direction: column;
}
.item-timeline .vis-item-content div span {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.item-timeline .vis-item-content div div {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.vis-panel {
  box-sizing: content-box;
}
.vis-left,
.vis-major {
  display: none !important;
}
.vis-itemset {
  height: 500px !important;
}
.vis-minor {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vis-text {
  height: 32px !important;
  color: var(--text) !important;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.vis-panel.vis-top {
  border: none !important;
  box-shadow: 0px 1px 2px -0.25px rgba(0, 0, 0, 0.02), 0px 2px 4px -0.5px rgba(0, 0, 0, 0.04), 0px 4px 8px -1px rgba(0, 0, 0, 0.06);
}
.vis-panel.vis-center {
  border-top-style: none !important;
}
.vis-panel.vis-center {
  border: 0px !important;
}
.vis-timeline {
  border: none !important;
}
.vis-today {
  background-color: #f9f9fb !important;
  position: absolute;
  z-index: 0;
}
.vis.timeline .timeaxis .text.sunday {
  color: white;
}
.vis-grid.vis-minor {
  border: 1px solid #f4f3f6 !important;
  border-top: none !important;
}
.vis-item {
  z-index: 0 !important;
  cursor: pointer;
}
#visualization {
  position: relative;
  width: 100vw;
  height: 74vh;
}
.vis-item-overflow {
  display: flex;
}
