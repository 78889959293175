.profile {
  width: 100%;
  height: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.profile .upper-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile .upper-line .title {
  font-family: var(--font-nunito);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 100, "GRAD" 0, "slnt" 0, "XTRA" 428, "XOPQ" 96, "YOPQ" 88, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
}
.profile .upper-line .cont-btn {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.profile .upper-line .cont-btn .btn-save {
  width: 107px;
}
.profile .lower-line {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.profile .profile-inf {
  display: flex;
  flex-direction: column;
  width: 680px;
  font-family: var(--font-nunito);
  gap: 16px;
}
.profile .profile-inf .btn-save {
  width: 124px;
}
.profile .profile-inf .line-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile .profile-inf .line-input .text {
  width: 184px;
  height: 44px;
  padding: 12px 6px 12px 6px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.profile .profile-inf .line-input .doptext {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.profile .profile-inf .line-input .cont-text-descript {
  display: flex;
  flex-direction: column;
  width: 184px;
  padding: 0px 6px;
  height: 44px;
  justify-content: center;
}
.profile .profile-inf .line-input .cont-text-descript .text-disc {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.profile .profile-inf .line-input .cont-text-descript .dop-text-disc {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.profile .profile-inf .line-input .input-profile {
  width: 484px;
}
.profile .profile-inf .alert-place {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.profile .profile-inf .alert-place .repeat-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profile .profile-inf .alert-place .repeat-cont .alert-profile-email {
  padding: 12px;
  gap: 12px;
  width: 484px;
}
.profile .profile-inf .alert-place .repeat-cont .alert-profile-email .cont-close-alert {
  right: 12px;
  top: 12px;
}
.profile .profile-inf .alert-place .repeat-cont .repeat-message {
  width: max-content;
}
.profile .profile-inf .alert-place .repeat-cont .repeat-message .image path {
  stroke: var(--icon-transparent-bckg);
}
.profile .profile-inf .alert-place .repeat-cont .repeat-message:hover .image path {
  stroke: var(--icon-transparent-bckg-hover);
}
.profile .profile-inf .alert-place .repeat-cont .repeat-message:active .image path {
  stroke: var(--icon-transparent-bckg-hover);
}
.profile .notif-div {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.profile .notification-profile {
  border: 1px solid var(--divider);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  width: 504px;
  height: auto;
}
.profile .notification-profile .toggles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profile .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.change-password {
  --width: 480px;
  --height: auto;
  --border-radius: 12px;
}
ion-modal.change-password .cont-change-password {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
ion-modal.change-password .cont-change-password .title {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
ion-modal.change-password .cont-change-password .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.change-phone {
  --width: 520px;
  --height: max-content;
  --border-radius: 12px;
}
ion-modal.change-phone .image-back-login {
  user-select: none;
  position: absolute;
  rotate: 0deg;
  max-width: 100%;
  right: 0px;
  bottom: 0px;
}
ion-modal.change-phone .body-modal-login {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 32px;
  position: relative;
  align-items: center;
}
ion-modal.change-phone .body-modal-login .title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.change-phone .body-modal-login .title .cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-modal.change-phone .body-modal-login .title .title-lower {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
ion-modal.change-phone .body-modal-login .form-login {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 16px;
}
ion-modal.change-phone .body-modal-login .form-login .login,
ion-modal.change-phone .body-modal-login .form-login .password {
  width: 320px;
}
ion-modal.change-phone .body-modal-login .form-login .checkbox .cont-text {
  padding: 2px 0px;
}
ion-modal.change-phone .body-modal-login .form-login .checkbox .cont-text .text {
  gap: 4px;
  display: flex;
  flex-direction: row;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.change-phone .body-modal-login .form-login .checkbox .cont-text .text .link {
  color: var(--link);
  cursor: pointer;
}
ion-modal.change-phone .body-modal-login .form-login .checkbox .cont-text .link {
  user-select: none;
  color: var(--link);
  cursor: pointer;
}
ion-modal.change-phone .body-modal-login .form-login .cont-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
ion-modal.change-phone .body-modal-login .form-login .cont-input-code {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.change-phone .body-modal-login .form-login .input-code {
  border: 0;
  outline: 0;
  background: var(--substrate);
  border-radius: 10px;
  min-width: 68px;
  height: 88px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
ion-modal.change-phone .body-modal-login .form-login .input-code::placeholder {
  color: #b0afb8;
}
ion-modal.change-phone .body-modal-login .form-login .input-code.error {
  background-color: var(--input-background-error);
  border: 1px solid var(--input-border-error);
}
ion-modal.change-phone .body-modal-login .form-login .send-again-text-cont {
  font-family: var(--font-nunito);
  color: var(--text-unfilled);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 8px 0px 8px;
  gap: 2px;
}
ion-modal.change-phone .body-modal-login .form-login .send-again-text-cont .first-text-send-again,
ion-modal.change-phone .body-modal-login .form-login .send-again-text-cont .timer {
  opacity: 0.4;
}
ion-modal.change-phone .body-modal-login .form-login .send-again-text-cont .second-text-send-again {
  opacity: 1;
  cursor: pointer;
}
ion-modal.change-phone .body-modal-login .form-login .btn-login {
  width: 86px;
  border-radius: 12px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
ion-modal.change-phone .body-modal-login .form-login .btn-login.save {
  width: 137px;
}
ion-modal.change-phone .body-modal-login .lower-btn {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
ion-modal.change-phone .body-modal-login .lower-btn .lower-btn-text {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--text-muted);
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 720px) {
  .profile {
    width: 100vw;
    padding: 20px 16px;
    gap: 20px;
  }
  .profile .upper-line {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .profile .upper-line .title {
    font-size: 20px;
    line-height: 28px;
  }
  .profile .lower-line {
    flex-direction: column;
    gap: 32px;
  }
  .profile .profile-inf {
    width: 100%;
  }
  .profile .profile-inf .line-input {
    flex-direction: column;
    align-items: start;
  }
  .profile .profile-inf .line-input .text {
    height: 20px;
    padding: 0px 6px;
    gap: 8px;
  }
  .profile .profile-inf .line-input .doptext {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    color: var(--text-muted);
  }
  .profile .profile-inf .line-input .cont-text-descript {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 6px;
    height: 20px;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .profile .profile-inf .line-input .input-profile {
    width: 100%;
  }
  .profile .profile-inf .alert-place .repeat-cont .alert-profile-email {
    width: 100%;
  }
  .profile .notification-profile {
    border-radius: 10px;
    width: 100%;
    height: max-content;
  }
}
