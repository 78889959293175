.header-pages-client {
  position: absolute;
  z-index: 999;
  width: 100vw;
  height: 76px;
  border: 0px 0px 1px 0px;
  padding: 0px 16px;
  box-shadow: 0px 1px 2px -0.25px #00000005;
  box-shadow: 0px 2px 4px -0.5px #0000000a;
  box-shadow: 0px 4px 8px -1px #0000000f;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-pages-client .cont-header-btn {
  display: flex;
  flex-direction: row;
  max-width: 1120px;
  height: 44px;
  align-items: center;
  width: 1120px;
  justify-content: space-between;
}
.header-pages-client .cont-header-btn .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.header-pages-client .cont-header-btn .left .lef {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.header-pages-client .cont-header-btn .left img {
  cursor: pointer;
  user-select: none;
  height: 60px;
}
.header-pages-client .cont-header-btn .left .btn-menu-left {
  cursor: pointer;
  user-select: none;
  padding: 12px;
  display: flex;
  align-items: center;
}
.header-pages-client .cont-header-btn .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.header-pages-client .cont-header-btn .right .btn-login-header {
  width: 86px;
}
.header-pages-client .cont-header-btn .right .favourite {
  width: 44px;
}
.client-tours-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  height: calc(100vh - 76px);
}
.client-tours-page .content {
  width: 1120px;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.client-tours-page .content .title {
  font-family: var(--font-nunito-sans);
  color: var(--text);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .cont-null {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
}
.client-tours-page .content .cont-null .left {
  align-items: center;
  justify-content: center;
  width: 440px;
  padding: 32px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.client-tours-page .content .cont-null .left .icon-map-x {
  width: 60px;
  height: 60px;
}
.client-tours-page .content .cont-null .left .cont-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.client-tours-page .content .cont-null .left .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.client-tours-page .content .cont-null .left .cont-text .text-muted {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.client-tours-page .content .cont-null .right {
  overflow: hidden;
  position: relative;
}
.client-tours-page .content .cont-null .right img {
  height: 100%;
  object-fit: cover;
}
.client-tours-page .content .tours {
  width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.client-tours-page .content .tours .orders-cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.client-tours-page .content .tours .orders-cont .tour {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.client-tours-page .content .tours .orders-cont .tour .left .image-cont {
  width: 400px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
.client-tours-page .content .tours .orders-cont .tour .left .image-cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.client-tours-page .content .tours .orders-cont .tour .right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.client-tours-page .content .tours .orders-cont .tour .right .info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .rating {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .rating .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .number {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .summa-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .summa-cont .summa {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .tours .orders-cont .tour .right .info .summa-cont .duration {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .tours .orders-cont .tour .right .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.client-tours-page .content .tours .l {
  border-bottom: 1px solid var(--divider);
  width: 100%;
}
.client-tours-page .content .tours .visited-cont {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.client-tours-page .content .tours .visited-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .tours .visited-cont .tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.client-tours-page .content .tours .visited-cont .tour .left .cont-image {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-tours-page .content .tours .visited-cont .tour .left .cont-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.client-tours-page .content .tours .visited-cont .tour .right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.client-tours-page .content .tours .visited-cont .tour .right .info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.client-tours-page .content .tours .visited-cont .tour .right .info .rating {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.client-tours-page .content .tours .visited-cont .tour .right .info .rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.client-tours-page .content .tours .visited-cont .tour .right .info .rating .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.client-tours-page .content .tours .visited-cont .tour .right .info .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.client-tours-page .content .tours .visited-cont .tour .right .info .number {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.client-tours-page .content-scroll {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.client-tours-page .content-scroll .p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: var(--text);
}
.checkout {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}
.checkout .checkout-cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 1120px;
  padding: 32px 0px;
}
.checkout .checkout-cont .title-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
.checkout .checkout-cont .title-cont .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.checkout .checkout-cont .cont-form {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.checkout .checkout-cont .cont-form .left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 560px;
}
.checkout .checkout-cont .cont-form .left .title {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.checkout .checkout-cont .cont-form .left .title .text {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .left .title .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .left .traveler {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid var(--divider);
  border-radius: 10px;
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
}
.checkout .checkout-cont .cont-form .left .traveler .title {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}
.checkout .checkout-cont .cont-form .left .traveler .line-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .text {
  font-family: var(--font-nunito);
  width: 184px;
  height: 44px;
  padding: 12px 6px 12px 6px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .doptext {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .cont-text-descript {
  display: flex;
  flex-direction: column;
  width: 184px;
  padding: 0px 6px;
  height: 44px;
  justify-content: center;
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .cont-text-descript .text-disc {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  font-family: var(--font-nunito);
  color: var(--text);
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .cont-text-descript .dop-text-disc {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  font-family: var(--font-nunito);
  text-align: left;
  color: var(--text-muted);
}
.checkout .checkout-cont .cont-form .left .traveler .line-input .input-profile {
  width: 484px;
}
.checkout .checkout-cont .cont-form .left .btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout .checkout-cont .cont-form .left .l {
  border-bottom: 1px solid var(--divider);
  width: 100%;
}
.checkout .checkout-cont .cont-form .right {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.checkout .checkout-cont .cont-form .right .topay {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 360px;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid var(--divider);
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
}
.checkout .checkout-cont .cont-form .right .topay .rating {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.checkout .checkout-cont .cont-form .right .topay .rating .val-rate {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.checkout .checkout-cont .cont-form .right .topay .rating .reviews {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .title {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .title .cont-image {
  width: 60px;
  min-width: 60px;
  border-radius: 8px;
  overflow: hidden;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .title .cont-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .title .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .line {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .line .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .cont-info .line .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .l {
  border-bottom: 1px solid var(--divider);
  width: 100%;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .row-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .row-price .text {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .row-price .text.promotion {
  color: var(--success);
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .row-price .last-price {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  text-decoration: line-through;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .price-cont .price .text-b {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .checkbox-conter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkout .checkout-cont .cont-form .right .topay .checkbox-conter .checkbox .cont-text {
  padding: 2px 0px;
}
.checkout .checkout-cont .cont-form .right .topay .checkbox-conter .checkbox .cont-text .text {
  gap: 4px;
  display: flex;
  flex-direction: row;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.checkout .checkout-cont .cont-form .right .topay .checkbox-conter .checkbox .cont-text .text .link {
  color: var(--link);
  cursor: pointer;
}
.checkout .checkout-cont .cont-form .right .topay .checkbox-conter .checkbox .cont-text .link {
  user-select: none;
  color: var(--link);
  cursor: pointer;
}
.checkout .checkout-cont .cont-form .right .descr {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.checkout .checkout-cont .cont-form .right .descr .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.checkout .checkout-cont .cont-form .right .descr .tetxt {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}
.checkout .checkout-cont .cont-form .right .descr .tetxt .row {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.checkout .checkout-cont .cont-form .right .descr .tetxt .row .link {
  cursor: pointer;
  user-select: none;
  color: var(--link);
}
.res-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 32px;
}
.res-body .result-const {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  gap: 32px;
  height: 100%;
  width: 1120px;
}
.res-body .result-const.load {
  justify-content: center;
}
.res-body .result-const .loading-btn {
  height: 100%;
}
.res-body .result-const .loading-btn .p-progress-spinner-circle {
  stroke: var(--text) !important;
}
.res-body .result-const .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.res-body .result-const .title .text-title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}
.res-body .result-const .title .text-title.err {
  color: var(--text-filled-error);
}
.res-body .result-const .info-errors {
  display: flex;
  max-width: 640px;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.res-body .result-const .info-errors .text-info {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}
.res-body .result-const .info-errors .text-lower {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}
.res-body .result-const .info-errors .text-lower .link {
  color: var(--link);
  cursor: pointer;
}
.res-body .result-const .info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.res-body .result-const .info .cont-text-info .text-info {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.res-body .result-const .box-cont {
  width: 640px;
  border: 1px solid var(--divider);
  padding: 32px 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px -0.25px #00000005, 0px 2px 4px -0.5px #0000000a, 0px 4px 8px -1px #0000000f;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.res-body .result-const .box-cont .time {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.res-body .result-const .box-cont .place {
  color: var(--link);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.res-body .result-const .box-cont .btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
@media screen and (max-width: 720px) {
  .header-pages-client {
    height: 52px;
    padding: 8px 8px;
  }
  .header-pages-client .cont-header-btn {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 100%;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
  }
  .header-pages-client .cont-header-btn .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }
  .header-pages-client .cont-header-btn .left .custom-btn {
    display: none;
  }
  .header-pages-client .cont-header-btn .left .lef {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  .header-pages-client .cont-header-btn .left .lef img {
    cursor: pointer;
    user-select: none;
    height: 40px;
  }
  .header-pages-client .cont-header-btn .left .btn-menu-left {
    padding: 8px;
  }
  .header-pages-client .cont-header-btn .right {
    gap: 4px;
  }
  .header-pages-client .cont-header-btn .right .btn-login-header {
    width: 86px;
  }
  .header-pages-client .cont-header-btn .right #bottom-start-user-client {
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
  .header-pages-client .cont-header-btn .right .favourite {
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
  .client-tours-page {
    height: calc(100vh - 52px);
    padding: 16px 16px;
    justify-content: space-between;
  }
  .client-tours-page .content {
    width: calc(100vw - 32px);
    padding: 0px 0px;
  }
  .client-tours-page .content .title {
    font-size: 22px;
    line-height: 32px;
  }
  .client-tours-page .content .cont-null {
    flex-direction: column;
    width: 100%;
    height: max-content;
  }
  .client-tours-page .content .cont-null .left {
    width: 100%;
    height: 240px;
    padding: 20px 16px;
  }
  .client-tours-page .content .cont-null .right {
    height: 240px;
  }
  .client-tours-page .content .cont-null .right img {
    height: 100%;
    object-fit: cover;
  }
  .client-tours-page .content .tours {
    width: 100%;
  }
  .client-tours-page .content .tours .orders-cont .tour {
    flex-direction: column;
  }
  .client-tours-page .content .tours .orders-cont .tour .left .image-cont {
    height: 200px;
    width: calc(100vw - 32px);
  }
  .client-tours-page .content .tours .orders-cont .tour .right .info .title {
    font-size: 16px;
    line-height: 24px;
  }
  .client-tours-page .content .tours .orders-cont .tour .right .info .summa-cont .summa {
    font-size: 18px;
    line-height: 28px;
  }
  .client-tours-page .content .tours .l {
    border-bottom: 1px solid var(--divider);
    width: calc(100vw - 32px);
  }
  .client-tours-page .content .tours .visited-cont .title {
    font-size: 20px;
    line-height: 28px;
  }
  .client-tours-page .content .tours .visited-cont .tour {
    align-items: flex-start;
    gap: 16px;
  }
  .client-tours-page .content .tours .visited-cont .tour .left .cont-image {
    width: 96px;
    height: 96px;
  }
  .client-tours-page .content .tours .visited-cont .tour .right .info .title {
    font-size: 16px;
    line-height: 24px;
  }
  .checkout .checkout-cont {
    width: 100vw;
    padding: 16px 16px;
  }
  .checkout .checkout-cont .title-cont .title {
    font-size: 22px;
    line-height: 32px;
  }
  .checkout .checkout-cont .cont-form {
    flex-direction: column;
  }
  .checkout .checkout-cont .cont-form .left {
    width: 100%;
  }
  .checkout .checkout-cont .cont-form .left .title .text {
    font-size: 18px;
    line-height: 27px;
  }
  .checkout .checkout-cont .cont-form .left .traveler .line-input {
    flex-direction: column;
    gap: 4px;
  }
  .checkout .checkout-cont .cont-form .left .traveler .line-input .text {
    font-family: var(--font-nunito);
    width: 100%;
    height: 20px;
    padding: 0px 6px;
    gap: 8px;
  }
  .checkout .checkout-cont .cont-form .left .traveler .line-input .cont-text-descript {
    flex-direction: row;
    width: 100%;
    height: 20px;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .checkout .checkout-cont .cont-form .left .traveler .line-input .input-profile {
    width: 100%;
  }
  .checkout .checkout-cont .cont-form .right .topay {
    width: 100%;
  }
  .checkout .checkout-cont .cont-form .right .topay .checkbox-conter .checkbox .cont-text {
    padding: 2px 0px;
  }
  .checkout .checkout-cont .cont-form .right .descr {
    width: 100%;
  }
  .res-body .result-const {
    padding: 16px 16px;
    width: 100vw;
  }
  .res-body .result-const .title .text-title {
    font-size: 22px;
    line-height: 32px;
  }
  .res-body .result-const .box-cont {
    width: 100%;
  }
  .res-body .result-const .box-cont .btns {
    flex-direction: column;
  }
}
