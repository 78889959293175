.profile-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 32px 0px 32px;
}
.profile-client .body-prof {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 1120px;
}
.profile-client .body-prof .upper-line {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.profile-client .body-prof .upper-line .title {
  color: var(--text);
  font-family: var(--font-nunito-sans);
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.profile-client .body-prof .cont-btn {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.profile-client .body-prof .lower-line {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 640px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid var(--divider);
}
.profile-client .body-prof .lower-line .btn-save {
  width: 124px;
}
.profile-client .body-prof .lower-line .profile-inf {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: var(--font-nunito);
}
.profile-client .body-prof .lower-line .profile-inf .line-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.profile-client .body-prof .lower-line .profile-inf .line-input .text {
  width: 184px;
  height: 44px;
  padding: 12px 6px 12px 6px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.profile-client .body-prof .lower-line .profile-inf .line-input .doptext {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.profile-client .body-prof .lower-line .profile-inf .line-input .cont-text-descript {
  display: flex;
  flex-direction: column;
  width: 184px;
  padding: 0px 6px;
  height: 44px;
  justify-content: center;
}
.profile-client .body-prof .lower-line .profile-inf .line-input .cont-text-descript .text-disc {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.profile-client .body-prof .lower-line .profile-inf .line-input .cont-text-descript .dop-text-disc {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
.profile-client .body-prof .lower-line .profile-inf .alert-place {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.profile-client .body-prof .lower-line .profile-inf .alert-place .repeat-cont {
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.profile-client .body-prof .lower-line .profile-inf .alert-place .repeat-cont .repeat-message {
  width: 186px;
}
.profile-client .body-prof .lower-line .profile-inf .input-profile {
  width: 380px;
}
.profile-client .body-prof .lower-line .l {
  width: 100%;
  border-top: 1px solid var(--divider);
}
@media screen and (max-width: 720px) {
  .profile-client {
    padding: 16px 16px;
  }
  .profile-client .body-prof {
    width: 100%;
  }
  .profile-client .body-prof .upper-line .title {
    font-size: 22px;
    line-height: 32px;
  }
  .profile-client .body-prof .cont-btn {
    flex-direction: column;
  }
  .profile-client .body-prof .lower-line {
    width: 100%;
    padding: 20px 16px;
  }
  .profile-client .body-prof .lower-line .profile-inf .line-input {
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }
  .profile-client .body-prof .lower-line .profile-inf .line-input .text {
    height: 20px;
    padding: 0px 6px;
    gap: 8px;
  }
  .profile-client .body-prof .lower-line .profile-inf .line-input .cont-text-descript {
    flex-direction: row;
    height: 20px;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .profile-client .body-prof .lower-line .profile-inf .alert-place {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .profile-client .body-prof .lower-line .profile-inf .alert-place .repeat-cont {
    width: 100%;
  }
  .profile-client .body-prof .lower-line .profile-inf .alert-place .repeat-cont .repeat-message {
    width: 186px;
  }
  .profile-client .body-prof .lower-line .profile-inf .input-profile {
    width: 100%;
  }
}
