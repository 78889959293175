.cont-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--input-background);
  border: 1px solid var(--input-normal-border, rgba(0, 0, 0, 0));
}
.cont-input.small {
  border-radius: 8px;
  height: 36px;
  padding: 2px;
}
.cont-input.medium {
  border-radius: 10px;
  height: 44px;
  padding: 6px;
}
.cont-input.large {
  border-radius: 10px;
  height: 52px;
  padding: 10px 8px;
}
.cont-input.area {
  border-radius: 10px;
  height: 120px;
  padding: 10px 8px;
}
.cont-input .cont-image {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.cont-input input[type=time]::-webkit-calendar-picker-indicator {
  color: #696775;
  background: url("../../image/icons/clock.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}
.cont-input input[type=date]::-webkit-calendar-picker-indicator {
  color: #696775;
  background: url("../../image/icons/calendar.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}
.cont-input .dropdown {
  position: absolute;
  z-index: 9;
  top: 226px;
  width: 246px;
  max-height: 372px;
  border-radius: 8px;
  background-color: #fff;
  padding: 6px 0px;
  box-shadow: 0px 3px 6px -1px #0000000F, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001A;
  overflow-y: auto;
  overflow-x: hidden;
}
.cont-input .dropdown .cont-dropdown {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
}
.cont-input .dropdown .cont-dropdown:hover {
  cursor: pointer;
  background-color: var(--divider);
}
.cont-input .dropdown .cont-dropdown .checkbox-cont {
  cursor: pointer;
  gap: 0px;
}
.cont-input .dropdown .cont-dropdown .cont-text {
  user-select: none;
  cursor: pointer;
  pointer-events: none;
  padding: 0px 8px;
}
.cont-input .dropdown .cont-dropdown .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.cont-input .dropdown .cont-dropdown .cont-text .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-input .input {
  width: 100%;
  background-color: transparent;
  padding: 4px 6px;
  border: none;
  outline: none;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-input .input:enabled:focus {
  box-shadow: none;
}
.cont-input .input::placeholder {
  color: var(--text-input-placeholder);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-input:hover {
  background: var(--input-background-hover);
}
.cont-input.disabled {
  opacity: 40%;
}
.cont-input.focus {
  box-shadow: var(--input-border-focus);
}
.cont-input.invalid {
  background: var(--input-background-error);
  border: 1px solid var(--input-border-error);
  box-shadow: none;
}
.cont-input .textarea {
  resize: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 4px 6px;
  border: none;
  outline: none;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-input .textarea::placeholder {
  color: var(--text-input-placeholder);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-input:hover {
  background: var(--input-background-hover);
}
.cont-input.disabled {
  opacity: 40%;
}
.cont-input.focus {
  box-shadow: var(--input-border-focus);
}
.cont-input.invalid {
  background: var(--input-background-error);
  border: 1px solid var(--input-border-error);
  box-shadow: none;
}
.el-offer {
  position: relative;
  width: 320px;
  height: 240px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.el-offer .cont-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.el-offer .cont-text .text-1 {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
.el-offer .cont-text .text-2 {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--text);
}
.el-offer .cont-look {
  width: 100px;
}
.el-offer .cont-look .look {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #606b6c;
  padding: 0px 2px 2px 2px;
}
.el-offer .image {
  position: absolute;
  width: 120px;
  height: auto;
  bottom: 0px;
  right: 20px;
}
.sup {
  background: #eef4ff;
}
.prom {
  background: #fef2e4;
}
.popover-menu-el-list {
  --box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08),
    0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  --backdrop-opacity: 0;
  --min-width: 162px;
  --max-width: 204px;
}
.popover-menu-el-list::part(content) {
  border-radius: 8px;
}
.popover-menu-el-list .body-popover {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
.popover-menu-el-list .body-popover .cont-el-pop {
  cursor: pointer;
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
}
.popover-menu-el-list .body-popover .cont-el-pop .text {
  padding: 0px 8px;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}
.popover-header-el-list {
  --box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.06), 0px 6px 12px -2px rgba(0, 0, 0, 0.08),
    0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  --backdrop-opacity: 0;
  --min-width: 162px;
  --max-width: 204px;
  --widht: auto;
}
.popover-header-el-list::part(content) {
  border-radius: 8px;
}
.popover-header-el-list.user {
  --max-width: 138px;
}
.popover-header-el-list.showcase {
  --max-width: 141px;
}
.popover-header-el-list.finance {
  --max-width: 92px;
}
.popover-header-el-list.feedback {
  --max-width: 95px;
}
.popover-header-el-list.settings {
  --max-width: 115px;
}
.popover-header-el-list .body-popover {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
}
.popover-header-el-list .body-popover .cont-el-pop {
  cursor: pointer;
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
}
.popover-header-el-list .body-popover .cont-el-pop .text {
  padding: 0px 8px;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}
ion-modal.modal-change-table {
  --width: 480px;
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.modal-change-table .body-modal {
  position: relative;
  font-family: var(--font-nunito);
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
}
ion-modal.modal-change-table .body-modal::-webkit-scrollbar {
  display: block;
  width: 5px;
}
ion-modal.modal-change-table .body-modal::-webkit-scrollbar-track {
  background: transparent;
}
ion-modal.modal-change-table .body-modal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #22222266;
}
ion-modal.modal-change-table .body-modal::-webkit-scrollbar-track-piece:end {
  background: #22222214;
  margin-bottom: 8px;
  border-radius: 8px;
}
ion-modal.modal-change-table .body-modal::-webkit-scrollbar-track-piece:start {
  background: #22222214;
  border-radius: 8px;
  margin-top: 8px;
}
ion-modal.modal-change-table .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
ion-modal.modal-change-table .body-modal .menu .line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  gap: 4px;
}
ion-modal.modal-change-table .body-modal .menu .line .left-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
ion-modal.modal-change-table .body-modal .menu .line .left-cont .cont-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 2px 0px;
  border-radius: 12px;
}
ion-modal.modal-change-table .body-modal .menu .line .left-cont .cont-text .text {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
}
ion-modal.modal-change-table .body-modal .menu .line .left-cont .cont-text .text-lower {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-muted);
}
ion-modal.modal-change-table .body-modal .menu .line .cont-arrow {
  padding: 8px;
}
ion-modal.modal-change-table .body-modal .cont-btn {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
ion-modal.modal-change-table .body-modal .cont-btn .btn-cancel {
  width: 84px;
}
ion-modal.modal-change-table .body-modal .cont-btn .btn-save {
  width: 107px;
}
.cont-close-btn {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 50;
}
ion-toggle.toggle-menu-change-table {
  padding: 2px;
  --track-background: var(--toggle-off-back);
  --track-background-checked: var(--toggle-on-back);
  --handle-background: var(--toggle-handler);
  --handle-background-checked: var(--toggle-handler);
  --handle-width: 13.33px;
  --handle-height: 13.33px;
  --handle-max-height: auto;
  --handle-spacing: 3.33px;
  --handle-border-radius: 800px;
  --handle-box-shadow: none;
}
ion-toggle.toggle-menu-change-table::part(track) {
  border: 1px solid #00000000;
  height: 18px;
  width: 30px;
}
ion-toggle.toggle-menu-change-table:hover {
  --track-background: var(--toggle-off-back-hover);
  --track-background-checked: var(--toggle-on-back-hover);
  --handle-background: var(--toggle-handler);
  --handle-background-checked: var(--toggle-handler);
}
ion-toggle.toggle-menu-change-table::part(track):focus {
  box-shadow: var(--toggle-back-boxshadow);
}
ion-toggle.toggle-menu-change-table:disabled {
  opacity: 0.4;
}
ion-toggle.toggle-menu-change-table.invalid {
  --track-background: var(--toggle-invalid-back) --track-background-checked: var(--toggle-invalid-back);
  --handle-background: var(--toggle-handler-invalid-off);
  --handle-background-checked: var(--toggle-handler-invalid-on);
}
ion-toggle.toggle-menu-change-table.invalid::part(track) {
  border: 1px solid #DD291D;
  height: 18px;
  width: 30px;
}
.custom-btn {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0px 12px;
  font-family: var(--font-nunito);
}
.custom-btn .cont-spinner {
  display: flex;
  align-items: center;
}
.custom-btn .loading-btn {
  width: 20px;
  height: 20px;
}
.custom-btn.small {
  padding: 0px 12px;
  border-radius: 8px;
  height: 36px;
}
.custom-btn.small .text-btn {
  gap: 4px;
  display: flex;
  flex-direction: row;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 2px 0px;
}
.custom-btn.small.cube {
  width: 36px;
}
.custom-btn.medium {
  border-radius: 12px;
  padding: 0px 16px;
  height: 44px;
}
.custom-btn.medium .text-btn {
  gap: 6px;
  display: flex;
  flex-direction: row;
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.custom-btn.medium .text-btn svg {
  margin-top: 2px;
}
.custom-btn.medium.cube {
  width: 44px;
}
.custom-btn.large {
  border-radius: 12px;
  padding: 0px 20px;
  height: 52px;
}
.custom-btn.large .text-btn {
  gap: 8px;
  display: flex;
  flex-direction: row;
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 2px 0px;
}
.custom-btn.large.cube {
  width: 52px;
}
.custom-btn.bright {
  background-color: var(--bright);
}
.custom-btn.bright .loading-btn {
  --color: var(--icon-bright);
}
.custom-btn.bright .text-btn {
  pointer-events: none;
  color: var(--text-bright-btn);
}
.custom-btn.bright .text-btn svg path {
  stroke: var(--icon-bright);
}
.custom-btn.bright:hover {
  background-color: var(--hover-bright);
}
.custom-btn.bright:focus {
  background-color: var(--bright);
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.bright:active {
  background-color: var(--pressed-bright);
}
.custom-btn.bright:disabled {
  background-color: var(--bright);
  opacity: 40%;
}
.custom-btn.muted {
  background-color: var(--muted);
}
.custom-btn.muted .loading-btn {
  --color: var(--icon-muted);
}
.custom-btn.muted .text-btn {
  pointer-events: none;
  color: var(--text-muted-btn);
}
.custom-btn.muted .text-btn svg path {
  stroke: var(--icon-muted);
}
.custom-btn.muted:hover {
  background-color: var(--hover-muted);
}
.custom-btn.muted:focus {
  background-color: var(--muted);
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.muted:active {
  background-color: var(--pressed-muted);
}
.custom-btn.muted:disabled {
  background-color: var(--muted);
  opacity: 40%;
}
.custom-btn.outline {
  background-color: transparent;
  border: 1px solid var(--border-outline-btn);
}
.custom-btn.outline .loading-btn {
  --color: var(--icon-transparent-bckg);
}
.custom-btn.outline .text-btn {
  pointer-events: none;
  color: var(--text-outline-btn);
}
.custom-btn.outline .text-btn svg path {
  stroke: var(--icon-transparent-bckg);
}
.custom-btn.outline:hover {
  background-color: var(--hover-outline);
}
.custom-btn.outline:focus {
  background-color: transparent;
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.outline:active {
  background-color: var(--pressed-outline);
}
.custom-btn.outline:disabled {
  background-color: transparent;
  opacity: 40%;
}
.custom-btn.ghost {
  background-color: transparent;
}
.custom-btn.ghost .loading-btn {
  --color: var(--icon-transparent-bckg);
}
.custom-btn.ghost .text-btn {
  pointer-events: none;
  color: var(--text-ghost-btn);
  display: flex;
}
.custom-btn.ghost .text-btn svg path {
  stroke: var(--icon-transparent-bckg);
}
.custom-btn.ghost:hover {
  background-color: var(--hover-outline);
}
.custom-btn.ghost:focus {
  background-color: transparent;
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.ghost:active {
  background-color: var(--pressed-ghost);
}
.custom-btn.ghost:disabled {
  background-color: transparent;
  opacity: 40%;
}
.custom-btn.elevated {
  background-color: var(--elevated);
  box-shadow: var(--box-shadow-btn-elevated);
}
.custom-btn.elevated .loading-btn {
  --color: var(--icon-elevated);
}
.custom-btn.elevated .text-btn {
  color: var(--text-elevated-btn);
  pointer-events: none;
}
.custom-btn.elevated .text-btn svg path {
  stroke: var(--icon-elevated);
}
.custom-btn.elevated:hover {
  background-color: var(--hover-elevated);
  box-shadow: var(--box-shadow-btn-elevated);
}
.custom-btn.elevated:focus {
  background-color: var(--elevated);
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.elevated:active {
  background-color: var(--pressed-elevated);
  box-shadow: var(--box-shadow-btn);
}
.custom-btn.elevated:disabled {
  background-color: var(--elevated);
  opacity: 40%;
}
ion-modal.filter-table-operator_users,
ion-modal.filter-table-options,
ion-modal.filter-table-oper-hist,
ion-modal.filter-table-reviews,
ion-modal.filter-table-questions {
  --width: 480px;
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.filter-table-operator_users .body-modal,
ion-modal.filter-table-options .body-modal,
ion-modal.filter-table-oper-hist .body-modal,
ion-modal.filter-table-reviews .body-modal,
ion-modal.filter-table-questions .body-modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  font-family: var(--font-nunito);
}
ion-modal.filter-table-operator_users .body-modal .title,
ion-modal.filter-table-options .body-modal .title,
ion-modal.filter-table-oper-hist .body-modal .title,
ion-modal.filter-table-reviews .body-modal .title,
ion-modal.filter-table-questions .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.filter-table-operator_users .body-modal .body,
ion-modal.filter-table-options .body-modal .body,
ion-modal.filter-table-oper-hist .body-modal .body,
ion-modal.filter-table-reviews .body-modal .body,
ion-modal.filter-table-questions .body-modal .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.filter-table-operator_users .body-modal .body .line,
ion-modal.filter-table-options .body-modal .body .line,
ion-modal.filter-table-oper-hist .body-modal .body .line,
ion-modal.filter-table-reviews .body-modal .body .line,
ion-modal.filter-table-questions .body-modal .body .line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
ion-modal.filter-table-operator_users .body-modal .body .line .input-short,
ion-modal.filter-table-options .body-modal .body .line .input-short,
ion-modal.filter-table-oper-hist .body-modal .body .line .input-short,
ion-modal.filter-table-reviews .body-modal .body .line .input-short,
ion-modal.filter-table-questions .body-modal .body .line .input-short {
  width: 218px;
}
ion-modal.filter-table-operator_users .body-modal .buttons,
ion-modal.filter-table-options .body-modal .buttons,
ion-modal.filter-table-oper-hist .body-modal .buttons,
ion-modal.filter-table-reviews .body-modal .buttons,
ion-modal.filter-table-questions .body-modal .buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.cont-alert-box {
  position: relative;
  border-radius: 10px;
  padding: 4px;
  gap: 4px;
}
.cont-alert-box .cont-close-alert {
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 8px;
  height: 36px;
  cursor: pointer;
  user-select: none;
}
.cont-alert-box .info {
  display: flex;
  width: calc(100% - 8px - 36px);
  max-width: 100%;
  flex-direction: row;
  align-items: start;
  padding: 8px;
  gap: 8px;
}
.cont-alert-box .info.noclose {
  width: 100%;
}
.cont-alert-box .info .icon-alert-box {
  min-width: 20px;
  min-height: 20px;
}
.cont-alert-box .info .cont-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cont-alert-box .info .cont-text .title {
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-alert-box .info .cont-text .text {
  font-family: var(--font-nunito);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.cont-alert-box.neutral {
  background-color: var(--alert-neutral);
}
.cont-alert-box.neutral .cont-text .title {
  color: var(--alert-neutral-text);
}
.cont-alert-box.neutral .cont-text .text {
  color: var(--alert-neutral-text);
}
.cont-alert-box.primary {
  background-color: var(--alert-primary);
}
.cont-alert-box.primary .cont-text .title {
  color: var(--alert-primary-text);
}
.cont-alert-box.primary .cont-text .text {
  color: var(--alert-primary-text);
}
.cont-alert-box.secondary {
  background-color: var(--alert-secondary);
}
.cont-alert-box.secondary .cont-text .title {
  color: var(--alert-secondary-text);
}
.cont-alert-box.secondary .cont-text .text {
  color: var(--alert-secondary-text);
}
.cont-alert-box.info {
  background-color: var(--alert-info);
}
.cont-alert-box.info .cont-text .title {
  color: var(--alert-info-text);
}
.cont-alert-box.info .cont-text .text {
  color: var(--alert-info-text);
}
.cont-alert-box.success {
  background-color: var(--alert-success);
}
.cont-alert-box.success .cont-text .title {
  color: var(--alert-success-text);
}
.cont-alert-box.success .cont-text .text {
  color: var(--alert-success-text);
}
.cont-alert-box.warning {
  background-color: var(--alert-warning);
}
.cont-alert-box.warning .cont-text .title {
  color: var(--alert-warning-text);
}
.cont-alert-box.warning .cont-text .text {
  color: var(--alert-warning-text);
}
.cont-alert-box.danger {
  background-color: var(--alert-danger);
}
.cont-alert-box.danger .cont-text .title {
  color: var(--alert-danger-text);
}
.cont-alert-box.danger .cont-text .text {
  color: var(--alert-danger-text);
}
.icon-eye path {
  stroke: var(--icon-password);
}
.icon-unfilled path {
  stroke: var(--icon-unfilled);
}
.icon-bright path {
  stroke: var(--icon-bright);
}
.icon-muted path {
  stroke: var(--icon-muted);
}
.icon-outline path {
  stroke: var(--icon-transparent-bckg);
}
.icon-outline:hover path {
  stroke: var(--icon-transparent-bckg-hover);
}
.icon-elevated path {
  stroke: var(--icon-elevated);
}
.icon-muted-info path {
  stroke: var(--icon-muted-info);
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 64px);
  height: 212px;
  padding: 32px 0px 32px 0px;
  gap: 32px;
  border-top: 1px solid var(--divider);
}
.footer .cont-footer {
  width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px 16px 0px;
  gap: 32px;
}
.footer .cont-footer .first-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer .cont-footer .first-line .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.footer .cont-footer .first-line .left .text {
  cursor: pointer;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 640;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: 'wdth' 100, 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
}
.footer .cont-footer .first-line .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.footer .cont-footer .first-line .right .text-muted {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.footer .cont-footer .first-line .right .social {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.footer .cont-footer .first-line .right .social img {
  cursor: pointer;
}
.footer .cont-footer .second-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer .cont-footer .second-line .left {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer .cont-footer .second-line .left .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.footer .cont-footer .second-line .left .top .text {
  cursor: pointer;
  user-select: none;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.footer .cont-footer .second-line .left .top .text:first-child {
  cursor: default;
  user-select: auto;
}
.footer .cont-footer .second-line .left .bottom {
  font-family: var(--font-nunito);
  color: var(--text-muted);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.footer .cont-footer .second-line .right {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}
.checkbox-cont {
  width: 100%;
  display: flex;
  gap: 8px;
}
.center {
  align-items: center;
}
.top {
  align-items: flex-start;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: #d5d5d5;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
}
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: #d5d5d5;
  border-radius: 800px;
  cursor: pointer;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
}
label {
  width: 100%;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
input[type="checkbox"]:after {
  content: url("../../image/icons/check.svg");
  display: none;
  margin-top: 1px;
}
input[type="checkbox"]:hover {
  background-color: var(--checkbox-off-hover);
}
input[type="checkbox"]:focus {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 0px 1px #00000099;
}
input[type="checkbox"]:disabled {
  opacity: 0, 4;
}
input[type="checkbox"]:checked {
  background-color: var(--checkbox-on);
}
input[type="checkbox"]:checked:hover {
  background-color: var(--checkbox-on-hover);
}
input[type="checkbox"]:checked:focus {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 0px 1px #00000099;
}
input[type="checkbox"]:checked:disabled {
  opacity: 0, 4;
}
input[type="checkbox"]:checked:after {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="checkbox"]:checked:invalid {
  border: 1px solid var(--checkbox-invalid-border);
  background-color: var(--checkbox-invalid);
}
input[type="checkbox"]:invalid {
  border: 1px solid var(--checkbox-invalid-border);
  background-color: var(--checkbox-invalid);
}
input[type="checkbox"]:after:invalid {
  content: url("../../image/icons/check-red.svg");
  display: none;
}
input[type="radio"]:after {
  content: url("../../image/dotcheckwhite.svg");
  margin-bottom: 2px;
  display: none;
}
input[type="radio"]:hover {
  background-color: var(--checkbox-off-hover);
}
input[type="radio"]:focus {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 0px 1px #00000099;
}
input[type="radio"]:disabled {
  opacity: 0, 4;
}
input[type="radio"]:checked {
  background-color: var(--checkbox-on);
}
input[type="radio"]:checked:hover {
  background-color: var(--checkbox-on-hover);
}
input[type="radio"]:checked:focus {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 0px 1px #00000099;
}
input[type="radio"]:checked:disabled {
  opacity: 0, 4;
}
input[type="radio"]:checked:after {
  display: block;
}
input[type="radio"]:checked:invalid {
  border: 1px solid var(--checkbox-invalid-border);
  background-color: var(--checkbox-invalid);
}
input[type="radio"]:invalid {
  border: 1px solid var(--checkbox-invalid-border);
  background-color: var(--checkbox-invalid);
}
input[type="radio"]:after:invalid {
  content: url("../../image/dotcheckred.svg");
  display: none;
}
.number-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.number-code input::-webkit-outer-spin-button,
.number-code input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.number-code .input-code {
  border: 0;
  outline: 0;
  background: var(--substrate);
  border-radius: 4px;
  width: 68px;
  height: 88px;
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
.number-code .input-code::placeholder {
  color: #B0AFB8;
}
.rating-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}
.rating-star .star {
  width: 20px;
  height: 20px;
}
.cont-select {
  position: relative;
}
.cont-select .icon-select {
  pointer-events: none;
  position: absolute;
  top: 16px;
  right: 14px;
}
.cont-select .icon-select.small {
  top: 6px;
  right: 8px;
}
.cont-select .icon-select.medium {
  top: 10px;
  right: 12px;
}
.cont-select .icon-select.large {
  top: 14px;
  right: 14px;
}
.cont-select .undef {
  color: var(--text-muted) !important;
}
.cont-select .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: row;
  align-items: center;
  background: var(--input-background);
  border: 1px solid rgba(0, 0, 0, 0);
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.cont-select .select.small {
  border-radius: 8px;
  height: 36px;
  padding: 6px 32px 6px 6px;
}
.cont-select .select.medium {
  border-radius: 10px;
  height: 44px;
  padding: 10px 32px 10px 6px;
}
.cont-select .select.large {
  border-radius: 10px;
  height: 52px;
  padding: 14px 14px 14px 14px;
}
.cont-select .select:hover {
  background: var(--input-background-hover);
}
.cont-select .select.disabled {
  opacity: 40%;
}
.cont-select .select.p-focus {
  box-shadow: var(--input-border-focus);
}
.cont-select .select.invalid {
  background: var(--input-background-error);
  border: 1px solid var(--input-border-error);
  box-shadow: none;
}
.cont-select .p-multiselect-label {
  padding: 0px;
}
.cont-select .p-inputtext {
  padding: 0px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-select .p-dropdown-panel {
  background: #fff;
  margin-top: 4px;
}
.p-multiselect-header {
  display: none;
}
.p-checkbox {
  display: none;
}
.p-multiselect-token {
  height: 24px;
  border-radius: 8px;
  border: 1px solid #B0AFB8;
  background: var(--status-gray);
  padding: 4px 8px 4px 8px;
  margin-right: 4px;
}
.p-multiselect-token-icon {
  margin-top: 4px;
  background: url("../../image/icons/x.svg");
  background-repeat: no-repeat;
}
.p-multiselect-token-icon g {
  display: none;
}
.p-placeholder {
  color: var(--text-input-placeholder) !important;
}
.p-dropdown-item,
.p-multiselect-item {
  padding: 4px 8px;
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.p-dropdown-items,
.p-multiselect-items {
  padding: 6px 8px;
}
.p-disabled {
  opacity: 40%;
}
.p-multiselect-empty-message,
.p-dropdown-empty-message {
  padding: 4px 8px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.p-dropdown-clear-icon {
  background: url("../../image/icons/x20.svg");
  top: 14px;
}
.p-dropdown-clear-icon path {
  display: none;
}
.p-icon {
  width: 20px;
  height: 20px;
}
.p-dropdown-filter-icon {
  width: 14px !important;
  height: 14px !important;
}
.ql-container {
  width: 680px;
  min-height: 260px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: var(--input-background);
  border: none !important;
  font-family: var(--font-nunito) !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px;
}
.ql-editor {
  padding: 4px 6px !important;
}
.textarea {
  height: 260px;
}
.ql-editor::before {
  height: 100%;
  font-family: var(--font-nunito) !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  font-style: normal !important;
  letter-spacing: 0em;
  text-align: left;
}
.cont-header {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 680px;
  padding: 4px !important;
  border-bottom: 1px solid var(--divider) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background: var(--input-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
}
.cont-header .ql-snow.ql-toolbar button,
.cont-header .ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.cont-header .ql-snow * {
  box-sizing: border-box;
}
.cont-header .ql-bold,
.cont-header .ql-list,
.cont-header .insert {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
}
.cont-header .ql-bold svg,
.cont-header .ql-list svg,
.cont-header .insert svg {
  width: 20px;
  height: 20px;
}
.cont-header .l {
  height: 36px;
  border: 1px solid var(--divider);
}
.cont-header .insert-icon:hover path {
  stroke: #06c;
}
ion-modal.filter-table-tours {
  --width: 480px;
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.filter-table-tours .body-modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  font-family: var(--font-nunito);
}
ion-modal.filter-table-tours .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.filter-table-tours .body-modal .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.filter-table-tours .body-modal .body .line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
ion-modal.filter-table-tours .body-modal .body .line .input-short {
  width: 218px;
}
ion-modal.filter-table-tours .body-modal .buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.status-badge {
  padding: 2px 6px 2px 6px;
  border-radius: 800px;
  width: max-content;
}
.status-badge .text-status {
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  font-variation-settings: 'wdth' 0, 'GRAD' 0, 'slnt' 0, 'XTRA' 0, 'XOPQ' 0, 'YOPQ' 0, 'YTLC' 0, 'YTUC' 0, 'YTAS' 0, 'YTDE' 0, 'YTFI' 0;
}
.status-badge._red {
  background-color: var(--status-red);
}
.status-badge._red .text-status {
  color: var(--status-red-text);
}
.status-badge._gray {
  background-color: var(--status-gray);
}
.status-badge._gray .text-status {
  color: var(--status-gray-text);
}
.status-badge._green {
  background-color: var(--status-green);
}
.status-badge._green .text-status {
  color: var(--status-green-text);
}
.status-badge._orange {
  background-color: var(--status-orange);
}
.status-badge._orange .text-status {
  color: var(--status-orange-text);
}
.cont-toggle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.cont-toggle.center {
  align-items: center;
}
.cont-toggle .cont-text {
  display: flex;
  flex-direction: column;
  padding: 2px 0px;
  gap: 4px;
}
.cont-toggle .cont-text .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-toggle .cont-text .text-lower {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
ion-modal.filter-table-promotions {
  --width: 480px;
  --height: 304px;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.filter-table-promotions .body-modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  font-family: var(--font-nunito);
}
ion-modal.filter-table-promotions .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.filter-table-promotions .body-modal .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.filter-table-promotions .body-modal .body .line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
ion-modal.filter-table-promotions .body-modal .body .line .input-short {
  width: 218px;
}
ion-modal.filter-table-promotions .body-modal .buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-modal.filter-table-orders {
  --width: 480px;
  --height: 628px;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.filter-table-orders .body-modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  font-family: var(--font-nunito);
}
ion-modal.filter-table-orders .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.filter-table-orders .body-modal .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.filter-table-orders .body-modal .body .line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
ion-modal.filter-table-orders .body-modal .body .line .input-short {
  width: 218px;
}
ion-modal.filter-table-orders .body-modal .buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.cont-badge {
  width: max-content;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  font-family: var(--font-nunito);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variation-settings: "wdth" 0, "GRAD" 0, "slnt" 0, "XTRA" 0, "XOPQ" 0, "YOPQ" 0, "YTLC" 0, "YTUC" 0, "YTAS" 0, "YTDE" 0, "YTFI" 0;
}
.cont-badge.red {
  background-color: var(--badge-red);
}
.cont-badge.blue {
  background-color: var(--badge-blue);
}
.cont-badge.sulu {
  background-color: var(--badge-sulu);
}
.cont-badge.lgreen {
  background-color: var(--badge-lgreen);
}
.cont-badge.mint {
  background-color: var(--badge-mint);
}
.cont-badge.blizzard {
  background-color: var(--badge-blizzard);
}
.cont-badge.purple {
  background-color: var(--badge-purple);
}
.cont-badge.lavender {
  background-color: var(--badge-lavender);
}
.cont-badge.pink {
  background-color: var(--badge-pink);
}
.cont-autocomplete {
  position: relative;
}
.cont-autocomplete .load {
  width: 30px;
  height: 30px;
}
.cont-autocomplete .icon-select {
  pointer-events: none;
  position: absolute;
  z-index: 123;
  top: 16px;
  right: 14px;
}
.cont-autocomplete .icon-select.small {
  top: 8px;
  right: 8px;
}
.cont-autocomplete .icon-select.medium {
  top: 12px;
  right: 12px;
}
.cont-autocomplete .icon-select.large {
  top: 14px;
  right: 14px;
}
.cont-autocomplete .autocomplete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: row;
  align-items: center;
  background: var(--input-background);
  border: 1px solid rgba(0, 0, 0, 0);
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.cont-autocomplete .autocomplete .p-autocomplete-input {
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0);
}
.cont-autocomplete .autocomplete.small {
  border-radius: 8px;
  height: 36px;
  padding: 6px 6px 6px 6px;
}
.cont-autocomplete .autocomplete.medium {
  border-radius: 10px;
  height: 44px;
  padding: 10px 6px 10px 6px;
}
.cont-autocomplete .autocomplete.large {
  border-radius: 10px;
  height: 52px;
  padding: 14px 14px 14px 14px;
}
.cont-autocomplete .autocomplete:hover {
  background: var(--input-background-hover);
}
.cont-autocomplete .autocomplete.disabled {
  opacity: 40%;
}
.cont-autocomplete .autocomplete.p-focus {
  box-shadow: var(--input-border-focus);
}
.cont-autocomplete .autocomplete.invalid {
  background: var(--input-background-error);
  border: 1px solid var(--input-border-error);
  box-shadow: none;
}
.cont-autocomplete .p-autocomplete-input {
  outline: none;
}
.cont-autocomplete .p-inputtext:enabled:focus {
  border-color: transparent;
  box-shadow: none;
}
.cont-autocomplete .p-inputtext {
  padding: 0px;
  font-family: var(--font-nunito);
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-autocomplete .p-dropdown-panel {
  background: #fff;
  margin-top: 4px;
}
.p-autocomplete-panel {
  margin-top: 8px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 6px -1px #0000000F, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001A;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding: 4px 8px;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item .country-item {
  padding: 0px 8px;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item .country-item .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item .country-item .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.p-dropdown-panel {
  margin-top: 4px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 6px -1px #0000000F, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001A;
}
.p-multiselect-panel {
  margin-top: 4px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 6px -1px #0000000F, 0px 6px 12px -2px #00000014, 0px 12px 24px -4px #0000001A;
}
.p-multiselect-item .cont-item {
  display: flex;
  flex-direction: row;
}
.p-multiselect-item .cont-item .country-item {
  display: flex;
  flex-direction: column;
  padding: 0px 8px 0px 0px;
}
.p-multiselect-item .cont-item .country-item .text {
  color: var(--text);
  font-family: var(--font-nunito);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.p-multiselect-item .cont-item .country-item .subtext {
  color: var(--text-muted);
  font-family: var(--font-nunito);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.cont-select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-select-btn .select {
  display: flex;
  height: 36px;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  font-family: var(--font-nunito);
}
.cont-select-btn .select.ghost {
  border: none;
  outline: none;
}
.cont-select-btn .select.ghost:hover {
  background-color: var(--hover-outline);
}
.cont-select-btn .select.ghost.p-focus {
  background-color: transparent;
  box-shadow: var(--box-shadow-btn) !important;
}
.cont-select-btn .select.ghost:active {
  background-color: var(--pressed-ghost);
}
.cont-select-btn .select.ghost:disabled {
  background-color: transparent;
  opacity: 40%;
}
.cont-select-btn .select.ghost .p-inputtext {
  font-family: var(--font-nunito);
  padding: 0px 2px 2px 2px;
  color: #696775;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-select-btn .select.elevated {
  border: none;
  outline: none;
}
.cont-select-btn .select.elevated:hover {
  background-color: var(--hover-elevated);
  box-shadow: var(--box-shadow-btn-elevated);
}
.cont-select-btn .select.elevated.p-focus {
  background-color: var(--elevated);
  box-shadow: var(--box-shadow-btn);
}
.cont-select-btn .select.elevated:active {
  background-color: var(--pressed-elevated);
  box-shadow: var(--box-shadow-btn);
}
.cont-select-btn .select.elevated:disabled {
  background-color: var(--elevated);
  opacity: 40%;
}
.cont-select-btn .select.elevated .p-inputtext {
  font-family: var(--font-nunito);
  padding: 0px 2px 2px 2px;
  color: var(--text-elevated-btn);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.cont-select-btn .select .p-dropdown-trigger {
  width: auto;
}
ion-modal.filter-table-product-races {
  --width: 480px;
  --height: 420px;
  --border-radius: 16px;
  --box-shadow: 0px 11px 22px -2.5px rgba(0, 0, 0, 0.12), 0px 22px 44px -5px rgba(0, 0, 0, 0.14), 0px 44px 88px -10px rgba(0, 0, 0, 0.16);
}
ion-modal.filter-table-product-races .body-modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  font-family: var(--font-nunito);
}
ion-modal.filter-table-product-races .body-modal .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
ion-modal.filter-table-product-races .body-modal .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-modal.filter-table-product-races .body-modal .body .line {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
ion-modal.filter-table-product-races .body-modal .body .line .input-short {
  width: 218px;
}
ion-modal.filter-table-product-races .body-modal .buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
ion-toast.toast {
  --width: max-content;
  --background: var(--alert-neutral);
  font-family: var(--font-nunito);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--alert-neutral-text);
  --border-radius: 10px;
  --box-shadow: none;
}
ion-toast.toast.neutral {
  --background: var(--alert-neutral);
  color: var(--alert-neutral-text);
}
ion-toast.toast.primary {
  --background: var(--alert-primary);
  color: var(--alert-primary-text);
}
ion-toast.toast.secondary {
  --background: var(--alert-secondary);
  color: var(--alert-secondary-text);
}
ion-toast.toast.info {
  --background: var(--alert-info);
  color: var(--alert-info-text);
}
ion-toast.toast.success {
  --background: var(--alert-success);
  color: var(--alert-success-text);
}
ion-toast.toast.warning {
  --background: var(--alert-warning);
  color: var(--alert-warning-text);
}
ion-toast.toast.error {
  --background: var(--alert-danger);
  color: var(--alert-danger-text);
}
ion-modal.calendar {
  --width: 300px;
  --border-radius: 10px;
  --height: auto;
  --background: var(--ion-color-light, #ffffff);
  --box-shadow: 0px 11px 22px -2.5px #0000001F, 0px 22px 44px -5px #00000024,  0px 44px 88px -10px #00000029;
}
ion-modal.calendar .cont-calendar {
  padding: 6px;
}
ion-modal.calendar ion-datetime {
  --background: transparent;
  --ion-color-base: var(--bright) !important;
  --ion-color-base-rgb: var(--muted) !important;
}
ion-modal.calendar ion-datetime::part(calendar-day) {
  font-family: var(--font-nunito);
}
ion-modal.calendar ion-datetime::part(wheel-item) {
  font-family: var(--font-nunito);
}
ion-modal.calendar ion-datetime::part(calendar-day today) {
  color: inherit;
}
@media screen and (max-width: 720px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    height: auto;
    padding: 32px 0px 32px 0px;
    gap: 32px;
    border-top: 1px solid var(--divider);
  }
  .footer .cont-footer {
    width: calc(100vw - 32px);
    align-items: flex-start;
  }
  .footer .cont-footer .first-line {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }
  .footer .cont-footer .first-line .left {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }
  .footer .cont-footer .first-line .left .text {
    font-weight: 700;
  }
  .footer .cont-footer .first-line .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  .footer .cont-footer .second-line {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  .footer .cont-footer .second-line .left {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer .cont-footer .second-line .left .top {
    flex-wrap: wrap;
  }
  .footer .cont-footer .second-line .left .top .text {
    white-space: nowrap;
  }
  .footer .cont-footer .second-line .right {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
}
